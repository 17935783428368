import Cookies from 'js-cookie';
import { i18n } from '@/plugins/i18n';
import { localize } from 'vee-validate';
import commonApi from '@/utils/api/common';
import pl from '@/locales/pl.json';
import en from '@/locales/en.json';
import es from '@/locales/es.json';
import uk from '@/locales/uk.json';
import ru from '@/locales/ru.json';
import sl from '@/locales/sl.json';
const koiosLangs = {
    pl: 'polish',
    en: 'english',
    es: 'spanish',
    uk: 'ukrainian',
    ru: 'russian',
    sl: 'slovenian'
};

const pathToObject = (resultReference, path) => {
    const splitPath = path.split('=');
    const keys = splitPath[0];
    const answer = splitPath[1];

    const [currentKey, ...restOfPath] = keys.split('.');

    if (restOfPath.length === 0) {
        resultReference[currentKey] = answer;
        return;
    }

    resultReference[currentKey] || (resultReference[currentKey] = {});
    pathToObject(resultReference[currentKey], restOfPath.join('.') + `=${answer}`);
};

export default {
    namespaced: true,
    state: () => ({
        locales: ['en', 'pl', 'es', 'uk', 'ru', 'sl'],
        currentLocale: null,
        loadedLanguages: []
    }),
    mutations: {
        SET_CURRENT_LOCALE (state, payload) {
            state.currentLocale = payload;
        },
        ADD_LOADED_LANGUAGE (state, payload) {
            state.loadedLanguages.push(payload);
        },
        ADD_ALL_LANGUAGE (state, payload) {
            state.loadedLanguages = payload;
        }
    },
    actions: {
        async detectCurrentLocale ({ state, commit, dispatch }) {
            const locale = Cookies.get('locale');

            if (locale) {
                commit('SET_CURRENT_LOCALE', locale);
                i18n.locale = locale;
                await localize(locale);
            } else {
                let usrlang = navigator.language || navigator.userLanguage;
                usrlang = usrlang.split(/-|_/)[0];
                if (!state.locales.includes(usrlang)) {
                    usrlang = 'en';
                }
                commit('SET_CURRENT_LOCALE', usrlang);
                await dispatch('switchLocale');
            }
        },
        switchLocale ({ state }) {
            Cookies.remove('locale');
            Cookies.remove('koios_lang');
            Cookies.set('locale', state.currentLocale, 365);
            Cookies.set('koios_lang', koiosLangs[state.currentLocale], 365);
            i18n.locale = state.currentLocale;
            localize(state.currentLocale);
        },
        getLocaleMessages ({ state, commit, dispatch, rootState }, lang) {
            if (rootState.generalConfigurations.host === 'superadmin') {
                i18n.setLocaleMessage('pl', pl);
                i18n.setLocaleMessage('en', en);
                i18n.setLocaleMessage('es', es);
                i18n.setLocaleMessage('uk', uk);
                i18n.setLocaleMessage('ru', ru);
                i18n.setLocaleMessage('sl', sl);
                commit('ADD_ALL_LANGUAGE', ['pl', 'en', 'es', 'uk', 'ru', 'sl']);
                if (state.loadedLanguages.includes(lang)) {
                    if (i18n.locale !== lang) {
                        commit('SET_CURRENT_LOCALE', lang);
                        dispatch('switchLocale');
                    }
                    return Promise.resolve();
                }
            } else {
                if (state.loadedLanguages.includes(lang)) {
                    if (i18n.locale !== lang) {
                        commit('SET_CURRENT_LOCALE', lang);
                        dispatch('switchLocale');
                    }
                    return Promise.resolve();
                }

                return commonApi.getLocaleMessages(lang, rootState.generalConfigurations.host)
                    .then(({ data }) => {
                        const keys = data.map(el => {
                            const key = el.attributes?.key || el.key;
                            const value = el.interpolations || el.value;
                            return `${key}=${value}`;
                        });

                        const result = {};
                        keys.forEach(x => pathToObject(result, x));
                        commit('ADD_LOADED_LANGUAGE', lang);
                        i18n.setLocaleMessage(lang, result);
                        commit('SET_CURRENT_LOCALE', lang);
                        dispatch('switchLocale');
                    });
            }
        }
    }
};
