import positionsApi from '@/utils/api/positions';

export default {
    namespaced: true,
    state: () => ({
        rawPositions: [],
        totalPositionsAmount: null
    }),
    getters: {
        filterablePositions: state => {
            return state.rawPositions.map(el => ({
                value: el.id,
                text: el.name
            }));
        },
        humanPositions: (state, getters) => {
            return getters.filterablePositions.filter(e => JSON.stringify(e.text) !== '"Item"');
        }
    },
    mutations: {
        SET_RAW_POSITIONS (state, payload) {
            state.rawPositions = payload;
        },
        SET_TOTAL_POSITIONS_AMOUNT (state, payload) {
            state.totalPositionsAmount = payload;
        }
    },
    actions: {
        getPositions ({ commit }, additionalParams = null) {
            commit('SET_RAW_POSITIONS', []);

            const params = {
                'stats[total]': 'count',
                sort: 'name'
            };

            if (additionalParams) {
                Object.keys(additionalParams).forEach(k => {
                    params[k] = additionalParams[k];
                });
            }

            return positionsApi.getPositions(params)
                .then(({ data }) => {
                    commit('SET_RAW_POSITIONS', data.data.map((el, index) => ({
                        seq: (index + 1) + ((params['page[number]'] - 1) * params['page[size]']),
                        id: el.id,
                        ...el.attributes
                    })));

                    if (data.meta.stats) {
                        commit('SET_TOTAL_POSITIONS_AMOUNT', data.meta.stats.total.count);
                    }
                });
        },
        getPositionsLazyLoading ({ state, commit }, additionalParams = null) {
            const params = {
                'stats[total]': 'count',
                sort: 'name'
            };

            if (additionalParams) {
                Object.keys(additionalParams).forEach(k => {
                    params[k] = additionalParams[k];
                });
            }

            return positionsApi.getPositions(params)
                .then(({ data }) => {
                    const result = [...state.rawPositions].concat(data.data.map(el => ({
                        id: el.id,
                        ...el.attributes
                    })));

                    commit('SET_RAW_POSITIONS', result);

                    if (data.meta.stats) {
                        commit('SET_TOTAL_POSITIONS_AMOUNT', data.meta.stats.total.count);
                    }
                });
        }
    }
};
