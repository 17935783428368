import HttpService from '@/servises/HttpService';
import { getEndpoint } from '@/utils/endpoints';

export default {
    getSystemLogs: (params) => {
        return HttpService.get(getEndpoint('system_logs'), {
            params: params
        });
    },
    getSystemLogTypes: (params) => {
        return HttpService.get(getEndpoint('system_log_types'), {
            params: params
        });
    }
};
