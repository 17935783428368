import employeesApi from '@/utils/api/employees';
import EmployeesGroup from '@/models/employees/employeesGroup';

const formatEmployeesGroupData = (data) => (data.map(el => {
    const result = {
        id: el.id,
        ...el.attributes
    };

    if (Object.hasOwnProperty.call(el.relationships.employees, 'data')) {
        result.employeesIds = el.relationships.employees.data.map(el => el.id);
    }

    return new EmployeesGroup(result);
}));

export default {
    namespaced: true,
    state: () => ({
        rawEmployeesGroups: [],
        totalEmployeesGroupsAmount: null
    }),
    getters: {
        filterableEmployeesGroups: state => {
            return state.rawEmployeesGroups.map(el => el.getFilterableData());
        }
    },
    mutations: {
        SET_RAW_EMPLOYEES_GROUPS (state, payload) {
            state.rawEmployeesGroups = payload;
        },
        SET_TOTAL_EMPLOYEES_GROUPS_AMOUNT (state, payload) {
            state.totalEmployeesGroupsAmount = payload;
        }
    },
    actions: {
        getEmployeesGroups ({ commit }, additionalParams = null) {
            commit('SET_RAW_EMPLOYEES_GROUPS', []);

            const params = {
                'stats[total]': 'count',
                sort: 'name'
            };

            if (additionalParams) {
                Object.keys(additionalParams).forEach(k => {
                    params[k] = additionalParams[k];
                });
            }

            return employeesApi.getEmployeesGroups(params)
                .then(({ data }) => {
                    commit('SET_RAW_EMPLOYEES_GROUPS', formatEmployeesGroupData(data.data));

                    if (data.meta.stats) {
                        commit('SET_TOTAL_EMPLOYEES_GROUPS_AMOUNT', data.meta.stats.total.count);
                    }
                });
        },
        getEmployeesGroupsLazyLoading ({ state, commit }, additionalParams = null) {
            const params = {
                'stats[total]': 'count',
                sort: 'name'
            };

            if (additionalParams) {
                Object.keys(additionalParams).forEach(k => {
                    params[k] = additionalParams[k];
                });
            }

            return employeesApi.getEmployeesGroups(params)
                .then(({ data }) => {
                    const result = [...state.rawEmployeesGroups].concat(formatEmployeesGroupData(data.data));

                    commit('SET_RAW_EMPLOYEES_GROUPS', result);

                    if (data.meta.stats) {
                        commit('SET_TOTAL_EMPLOYEES_GROUPS_AMOUNT', data.meta.stats.total.count);
                    }
                });
        }
    }
};
