import tracksApi from '@/utils/api-v2/tracks';

export default {
    namespaced: true,
    state: {
        tracks: [],
        tracksTotalAmount: null,
        selectedTrackElements: [],
        selectedTrackPoints: [],
        selectedTrackEmployees: [],
        allActivePointsSelected: false
    },
    mutations: {
        SET_TRACKS (state, payload) {
            state.tracks = payload;
        },
        SET_SELECTED_TRACKS_ELEMENTS (state, payload) {
            state.selectedTrackElements = payload;
        },
        SET_SELECTED_TRACKS_POINTS (state, payload) {
            state.selectedTrackPoints = payload;
        },
        SET_SELECTED_TRACKS_EMPLOYEES (state, payload) {
            state.selectedTrackEmployees = payload;
        },
        SET_TRACKS_TOTAL_AMOUNT (state, payload) {
            state.tracksTotalAmount = payload;
        },
        SET_ALL_ACTIVE_POINTS_SELECTED (state, payload) {
            state.allActivePointsSelected = payload;
        }
    },
    actions: {
        getTracks ({ commit }, params = null) {
            commit('SET_TRACKS', []);

            return tracksApi.getTracks(params)
                .then(({ data }) => {
                    commit('SET_TRACKS', data.results);
                    commit('SET_TRACKS_TOTAL_AMOUNT', data.count);
                });
        },
        deleteTrack (_, id = null) {
            return tracksApi.deleteTrack(id);
        },
        deleteManyTracks (_, ids) {
            const payload = {
                data: {
                    ids: ids
                }
            };
            return tracksApi.deleteManyTracks(payload);
        },
        setSelectedTracksElements ({ commit }, payload) {
            commit('SET_SELECTED_TRACKS_ELEMENTS', payload);
        },
        setSelectedTracksPoints ({ commit }, payload) {
            commit('SET_SELECTED_TRACKS_POINTS', payload);
        },
        setSelectedTracksEmployees ({ commit }, payload) {
            commit('SET_SELECTED_TRACKS_EMPLOYEES', payload);
        },
        clearSelectedOptions ({ commit }) {
            commit('SET_SELECTED_TRACKS_ELEMENTS', []);
            commit('SET_SELECTED_TRACKS_POINTS', []);
            commit('SET_SELECTED_TRACKS_EMPLOYEES', []);
        },
        setAllActivePointsSelected ({ commit }, payload) {
            commit('SET_ALL_ACTIVE_POINTS_SELECTED', payload);
        }
    }
};
