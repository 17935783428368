import KmsHttpService from '@/servises/kmsHttpService';
import { getV2Endpoint } from '@/utils/apiV2Endpoints';

export default {
    getSubContractors: (params) => {
        return KmsHttpService.get(getV2Endpoint('subcontractors'), { params: params });
    },
    getActiveSubContractors: (id) => {
        return KmsHttpService.get(getV2Endpoint('manage_subcontractors', { id: id }));
    },
    createSubContractors: (payload) => {
        return KmsHttpService.post(getV2Endpoint('subcontractors'), payload);
    },
    updateSubContractors: (id, payload) => {
        return KmsHttpService.put(getV2Endpoint('manage_subcontractors', { id: id }), payload);
    },
    deleteSubContractors: (id) => {
        return KmsHttpService.delete(getV2Endpoint('manage_subcontractors', { id: id }));
    }
};
