import companyTypes from '@/utils/api/companyTypes';

export default {
    namespaced: true,
    state: () => ({
        rawCompanyTypes: [],
        totalCompanyTypesAmount: null
    }),
    getters: {
        filterableCompanyTypes: state => {
            return state.rawCompanyTypes.map(el => ({
                value: el.id,
                text: el.name
            }));
        }
    },
    mutations: {
        SET_RAW_COMPANY_TYPES (state, payload) {
            state.rawCompanyTypes = payload;
        },
        SET_TOTAL_COMPANY_TYPES_AMOUNT (state, payload) {
            state.totalCompanyTypesAmount = payload;
        }
    },
    actions: {
        getCompanyTypes ({ commit }, additionalParams = null) {
            commit('SET_RAW_COMPANY_TYPES', []);

            const params = {
                'stats[total]': 'count',
                sort: 'name'
            };

            if (additionalParams) {
                Object.keys(additionalParams).forEach(k => {
                    params[k] = additionalParams[k];
                });
            }

            return companyTypes.getCompanyTypes(params)
                .then(({ data }) => {
                    commit('SET_RAW_COMPANY_TYPES', data.data.map(el => ({
                        id: el.id,
                        ...el.attributes
                    })));

                    if (data.meta.stats) {
                        commit('SET_TOTAL_COMPANY_TYPES_AMOUNT', data.meta.stats.total.count);
                    }
                });
        },
        getCompanyTypesLazyLoading ({ state, commit }, additionalParams = null) {
            const params = {
                'stats[total]': 'count',
                sort: 'name'
            };

            if (additionalParams) {
                Object.keys(additionalParams).forEach(k => {
                    params[k] = additionalParams[k];
                });
            }

            return companyTypes.getCompanyTypes(params)
                .then(({ data }) => {
                    const result = [...state.rawCompanyTypes].concat(data.data.map(el => ({
                        id: el.id,
                        ...el.attributes
                    })));

                    commit('SET_RAW_COMPANY_TYPES', result);

                    if (data.meta.stats) {
                        commit('SET_TOTAL_COMPANY_TYPES_AMOUNT', data.meta.stats.total.count);
                    }
                });
        }
    }
};
