import HttpService from '@/servises/HttpService';
import { getEndpoint } from '@/utils/endpoints';

export default {
    getTerritories: (params) => {
        return HttpService.get(getEndpoint('territories'), {
            params: params
        });
    }
};
