import { getEndpoint } from '@/utils/endpoints';
import customersApi from '@/utils/api/customers';
import customersApiV2 from '@/utils/api-v2/customers';
import Customer from '@/models/customers/customer';
import { getField, updateField } from 'vuex-map-fields';
import { forceFileDownload } from '@/helpers/files';

export default {
    namespaced: true,
    state: () => ({
        customerFormVisibility: false,
        customerData: new Customer(),
        editedCustomerId: null,
        activeCustomer: null,
        activeCustomerLoader: false
    }),
    getters: {
        getField,
        getCoordinates: state => ({
            latitude: state.customerData.latitude,
            longitude: state.customerData.longitude
        })
    },
    mutations: {
        updateField,
        SET_CUSTOMER_DATA (state, payload) {
            state.customerData.parseData(payload);
        },
        SET_CUSTOMER_NAME_AND_ID (state, payload) {
            state.editedCustomerId = payload.id;
            state.activeCustomer = payload;
        },
        CLEAR_CUSTOMER_DATA (state) {
            state.customerData = new Customer();
        },
        SET_ACTIVE_CUSTOMER (state, payload) {
            let result;

            if (payload === null) {
                result = payload;
            } else {
                const _city = payload.data.attributes.city_name || '';
                const _street = payload.data.attributes.street_and_number || '';

                result = {
                    id: payload.data.id,
                    ...payload.data.attributes,
                    latitude: payload.data.attributes.latitude,
                    longitude: payload.data.attributes.longitude,
                    city_id: payload.included.find(el => el.type === 'cities').id,
                    country_id: payload.included.find(el => el.type === 'countries')?.id,
                    country_name: payload.included.find(el => el.type === 'countries')?.attributes.name,
                    address: `${_city} ${_street}`
                };
            }

            state.activeCustomer = result;
        },
        SET_ACTIVE_CUSTOMER_LOADER (state, payload) {
            state.activeCustomerLoader = payload;
        }
    },
    actions: {
        getActiveCustomer ({ commit, dispatch, rootState }, id) {
            commit('SET_ACTIVE_CUSTOMER_LOADER', true);
            return customersApi.getActiveCustomer(id, rootState.locale.currentLocale)
                .then(({ data }) => {
                    dispatch('commitCustomerData', data);
                });
        },
        commitCustomerData ({ commit }, data) {
            commit('SET_ACTIVE_CUSTOMER', data);
            commit('SET_ACTIVE_CUSTOMER_LOADER', false);

            const sites = data.included?.filter(el => el.type === 'places');

            if (sites) {
                commit('customers/SET_SITES_PER_CUSTOMERS', sites, { root: true });
            }
        },
        updateCustomer ({ state, rootState, commit }) {
            const pointCreationAvailable = rootState.customers.pointCreationAvailable;
            const customerId = state.editedCustomerId;
            let payload = state.customerData.getDataForUpdate(customerId);

            if (rootState.manageSite.isFormFilled) {
                payload = state.customerData.getDataForUpdateWithNested(customerId, pointCreationAvailable, rootState.manageSite.nestedSites);
            }

            return customersApi.updateCustomer(customerId, payload)
                .then(() => {
                    commit('CLEAR_CUSTOMER_DATA');
                });
        },
        update ({ state, dispatch, commit }) {
            const customerId = state.editedCustomerId;
            const payload = state.customerData.getDataForUpdate(customerId);
            return customersApi.updateCustomer(customerId, payload)
                .then(({ data }) => {
                    dispatch('commitCustomerData', data);
                });
        },
        createCustomer ({ state, rootState, commit, dispatch }) {
            const pointCreationAvailable = rootState.customers.pointCreationAvailable;
            let payload = state.customerData.getDataForCreate(pointCreationAvailable);

            if (rootState.manageSite.isFormFilled) {
                payload = state.customerData.getDataForCreateWithNested(pointCreationAvailable, rootState.manageSite.nestedSites);
            }

            return customersApi.createCustomer(payload)
                .then(({ data }) => {
                    dispatch('commitCustomerData', data);
                });
        },
        create ({ state, commit, dispatch }) {
            const payload = state.customerData.build();
            return customersApi.createCustomer(payload)
                .then(({ data }) => {
                    dispatch('commitCustomerData', data);
                });
        },
        deleteCustomer (context, id) {
            return customersApiV2.deleteCustomer(id);
        },
        getLocationsPerCustomerQrCode ({ rootState }, id) {
            const queryParams = new URLSearchParams();
            queryParams.set('locale', rootState.locale.currentLocale);

            const url = `${getEndpoint('customer_points_qrcode_pdf', { id: id })}?${queryParams}`;

            return forceFileDownload(url, `locations_for_customer_${id}`);
        }
    }
};
