class Device {
    constructor (params) {
        this.id = params?.id || '';
        this.deviceType = params?.deviceType || '';
        this.deviceSerialNumber = params?.deviceSerialNumber || '';
    }

    parseData (params) {
        this.id = params.id || '';
        this.deviceType = params.deviceType || '';
        this.deviceSerialNumber = params.deviceSerialNumber || '';
    }
}

export default Device;
