import { getField, updateField } from 'vuex-map-fields';
import commonApi from '@/utils/api/common';
import incidentsApiV2 from '@/utils/api-v2/incidents';
import inspectionsApiV2 from '@/utils/api-v2/inspections';
import Email from '@/models/email/email';

export default {
    namespaced: true,
    state: () => ({
        mailData: new Email()
    }),
    getters: {
        getField
    },
    mutations: {
        updateField,
        RESET_MAIL_DATA (state) {
            state.mailData = new Email();
        }
    },
    actions: {
        sendReport ({ state, commit }) {
            const data = state.mailData.getSendReportData();

            return commonApi.reportMail(data)
                .then(() => {
                    commit('RESET_MAIL_DATA');
                });
        },
        sendIncidentMail ({ state, commit }, id) {
            const data = state.mailData.getSendMailData();

            return incidentsApiV2.sendReport(id, data.data)
                .then(() => {
                    commit('RESET_MAIL_DATA');
                });
        },
        sendInspectionMail ({ state, commit }, id) {
            const data = state.mailData.getSendMailData();

            return inspectionsApiV2.sendReport(id, data.data)
                .then(() => {
                    commit('RESET_MAIL_DATA');
                });
        }
    }
};
