import KmsHttpService from '@/servises/kmsHttpService';
import { getV2Endpoint } from '@/utils/apiV2Endpoints';

export default {
    getSiteGroups: (params) => {
        return KmsHttpService.get(getV2Endpoint('site_groups'), {
            params: params
        });
    },
    createSitesGroup: (data) => {
        return KmsHttpService.post(getV2Endpoint('site_groups'), data);
    },
    updateSitesGroup: (data) => {
        return KmsHttpService.put(getV2Endpoint('manage_site_groups', { id: data.id }), data);
    },
    deleteSitesGroup: (id) => {
        return KmsHttpService.delete(getV2Endpoint('manage_site_groups', { id: id }));
    }
};
