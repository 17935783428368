<template>
    <v-app>
        <router-view v-if="isLocaleLoaded"></router-view>
        <Error></Error>
        <portal-target name="modal"></portal-target>
    </v-app>
</template>

<script>
import { mapState } from 'vuex';
import Error from '@/components/widgets/Error';
import { checkTokenExpiration } from '@/helpers/auth-verify';

export default {
    name: 'App',
    components: { Error },
    data: () => ({
        isLocaleLoaded: false
    }),
    computed: {
        ...mapState('locale', [
            'currentLocale'
        ]),
        ...mapState('generalConfigurations', [
            'websocketUrl'
        ]),
        websocket () {
            return new WebSocket(this.websocketUrl);
        }
    },
    beforeCreate () {
        this.$store.dispatch('authorization/checkRules');

        const baseApiUrl = process.env.NODE_ENV === 'production'
            ? 'https://api.koios.pl'
            : process.env.VUE_APP_STAGE === 'local'
                ? 'http://api.loc.koios.pl'
                : 'https://api.dev.koios.pl';

        const host = window.location.host.split('.')[0];
        const isSecure = baseApiUrl.search('https://') !== -1;
        const DJANGO_PORT = process.env.VUE_APP_STAGE === 'local' ? `:${process.env.DJANGO_PORT || 8010}` : '';
        const websocketUrl = `${isSecure ? 'wss' : 'ws'}://${baseApiUrl.split('//')[1]}${DJANGO_PORT}/ws/agency/${host}/`;
        this.$store.commit('generalConfigurations/SET_HOST', host);
        this.$store.commit('generalConfigurations/SET_API_URL', baseApiUrl);
        this.$store.commit('generalConfigurations/SET_API_V2_URL', baseApiUrl);
        this.$store.commit('generalConfigurations/SET_API_REPORTS_URL', baseApiUrl);
        this.$store.commit('generalConfigurations/SET_WEBSOCKET_URL', websocketUrl);
        this.$store.dispatch('generalConfigurations/setBaseApiUrl');
        this.$store.dispatch('generalConfigurations/setBaseBackendUrl');

        this.$store.dispatch('locale/detectCurrentLocale');
        this.$nextTick(() => {
            this.$vuetify.lang.current = this.currentLocale;
            this.$store.dispatch('locale/getLocaleMessages', this.currentLocale)
                .finally(() => {
                    this.isLocaleLoaded = true;
                });
        });
    },
    created () {
        const token = localStorage.getItem('jwt_access_token');
        // autologout when token is expired
        if (!token) {
            this.$store.dispatch('authorization/signOut');
        } else if (checkTokenExpiration(token)) {
            this.$store.dispatch('authorization/refreshToken');
        }

        this.websocket.onmessage = (event) => {
            try {
                const eventName = JSON.parse(event.data).event;

                switch (eventName) {
                case 'new_incident':
                    if (this.$route.name !== 'incidents' &&
                        this.$can(this.$abilityActions.READ, this.$abilitySubjects.INCIDENT) &&
                        this.$can(this.$abilityActions.VIEW, this.$abilitySubjects.INCIDENT_TAB)) {
                        this.$store.commit('incidents/SET_NEW_INCIDENTS_NOTIFICATION', true);
                    }
                    break;
                default:
                    break;
                }
            } catch (e) {}
        };
    },
    beforeDestroy () {
        this.websocket.close();
    }
};
</script>
