import { getField, updateField } from 'vuex-map-fields';

const defaultData = () => ({
    name: '',
    phone: '',
    remarks: ''
});

export default {
    namespaced: true,
    state: () => ({
        contactFormVisibility: false,
        editedContactId: null,
        contactData: defaultData()
    }),
    getters: {
        getField
    },
    mutations: {
        updateField
    },
    actions: {}
};
