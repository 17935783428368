import { getField, updateField } from 'vuex-map-fields';
import HttpService from '@/servises/HttpService';
import SecondaryHttpService from '@/servises/secondaryHttpService';
import KMSHttpService from '@/servises/kmsHttpService';
import router from '@/router';
import { Ability } from '@casl/ability';
import { TYPE_KEY } from '@/utils/types';
import auth from '@/utils/api/auth';
import authV2 from '@/utils/api-v2/auth';
import Cookies from 'js-cookie';

export default {
    namespaced: true,
    state: {
        login: null,
        password: null,
        isLogged: false,
        resetEmailSent: false,
        userName: '',
        previousRoute: '',
        rules: []
    },
    getters: {
        getField,
        ability () {
            return new Ability([], {
                subjectName (subject) {
                    return !subject || typeof subject === 'string' ? subject : subject[TYPE_KEY];
                }
            });
        }
    },
    mutations: {
        updateField,
        SET_IS_LOGGED (state, payload) {
            state.isLogged = payload;
        },
        SET_USERNAME (state, payload) {
            state.userName = payload;
        },
        SET_RESET_EMAIL (state, payload) {
            state.resetEmailSent = payload;
        },
        SET_PREVIOUS_ROUTE (state, payload) {
            state.previousRoute = Object.entries(payload)[0];
        },
        SET_RULES (state, payload) {
            state.rules = payload;
        }
    },
    actions: {
        checkAuthorization ({ commit }) {
            const _isLogged = localStorage.getItem('isLogged') === 'true';
            commit('SET_IS_LOGGED', _isLogged);
        },
        checkRules ({ commit }) {
            const rules = localStorage.getItem('rules') ? JSON.parse(localStorage.getItem('rules')) : [];
            commit('SET_RULES', rules);
        },
        checkUserName ({ commit }) {
            const _userName = localStorage.getItem('userName');
            commit('SET_USERNAME', _userName);
        },
        signIn ({ state, commit }) {
            const data = {
                username: state.login,
                password: state.password
            };

            return authV2.signIn(data)
                .then(data => {
                    localStorage.setItem('jwt_access_token', data.data.access);
                    localStorage.setItem('jwt_refresh_token', data.data.refresh);

                    HttpService.setAuthHeader(data.data.access);
                    KMSHttpService.setAuthHeader(data.data.access);
                    SecondaryHttpService.setAuthHeader(data.data.access);

                    const _rules = data.data.permissions ? data.data.permissions : [];
                    commit('SET_RULES', _rules);
                    localStorage.setItem('rules', JSON.stringify(_rules));
                    commit('SET_IS_LOGGED', true);
                    commit('SET_USERNAME', state.login);
                    localStorage.setItem('isLogged', 'true');
                    localStorage.setItem('userName', state.login);
                    if (state.previousRoute !== '' && state.previousRoute[0] === 'name') {
                        router.push({ name: state.previousRoute[1] });
                    } else if (state.previousRoute !== '' && state.previousRoute[0] === 'path') {
                        router.push({ path: state.previousRoute[1] });
                    } else {
                        router.push({ name: 'home' });
                    }
                });
        },
        remindPassword ({ state, commit, rootState }) {
            const data = {
                login: state.login,
                agency: rootState.generalConfigurations.host
            };

            return auth.remindPassword(data)
                .then(() => {
                    commit('SET_RESET_EMAIL');
                });
        },
        saveNewPassword ({ state }, params) {
            return auth.saveNewPassword(params);
        },
        refreshToken ({ dispatch }) {
            if (localStorage.getItem('jwt_refresh_token') === null) {
                return dispatch('signOut');
            }

            const data = {
                refresh: localStorage.getItem('jwt_refresh_token')
            };

            return authV2.refreshToken(data).then(data => {
                localStorage.setItem('jwt_access_token', data.data.access);
                localStorage.setItem('jwt_refresh_token', data.data.refresh);

                HttpService.setAuthHeader(data.data.access);
                KMSHttpService.setAuthHeader(data.data.access);
                SecondaryHttpService.setAuthHeader(data.data.access);
            }).catch(() => {
                localStorage.removeItem('jwt_refresh_token');
                dispatch('signOut');
            });
        },
        signOut ({ commit }) {
            const performLogout = () => {
                localStorage.removeItem('jwt_access_token');
                commit('SET_RULES', []);
                localStorage.removeItem('rules');
                HttpService.removeAuthHeader();
                // logout
                commit('SET_IS_LOGGED', false);
                commit('SET_USERNAME', '');
                localStorage.removeItem('isLogged');
                localStorage.removeItem('userName');
                Cookies.remove('koios');

                router.push({ name: 'login' });
            };

            if (localStorage.getItem('jwt_refresh_token') === null) {
                return performLogout();
            }

            const data = {
                refresh: localStorage.getItem('jwt_refresh_token')
            };

            return authV2.signOut(data)
                .then(() => {
                    localStorage.removeItem('jwt_refresh_token');
                    performLogout();
                }).catch(() => {
                    localStorage.removeItem('jwt_refresh_token');
                    performLogout();
                });
        }
    }
};
