import { defaultAgency } from '@/models/superadmin/agency';
import { defaultUser } from '@/models/superadmin/user';
import { getField, updateField } from 'vuex-map-fields';
import HttpService from '@/servises/HttpService';
import { getEndpoint } from '@/utils/endpoints';
import { PARSE_DATE } from '@/helpers/dates';
import { uuid } from 'uuidv4';

export default {
    namespaced: true,
    state: {
        agency: defaultAgency(),
        user: defaultUser(),
        users: [],
        allPermissions: [],
        userOldPermissions: []
    },
    getters: {
        getField
    },
    mutations: {
        updateField,
        SET_DEFAULT_AGENCY (state) {
            state.agency = defaultAgency();
        },
        SET_USERS (state, payload) {
            state.users = payload;
        },
        SET_PERMISSIONS (state, payload) {
            state.allPermissions = payload.map(e => {
                return {
                    id: e.id,
                    short_name: e.attributes.short_name
                };
            });
        },
        SET_DEFAULT_USER (state) {
            state.user = defaultUser();
            state.userOldPermissions = [];
        },
        SET_EDITED_AGENCY (state, payload) {
            state.agency = {
                id: payload.data.id,
                ...payload.data.attributes,
                demoDate: PARSE_DATE(payload.data.attributes.demoDate),
                defLat: payload?.included?.find(e => e.attributes.name === 'define-C_DEF_LAT')?.attributes.value,
                defLon: payload?.included?.find(e => e.attributes.name === 'define-C_DEF_LON')?.attributes.value,
                mobileColor: payload?.included?.find(e => e.attributes.name === 'mobileColor')?.attributes.value,
                mobileTextColor: payload?.included?.find(e => e.attributes.name === 'mobileTextColor').attributes.value
            };
            if (payload?.included && payload?.included?.length > 0) {
                state.agency.included = payload?.included;
            }
        },
        SET_EDITED_USER (state, payload) {
            let permissions = [];
            if (payload.data.relationships.permissions.data.length > 0) {
                permissions = payload.data.relationships.permissions.data.map(e => e.id);
            }
            state.userOldPermissions = permissions;
            state.user = {
                id: payload.data.id,
                ...payload.data.attributes,
                permissions: permissions
            };
        }
    },
    actions: {
        getAgency ({ commit }, id) {
            const params = {
                'extra_fields[agencies]': 'logos',
                'include': 'agency_settings'
            };
            return HttpService.get(getEndpoint('manage_superadmin_agencies', { id: id }), { params: params })
                .then(({ data }) => {
                    commit('SET_EDITED_AGENCY', data);
                });
        },
        getUser ({ commit }, id) {
            return HttpService.get(getEndpoint('manage_superadmin_users', { id: id }), {
                params: {
                    'include': 'permissions'
                }
            })
                .then(({ data }) => {
                    commit('SET_EDITED_USER', data);
                });
        },
        getUsers ({ commit }, params) {
            return HttpService.get(getEndpoint('superadmin_users', { params: params }))
                .then(({ data }) => {
                    commit('SET_USERS', data.data);
                });
        },
        getPermissions ({ commit }, params) {
            return HttpService.get(getEndpoint('superadmin_permissions', { params: params }))
                .then(({ data }) => {
                    commit('SET_PERMISSIONS', data.data);
                });
        },
        deleteAgency ({ commit }, id) {
            return HttpService.delete(getEndpoint('manage_superadmin_agencies', { id: id }));
        },
        deleteUser ({ commit }, id) {
            return HttpService.delete(getEndpoint('manage_superadmin_users', { id: id }));
        },
        createAgency ({ commit, state }) {
            const id1 = uuid();
            const id2 = uuid();
            const id3 = uuid();
            const id4 = uuid();
            const data = {
                data: {
                    type: 'agency',
                    attributes: {
                        name: state.agency.name,
                        language: state.agency.language,
                        useConfig: state.agency.useConfig,
                        useKronos: state.agency.useKronos,
                        useIncidents: state.agency.useIncidents,
                        useCheckpoint: state.agency.useCheckpoint,
                        isActive: state.agency.isActive,
                        useSmsApi: state.agency.useSmsApi,
                        isDemo: state.agency.isDemo,
                        demoDate: state.agency.demoDate,
                        id_user_resp: state.agency.id_user_resp,
                        todelete: state.agency.todelete
                    },
                    'relationships': {
                        'agency_settings': {
                            'data': [
                                {
                                    'type': 'agency_settings',
                                    'temp-id': id1,
                                    'method': 'create'
                                },
                                {
                                    'type': 'agency_settings',
                                    'temp-id': id2,
                                    'method': 'create'
                                },
                                {
                                    'type': 'agency_settings',
                                    'temp-id': id3,
                                    'method': 'create'
                                },
                                {
                                    'type': 'agency_settings',
                                    'temp-id': id4,
                                    'method': 'create'
                                }
                            ]
                        }
                    }
                },
                'included': [
                    {
                        'type': 'agency_settings',
                        'temp-id': id1,
                        'attributes': {
                            'name': 'define-C_DEF_LON',
                            'value': state.agency.defLon
                        }
                    },
                    {
                        'type': 'agency_settings',
                        'temp-id': id2,
                        'attributes': {
                            'name': 'define-C_DEF_LAT',
                            'value': state.agency.defLat
                        }
                    },
                    {
                        'type': 'agency_settings',
                        'temp-id': id3,
                        'attributes': {
                            'name': 'mobileColor',
                            'value': state.agency.mobileColor
                        }
                    },
                    {
                        'type': 'agency_settings',
                        'temp-id': id4,
                        'attributes': {
                            'name': 'mobileTextColor',
                            'value': state.agency.mobileTextColor
                        }
                    }
                ],
                logos: {}
            };
            if (state.agency.logos.print) { data.logos.print = state.agency.logos.print; }
            if (state.agency.logos.watermark) { data.logos.watermark = state.agency.logos.watermark; }
            if (state.agency.logos.web) { data.logos.web = state.agency.logos.web; }
            if (state.agency.logos.mobile) { data.logos.mobile = state.agency.logos.mobile; }
            return HttpService.post(getEndpoint('superadmin_agencies'), data);
        },
        createUser ({ commit, state }) {
            const data = {
                data: {
                    type: 'users',
                    attributes: {
                        login: state.user.login,
                        password: state.user.password,
                        name: state.user.name
                    },
                    relationships: {}
                }
            };
            if (state.user.permissions.length > 0) {
                const relationships = [];
                const included = [];
                state.user.permissions.forEach(e => {
                    const tempId = uuid();
                    relationships.push({
                        'type': 'user_permissions',
                        'temp-id': tempId,
                        'method': 'create'
                    });
                    included.push({
                        'type': 'user_permissions',
                        'temp-id': tempId,
                        'relationships': {
                            'permission': {
                                'data': {
                                    'type': 'permission',
                                    'id': e
                                }
                            }
                        }
                    });
                });
                data.data.relationships = {
                    user_permissions: {
                        data: relationships
                    }
                };
                data.included = included;
            }
            return HttpService.post(getEndpoint('superadmin_users'), data);
        },
        updateAgency ({ commit, state }) {
            const _defLonId = state.agency.included?.find(e => e.attributes.name === 'define-C_DEF_LON').id;
            const _defLatId = state.agency.included?.find(e => e.attributes.name === 'define-C_DEF_LAT').id;
            const _mobileColorId = state.agency.included?.find(e => e.attributes.name === 'mobileColor').id;
            const _mobileTextColorId = state.agency.included?.find(e => e.attributes.name === 'mobileTextColor').id;
            const data = {
                data: {
                    type: 'agencies',
                    id: state.agency.id,
                    attributes: {
                        name: state.agency.name,
                        language: state.agency.language,
                        useConfig: state.agency.useConfig,
                        useKronos: state.agency.useKronos,
                        useCheckpoint: state.agency.useCheckpoint,
                        useIncidents: state.agency.useIncidents,
                        isActive: state.agency.isActive,
                        useSmsApi: state.agency.useSmsApi,
                        isDemo: state.agency.isDemo,
                        demoDate: state.agency.demoDate,
                        id_user_resp: state.agency.id_user_resp,
                        todelete: state.agency.todelete,
                        useReports: state.agency.useReports,
                        mobileColor: state.agency.mobileColor,
                        mobileTextColor: state.agency.mobileTextColor
                    },
                    'relationships': {
                        'agency_settings': {
                            'data': [
                                {
                                    'type': 'agency_settings',
                                    'id': _defLonId,
                                    'method': 'update'
                                },
                                {
                                    'type': 'agency_settings',
                                    'id': _defLatId,
                                    'method': 'update'
                                },
                                {
                                    'type': 'agency_settings',
                                    'id': _mobileColorId,
                                    'method': 'update'
                                },
                                {
                                    'type': 'agency_settings',
                                    'id': _mobileTextColorId,
                                    'method': 'update'
                                }
                            ]
                        }
                    }
                },
                'included': [
                    {
                        'type': 'agency_settings',
                        'id': _defLonId,
                        'attributes': {
                            'value': state.agency.defLon
                        }
                    },
                    {
                        'type': 'agency_settings',
                        'id': _defLatId,
                        'attributes': {
                            'value': state.agency.defLat
                        }
                    },
                    {
                        'type': 'agency_settings',
                        'id': _mobileColorId,
                        'attributes': {
                            'value': state.agency.mobileColor
                        }
                    },
                    {
                        'type': 'agency_settings',
                        'id': _mobileTextColorId,
                        'attributes': {
                            'value': state.agency.mobileTextColor
                        }
                    }
                ],
                logos: {}
            };
            if (state.agency.logos.print) { data.logos.print = state.agency.logos.print; }
            if (state.agency.logos.watermark) { data.logos.watermark = state.agency.logos.watermark; }
            if (state.agency.logos.web) { data.logos.web = state.agency.logos.web; }
            if (state.agency.logos.mobile) { data.logos.mobile = state.agency.logos.mobile; }
            return HttpService.patch(getEndpoint('manage_superadmin_agencies', { id: state.agency.id }), data);
        },
        updateUser ({ commit, state }) {
            const data = {
                data: {
                    type: 'users',
                    id: parseInt(state.user.id),
                    attributes: {
                        login: state.user.login,
                        password: state.user.password,
                        name: state.user.name
                    },
                    relationships: {}
                }
            };
            if (state.user.permissions.length > 0 || state.userOldPermissions.length > 0) {
                const permissionsToDelete = state.userOldPermissions.filter(e => state.user.permissions.indexOf(e) === -1);
                const permissionsToAdd = state.user.permissions.filter(el => state.userOldPermissions.indexOf(el) === -1);
                const relationships = [];
                const disassociated = [];
                const included = [];
                permissionsToAdd.forEach(e => {
                    const tempId = uuid();
                    relationships.push({
                        'type': 'user_permission',
                        'temp-id': tempId,
                        'method': 'create'
                    });
                    included.push({
                        'type': 'user_permission',
                        'temp-id': tempId,
                        'relationships': {
                            'permission': {
                                'data': {
                                    'type': 'permission',
                                    'id': e
                                }
                            }
                        }
                    });
                });
                permissionsToDelete.forEach(e => {
                    disassociated.push({
                        'type': 'permission',
                        'id': parseInt(e),
                        'method': 'disassociate'
                    });
                });
                data.data.relationships = {
                    user_permissions: {
                        data: relationships
                    }
                };
                data.data.relationships.permissions = {
                    data: disassociated
                };
                if (included.length > 0) {
                    data.included = included;
                }
            }
            return HttpService.patch(getEndpoint('manage_superadmin_users', { id: state.user.id }), data);
        }
    }
};
