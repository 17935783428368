const prefix = '/';

const labels = {
    'ezt': 'ezt/',
    'sestLuvComments': 'sest-luv/with-comments/',
    'sestLuvWeeks': 'sest-luv/per-week/',
    'tracksList': 'track/list/',
    'track': 'track/',
    'manageTrack': 'track/:id'
};

export const getReportsEndpoint = (label, params = null, withPrefix = true) => {
    const _prefix = withPrefix ? prefix : '';
    let url = `${_prefix}${labels[label]}`;
    if (!params) {
        return url;
    } else {
        if (labels[label].indexOf(':') === -1) {
            return url;
        } else {
            if (typeof (params) === 'object') {
                for (const param in params) {
                    url = url.replace(':' + param, params[param]);
                }
            }
            return url;
        }
    }
};

export const getUrlParamsObj = (params) => {
    const urlParams = new URLSearchParams();
    Object.keys(params.params).forEach(key => {
        if (Array.isArray(params.params[key])) {
            params.params[key].forEach(item => {
                urlParams.append(key, item);
            });
        } else {
            urlParams.append(key, params.params[key]);
        }
    });
    return urlParams;
};
