export default (store) => {
    const ability = store.getters['authorization/ability'];
    ability.update(store.state.authorization.rules);

    return store.subscribe((mutation) => {
        switch (mutation.type) {
        case 'authorization/SET_RULES':
            ability.update(mutation.payload);
            break;
        }
    });
};
