import { getField, updateField } from 'vuex-map-fields';

export default {
    namespaced: true,
    state: () => ({
        search: null
    }),
    getters: { getField },
    mutations: { updateField },
    actions: {}
};
