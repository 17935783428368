class SystemSetting {
    constructor (params) {
        this.id = params?.id || null;
        this.type = params?.type || '';
        this.value = params?.value || '';
        this.setting_type = params?.setting_type || '';
        this.group = params?.group || null;
    }

    createStructure (params) {
        return {
            id: params.id,
            type: params.type,
            attributes: {
                value: params.value,
                setting_type: params.setting_type
            }
        };
    }

    getUpdateData () {
        return this.createStructure();
    }
}

export default SystemSetting;
