import employeesApi from '@/utils/api/employees';
import incidentsApi from '@/utils/api/incidents';
import templatesApi from '@/utils/api/templates';

export default {
    namespaced: true,
    state: {
        assignedData: null
    },
    mutations: {
        SET_ASSIGNED_DATA (state, payload) {
            state.assignedData = payload;
        },
        CLEAR_EMPLOYEE_FOR_SHOW_POINTS (state) {
            state.assignedData = null;
        }
    },
    actions: {
        getDataForShowAssigned ({ commit }, params) {
            if (params) {
                switch (params.type) {
                case 'templates':
                    return templatesApi.getSingleTemplate(params.id)
                        .then(({ data }) => {
                            commit('SET_ASSIGNED_DATA', {
                                id: data.data.id,
                                point_ids: data.data.attributes.points_ids,
                                place_ids: data.data.attributes.places_ids,
                                customer_ids: data.data.attributes?.customers_ids
                            });
                        });
                case 'employees':
                    return employeesApi.getSingleEmployee(params.id)
                        .then(({ data }) => {
                            commit('SET_ASSIGNED_DATA', {
                                id: data.data.id,
                                point_ids: data.data.attributes.inspector_relations_ids.point_ids,
                                place_ids: data.data.attributes.inspector_relations_ids.place_ids,
                                customer_ids: data.data.attributes.inspector_relations_ids.customer_ids
                            });
                        });

                case 'kinds':
                    return incidentsApi.getSingleIncidentType(params.id)
                        .then(({ data }) => {
                            commit('SET_ASSIGNED_DATA', {
                                id: data.data.id,
                                point_ids: data.data.attributes.assigned.point_ids,
                                place_ids: data.data.attributes.assigned.place_ids,
                                customer_ids: data.data.attributes.assigned.customer_ids
                            });
                        });
                }
            }
        }
    }
};
