import KMSHttpService from '@/servises/kmsHttpService';
import { getV2Endpoint } from '@/utils/apiV2Endpoints';
import HttpService from '@/servises/HttpService';
import { getEndpoint } from '@/utils/endpoints';
export default {
    executeGroupEndpoint: (action, endpointGroup, endpointParams, httpData) => {
        let httpMethod = 'get';
        let endpointName = 'manage';
        switch (action) {
        case 'update':
            httpMethod = 'put'; // 'patch';
            break;
        case 'delete':
            httpMethod = 'delete';
            break;
        case 'get':
            httpMethod = 'get';
            break;
        case 'create':
            httpMethod = 'post';
            endpointName = 'list';
            break;
        case 'send': // v2
            httpMethod = 'post';
            endpointName = 'send';
            break;
        }

        let endpoint = getV2Endpoint({ 'group': endpointGroup, 'endpoint': endpointName }, endpointParams);
        if (endpoint) { // użyj apiV2 gdy zna endpoint
            return KMSHttpService[httpMethod](endpoint, httpData);
        } else { // jeśli nie użyj apiV1
            endpoint = getEndpoint({ 'group': endpointGroup, 'endpoint': endpointName }, endpointParams);
            return HttpService[httpMethod](endpoint, httpData);
        }
    }

};
