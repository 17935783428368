import { DATES_CONFIGURATIONS } from '@/constants/customReports';
import { RELATIONSHIPS } from '@/helpers/jsonapiHelpers';
import { INCIDENT_CONFIGURATION } from '@/constants/incidentConfiguration';
import { CONVERT_FROM_UTC } from '@/helpers/dates';

const createIncidentFields = () => {
    const result = {};

    Object.keys(INCIDENT_CONFIGURATION).forEach(k => {
        result[INCIDENT_CONFIGURATION[k]] = false;
    });

    return result;
};

class CustomReport {
    constructor (params) {
        this.incidentTypeId = (params?.incidentTypeId !== undefined) ? params?.incidentTypeId : null;
        this.templateId = (params?.templateId !== undefined) ? params?.templateId : null;
        this.name = params?.name || null;
        this.incidentFields = params?.incidentFields || createIncidentFields();
        this.templateQuestions = params?.templateQuestions || [];
        this.dateType = params?.dateType || null;
        this.dateFrom = params?.dateFrom || null;
        this.dateTo = params?.dateTo || null;
    }

    getCreateData () {
        const result = {
            data: {
                type: 'custom_reports',
                attributes: {
                    name: this.name,
                    incident_configuration: this.incidentFields,
                    date_configuration: {
                        dates: {
                            type: this.dateType
                        }
                    },
                    answers_configuration: []
                },
                relationships: {
                    incident_kind: {
                        data: RELATIONSHIPS.CREATE.INCIDENT_TYPE(this.incidentTypeId)
                    }
                }
            }
        };

        this.templateQuestions.forEach(question => {
            result.data.attributes.answers_configuration.push({
                question: question
            });
        });

        if (this.dateType === DATES_CONFIGURATIONS.CUSTOM_DATE) {
            result.data.attributes.date_configuration.dates.start_date = CONVERT_FROM_UTC(this.dateFrom);
            result.data.attributes.date_configuration.dates.end_date = CONVERT_FROM_UTC(this.dateTo);
        }

        return result;
    }

    getUpdateData (id) {
        const data = this.getCreateData();
        data.data.id = id;

        return data;
    }

    parseData (payload) {
        this.incidentTypeId = (payload.incidentTypeId !== undefined) ? payload.incidentTypeId : null;
        this.templateId = (payload.templateId !== undefined) ? payload.templateId : null;
        this.name = payload.name || null;
        this.incidentFields = payload.incidentFields || createIncidentFields();
        this.templateQuestions = payload.templateQuestions || [];
        this.dateType = payload.dateType || null;
        this.dateFrom = payload.dateFrom || null;
        this.dateTo = payload.dateTo || null;
    }

    resetIncidentFields () {
        this.incidentFields = createIncidentFields();
    }
}

export default CustomReport;
