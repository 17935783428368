import KmsHttpService from '@/servises/kmsHttpService';
import { getV2Endpoint } from '@/utils/apiV2Endpoints';

export default {
    getEmployeeSubContractors: (params) => {
        return KmsHttpService.get(getV2Endpoint('users_assigned_subcontractors'), {
            params: params
        });
    }
};
