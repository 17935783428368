
class subContractorsType {
    constructor (params) {
        this.id = params?.id !== undefined ? params?.id : null;
        this.name = params?.name || '';
    }

    getCreateData () {
        return {
            name: this.name
        };
    }
}

export default subContractorsType;
