import HttpService from '@/servises/HttpService';
import { getEndpoint } from '@/utils/endpoints';

export default {
    getInspectionsInfringement: (params) => {
        return HttpService.get(getEndpoint('inspections_infringement'), {
            params: params
        });
    },
    getNumberOfIncidents: (params) => {
        return HttpService.get(getEndpoint('number_of_incidents'), {
            params: params
        });
    },
    getInspectionsPerEmployee: (params) => {
        return HttpService.get(getEndpoint('report_customers'), {
            params: params
        });
    },
    getGlobalViolations: (params) => {
        return HttpService.get(getEndpoint('report_global_violations'), {
            params: params
        });
    },
    getViolationsPerQuestion: (params) => {
        return HttpService.get(getEndpoint('report_violations_per_question'), {
            params: params
        });
    },
    getIncidentKinds: (params) => {
        return HttpService.get(getEndpoint('report_incident_kinds'), {
            params: params
        });
    },
    getCustomReports: (params) => {
        return HttpService.get(getEndpoint('custom_reports'), {
            params: params
        });
    },
    getActiveCustomReport: (id) => {
        return HttpService.get(getEndpoint('manage_custom_reports', { id: id }));
    },
    createCustomReport: (data) => {
        return HttpService.post(getEndpoint('custom_reports'), data);
    },
    updateCustomReport: (id, data) => {
        return HttpService.patch(getEndpoint('manage_custom_reports', { id: id }), data);
    },
    deleteCustomReport: (id) => {
        return HttpService.delete(getEndpoint('manage_custom_reports', { id: id }));
    },
    getSingleCustomReport: (id, params) => {
        return HttpService.get(getEndpoint('single_custom_report', { id: id }), {
            params: params
        });
    },
    getNFCReport: (params) => {
        return HttpService.get(getEndpoint('nfc_report'), {
            params: params
        });
    },
    getSingleNFCReport: (id, params) => {
        return HttpService.get(getEndpoint('nfc_report_details', { id: id }), {
            params: params
        });
    }
};
