import subContractorsApi from '@/utils/api-v2/subContractors';

export default {
    namespaced: true,
    state: () => ({
        rawSubContractors: [],
        totalSubContractorsAmount: null
    }),
    getters: {
        filterableSubContractors: state => {
            return state.rawSubContractors.map(el => ({
                value: el.id,
                text: el.name
            }));
        }
    },
    mutations: {
        SET_RAW (state, payload) {
            state.rawSubContractors = payload;
        },
        SET_TOTAL_AMOUNT (state, payload) {
            state.totalSubContractorsAmount = payload;
        }
    },
    actions: {
        clearSubContractors ({ commit }) {
            commit('SET_RAW', []);
        },
        getSubContractors ({ commit }, additionalParams = null) {
            commit('SET_RAW', []);

            const params = {};

            if (additionalParams) {
                Object.keys(additionalParams).forEach(k => {
                    params[k] = additionalParams[k];
                });
            }

            return subContractorsApi.getSubContractors(params)
                .then(({ data }) => {
                    commit('SET_RAW', data.results.map((el, index) => ({
                        seq: (index + 1) + ((params.page - 1) * params.page_size),
                        ...el
                    })));

                    if (data.count) {
                        commit('SET_TOTAL_AMOUNT', data.count);
                    }
                });
        },
        getSubContractorsLazyLoading ({ commit, state }, additionalParams = null) {
            const params = {};

            if (additionalParams) {
                Object.keys(additionalParams).forEach(k => {
                    params[k] = additionalParams[k];
                });
            }

            return subContractorsApi.getSubContractors(params)
                .then(({ data }) => {
                    const result = [...state.rawSubContractors].concat(data.results.map(el => ({
                        ...el
                    })));

                    commit('SET_RAW', result);

                    if (data.count) {
                        commit('SET_TOTAL_AMOUNT', data.count);
                    }
                });
        }
    }
};
