export default {
    path: '/employees',
    component: () => import(/* webpackChunkName: "Index" */ '@/views/index'),
    redirect: { name: 'employees' },
    children: [
        {
            path: '',
            name: 'employees',
            component: () => import(/* webpackChunkName: "Employees" */ '@/views/employees/Employees')
        }
    ]
};
