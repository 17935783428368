import TemplateQuestion from '@/models/templates/templateQuestion';
import templatesApi from '@/utils/api/templates';
import { getField, updateField } from 'vuex-map-fields';

export default {
    namespaced: true,
    state: () => ({
        questionFormVisibility: false,
        editedQuestionId: null,
        questionData: new TemplateQuestion()
    }),
    getters: {
        getField,
        getFormParameters: state => state.questionData.getFormParams()
    },
    mutations: {
        updateField,
        SET_QUESTION_FORM_VISIBILITY (state, payload) {
            state.questionFormVisibility = payload;
        },
        SET_EDITED_QUESTION_ID (state, payload) {
            state.editedQuestionId = payload;
        },
        RESET_QUESTION_DATA (state) {
            state.questionData = new TemplateQuestion();
        },
        SET_DATA_DEFAULT_VALUES (state) {
            state.questionData.setDefaultValues();
        },
        SET_QUESTION_DATA (state, payload) {
            state.questionData.parseData(payload);
        }
    },
    actions: {
        createQuestion ({ state, rootState }) {
            const data = state.questionData.getDataForCreate(rootState.manageTemplate.editedTemplateId);
            return templatesApi.createQuestion(data);
        },
        updateQuestion ({ state, rootState }) {
            const data = state.questionData.getDataForUpdate(rootState.manageTemplate.editedTemplateId);
            return templatesApi.updateQuestion(data);
        },
        deleteQuestion (context, id) {
            return templatesApi.deleteQuestion(id);
        }
    }
};
