// for each endpointGroup we need to add endpoints in \utils\apiV2Endpoints.js or \utils\endpoints.js
// in format:

// 'periodicPointReport': {
//     'list': '/reports/periodic_point_reports',
//     'manage': '/reports/periodic_point_reports/:id',
//     'send': '/reports/send_periodic_point_reports'
// },

// endpoints types: 'list' (w tym list, create), 'manage' ( w tym get, update, delete ), 'send'

// if there is no endpoint in the \utils\apiV2Endpoints file, it will use \utils\endpoints.js
// so in the file \utils\apiV2Endpoints you can add only one endpoint:
// 'periodicPointReport': {
//     'send': '/reports/send_periodic_point_reports'
// },

import { PERIODIC_REPORTS_PARAMS } from './reports_params';

export const PERIODIC_REPORTS_TYPES = [
    {
        'name': 'planning_reports.report_types.location', // this.$t()
        'type': 'periodic_location_reports',
        'endpointGroup': 'periodicLocationReport',
        'param': PERIODIC_REPORTS_PARAMS.LOCATION
    },
    {
        'name': 'planning_reports.report_types.point', // this.$t()
        'type': 'periodic_point_reports',
        'endpointGroup': 'periodicPointReport',
        'param': PERIODIC_REPORTS_PARAMS.POINT
    },
    {
        'name': 'planning_reports.report_types.employee', // this.$t()
        'type': 'periodic_employee_reports',
        'endpointGroup': 'periodicEmployeeReport',
        'param': PERIODIC_REPORTS_PARAMS.EMPLOYEE
    },
    {
        'name': 'planning_reports.report_types.points_not_scanned', // this.$t()
        'type': 'periodic_points_not_scanned_reports',
        'endpointGroup': 'periodicPointsNoScannedReport',
        'param': PERIODIC_REPORTS_PARAMS.POINT
    },
    {
        'name': 'planning_reports.report_types.incremental_points_not_scanned', // this.$t()
        'type': 'incremental_points_not_scanned_reports',
        'endpointGroup': 'incrementalPointsNoScannedReport',
        'param': PERIODIC_REPORTS_PARAMS.POINT
    }
];

export const FREQUENCY_TYPES_ENUM = {
    'DAILY': 'daily',
    'EVERY_MONDAY': 'every_monday',
    'MONTHLY': 'monthly'
};
