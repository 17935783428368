import { getEndpoint } from '@/utils/endpoints';
import { getReportsEndpoint } from '@/utils/reportsEndpoints';
import { forceFileDownload, forceExternalFileDownload } from '@/helpers/files';
import commonApi from '@/utils/api/common';

export default {
    namespaced: true,
    actions: {
        getXLS ({ rootState }, additionalParams) {
            const params = {
                'page[number]': '-1',
                locale: rootState.locale.currentLocale
            };

            Object.keys(additionalParams).forEach(k => {
                if (k !== 'instance' && k !== 'prefix') {
                    params[k] = additionalParams[k];
                }
            });

            const urlParams = new URLSearchParams();

            Object.entries(params).forEach(el => {
                urlParams.set(el[0], el[1]);
            });

            const url = getEndpoint('export_xlsx',
                { instance: additionalParams.instance, prefix: additionalParams.prefix }) + `?${urlParams}`;

            return forceFileDownload(url, `${additionalParams.instance}.xlsx`);
        },
        getReportXLS ({ rootState }, additionalParams) {
            const params = {
                locale: rootState.locale.currentLocale
            };

            Object.keys(additionalParams).forEach(k => {
                if (k !== 'kind') {
                    params[k] = additionalParams[k];
                }
            });

            const urlParams = new URLSearchParams();

            Object.entries(params).forEach(el => {
                urlParams.set(el[0], el[1]);
            });

            // Reports (FastAPI) endpoints
            var url;
            var reportsApi = false;
            if (['ezt'].includes(additionalParams.kind)) {
                url = getReportsEndpoint('ezt') + `?${urlParams}`;
                reportsApi = true;
            } else {
                url = getEndpoint('reports_xls',
                    { kind: additionalParams.kind }) + `?${urlParams}`;
            }
            return forceFileDownload(url, `${additionalParams.kind}.xlsx`, reportsApi);
        },
        getReportPDF ({ rootState }, additionalParams) {
            const params = {
                locale: rootState.locale.currentLocale
            };

            Object.keys(additionalParams).forEach(k => {
                if (k !== 'kind') {
                    params[k] = additionalParams[k];
                }
            });

            const urlParams = new URLSearchParams();

            Object.entries(params).forEach(el => {
                urlParams.set(el[0], el[1]);
            });

            const url = getEndpoint('report_pdf',
                { kind: additionalParams.kind }) + `?${urlParams}`;

            return forceFileDownload(url, `${additionalParams.kind}.pdf`);
        },
        downloadSampleXLSX ({ rootState }, additionalParams) {
            const params = {
                locale: rootState.locale.currentLocale
            };

            Object.keys(additionalParams).forEach(k => {
                if (k !== 'kind') {
                    params[k] = additionalParams[k];
                }
            });

            const urlParams = new URLSearchParams();

            Object.entries(params).forEach(el => {
                urlParams.set(el[0], el[1]);
            });

            const url = getEndpoint('download_xlsx_sample',
                { kind: additionalParams.kind }) + `?${urlParams}`;
            if (additionalParams.kind === 'customers') {
                return forceFileDownload(url, 'koios_clients.xlsx');
            } else if (additionalParams.kind === 'points') {
                return forceFileDownload(url, 'koios_locations.xlsx');
            } else if (additionalParams.kind === 'places') {
                return forceFileDownload(url, 'koios_sites.xlsx');
            } else if (additionalParams.kind === 'employees') {
                return forceFileDownload(url, 'koios_persons_items.xlsx');
            } else {
                return forceFileDownload(url, `sample_${additionalParams.kind}.xlsx`);
            }
        },
        downloadLocationAttachment ({ rootState }, additionalParams) {
            return forceExternalFileDownload(additionalParams.url);
        },
        getCoordinates (context, data) {
            return commonApi.getCoordinates(data);
        }
    }
};
