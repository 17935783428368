import store from '@/store';

export function onFulfilled (response) {
    return response;
}

export function onRejected (error) {
    let payload;
    if (error.request.responseText && error.request.responseText !== ' ') {
        if (typeof JSON.parse(error.request.responseText) === 'string') {
            payload = {
                status: error.request.status,
                text: error.request.responseText
            };
        } else {
            if (JSON.parse(error.request.responseText).meta) {
                payload = {
                    status: error.request.status,
                    text: JSON.parse(error.request.responseText).meta.error ||
                        JSON.parse(error.request.responseText).meta.error_details ||
                        JSON.parse(error.request.responseText).meta,
                    title: null
                };
            } else if (JSON.parse(error.request.responseText).errors) {
                const errors = JSON.parse(error.request.responseText).errors;
                if (Array.isArray(errors)) {
                    payload = {
                        status: error.request.status,
                        text: errors.length > 0 ? errors[0].detail : error,
                        title: errors.length > 0 ? errors[0].title : null
                    };
                } else {
                    payload = {
                        status: error.request.status,
                        text: errors || error,
                        title: null
                    };
                }
            } else if (JSON.parse(error.request.responseText).detail) {
                payload = {
                    status: error.request.status,
                    text: JSON.parse(error.request.responseText).detail,
                    title: error.request.statusText
                };
            } else {
                const errors = JSON.parse(error.request.responseText);
                const errorsKeys = Object.keys(errors);
                payload = {
                    status: error.request.status,
                    text: errorsKeys.length > 0 ? errors[errorsKeys[0]]?.[0] : error,
                    title: null
                };
            }
        }
    } else {
        payload = {
            status: error.request.status,
            text: error,
            title: null
        };
    }
    store.commit('error/SET_ERROR', payload);

    return Promise.reject(error);
}
