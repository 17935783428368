export default {
    path: '/settings',
    component: () => import(/* webpackChunkName: "Index" */ '@/views/index'),
    redirect: { name: 'holidays' },
    children: [
        {
            path: 'config-global',
            name: 'configGlobal',
            component: () => import(/* webpackChunkName: "ConfigGlobal" */ '@/views/settings/ConfigGlobal')
        },
        {
            path: 'system-log',
            name: 'systemLog',
            component: () => import(/* webpackChunkName: "SystemLog" */ '@/views/settings/SystemLog')
        },
        {
            path: 'locales',
            name: 'locales',
            component: () => import(/* webpackChunkName: "Locales" */ '@/views/settings/Locales')
        },
        {
            path: 'sms-gate',
            name: 'smsGate',
            component: () => import(/* webpackChunkName: "SmsGate" */ '@/views/settings/SmsGate')
        },
        {
            path: 'holidays',
            name: 'holidays',
            component: () => import(/* webpackChunkName: "holidays" */ '@/views/settings/HolidaysCalendar')
        }
    ]
};
