import inspectionsApi from '@/utils/api-v2/inspections';
import { PARSE_INSPECTIONS } from '@/helpers/inspectionsHelper';

export default {
    namespaced: true,
    state: () => ({
        inspections: [],
        inspectionsTotalAmount: null
    }),
    mutations: {
        SET_INSPECTIONS (state, payload) {
            state.inspections = payload;
        },
        SET_NEW_INSPECTIONS (state, payload) {
            const seq = state.inspections[0].seq;
            state.inspections.splice(-payload.length);
            state.inspections.unshift(...payload);
            state.inspections.map((el, index) => {
                el.seq = seq + index;
            });
        },
        SET_INSPECTIONS_TOTAL_AMOUNT (state, payload) {
            state.inspectionsTotalAmount = payload;
        }
    },
    actions: {
        getInspections ({ commit }, params) {
            commit('SET_INSPECTIONS', []);

            return inspectionsApi.getInspections(params)
                .then(({ data }) => {
                    commit('SET_INSPECTIONS', PARSE_INSPECTIONS(data.data, params));
                    commit('SET_INSPECTIONS_TOTAL_AMOUNT', data.meta.stats.total.count);
                });
        },
        updateInspections ({ commit, state }, params) {
            return inspectionsApi.getInspections(params)
                .then(({ data }) => {
                    const oldInspections = state.inspections.map(el => el.id);
                    const newInspections = data.data.filter(el => !oldInspections.includes(el.id));
                    if (newInspections.length) {
                        commit('SET_NEW_INSPECTIONS', PARSE_INSPECTIONS(newInspections, params));
                        commit('SET_INSPECTIONS_TOTAL_AMOUNT', data.meta.stats.total.count);
                    }
                });
        }
    }
};
