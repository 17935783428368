
class IncidentStatus {
    constructor (params) {
        this.id = params?.id !== undefined ? params?.id : null;
        this.name = params?.name || '';
        this.description = params?.description || '';
        this.color = params?.color || '';
        this.completion_percent = params?.completion_percent || null;
        this.is_default = params?.is_default || 0;
        this.description_for_mail = params?.description_for_mail || '';
        // generated from relation links
        this.incidents = (params?.incidents !== undefined) ? params?.incidents : [];
        this.incident_kind_statuses = (params?.incident_kind_statuses !== undefined) ? params?.incident_kind_statuses : [];
        this.incident_kinds = (params?.incident_kinds !== undefined) ? params?.incident_kinds : [];
    }

    getCreateData () {
        return {
            data: {
                type: 'incident_statuses',
                attributes: {
                    name: this.name,
                    color: this.color,
                    description: this.description,
                    completion_percent: this.completion_percent,
                    is_default: this.is_default
                }
            }
        };
    }

    getUpdateData (id) {
        const payload = this.getCreateData();
        payload.data.id = id;
        return payload;
    }
}

export default IncidentStatus;
