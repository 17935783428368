import { RELATIONSHIPS } from '@/helpers/jsonapiHelpers';

class Template {
    constructor (params) {
        this.name = params?.name || '';
        this.color = params?.color || '';
        this.questions = params?.questions || [];
        this.numberOfRandomQuestions = parseInt(params?.number_of_random_questions, 10) || 0;
    }

    #getBaseJSONStructure () {
        return {
            data: {
                type: 'templates',
                attributes: {
                    name: this.name,
                    color: this.color,
                    number_of_random_questions: parseInt(this.numberOfRandomQuestions, 10)
                },
                relationships: {}
            }
        };
    }

    getDataForCreate (parentID) {
        const data = this.#getBaseJSONStructure();
        if (parentID) {
            data.data.attributes.parent_id = Number.parseInt(parentID);
        }
        if (this.questions.length > 0) {
            data.data.relationships.template_questions = {
                data: RELATIONSHIPS.CREATE.CREATE_TEMPLATE_QUESTIONS(this.questions)
            };

            data.included = [];

            this.questions.forEach(question => {
                data.included.push(question.getDataForInclude());
            });
        }

        return data;
    }

    getDataForUpdate (id) {
        const data = this.#getBaseJSONStructure();
        data.data.id = id;

        if (this.questions.length > 0) {
            this.reorderQuestions();

            data.data.relationships.template_questions = {
                data: RELATIONSHIPS.UPDATE.UPDATE_TEMPLATE_QUESTIONS(this.questions)
            };

            data.included = [];

            this.questions.forEach(question => {
                data.included.push(question.getDataForIncludeUpdate());
            });
        }

        return data;
    }

    parseData (params) {
        this.name = params.name || '';
        this.color = params.color || '';
        this.questions = params.questions || [];
        this.numberOfRandomQuestions = parseInt(params.number_of_random_questions, 10) || 0;
    }

    reorderQuestions () {
        this.questions.forEach((el, i) => {
            el.sequence = i + 1;
        });
    }
}

export default Template;
