export const EVENT_CYCLES = {
    YEARLY: 'yearly',
    MONTHLY: 'monthly',
    WEEKLY: 'weekly',
    DAILY: 'daily',
    // HOURLY: 4,
    // MINUTELY: 5,
    // SECONDLY: 6,
    ONCE: 'once'
};
