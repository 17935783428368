export default {
    path: '/subContractors',
    component: () => import(/* webpackChunkName: "Index" */ '@/views/index'),
    redirect: { name: 'subContractors' },
    children: [
        {
            path: '',
            name: 'subContractors',
            component: () => import(/* webpackChunkName: "subContractors" */'@/views/subContractors/SubContractors.vue')
        }
    ]
};
