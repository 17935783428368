export default {
    path: '/incidents-list',
    component: () => import(/* webpackChunkName: "Index" */ '@/views/index'),
    redirect: { name: 'incidents' },
    children: [
        {
            path: '',
            name: 'incidents',
            component: () => import(/* webpackChunkName: "Incidents" */ '@/views/incidents/Incidents')
        }
    ]
};
