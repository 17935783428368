import HttpService from '@/servises/HttpService';
import { getEndpoint } from '@/utils/endpoints';

export default {
    getHolidays: (params) => {
        return HttpService.get(getEndpoint('holiday_calendars'), {
            params: params
        });
    },
    createHoliday: (data) => {
        return HttpService.post(getEndpoint('holiday_calendars'), data);
    },
    updateHoliday: (data) => {
        return HttpService.patch(getEndpoint('manage_holiday_calendars', { id: data.data.id }), data);
    },
    deleteHoliday: (id) => {
        return HttpService.delete(getEndpoint('manage_holiday_calendars', { id: id }));
    }
};
