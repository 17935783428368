export const STEPS_TYPES_ENUM = {
    QR: 'qr',
    NFC: 'nfc',
    TEMPLATE: 'template'
};

export const STEP_ELEMENT_TYPE_ENUM = {
    POINT: 'point',
    ELEMENT: 'element',
    EMPLOYEE: 'employee'
};

export const REPETITION_CYCLE_ENUM = {
    WEEKLY_CYCLE: 'weekly_cycle',
    PRECISE_DAYS: 'precise_days',
    CYCLE_PER_X_DAYS: 'cycle_per_x_days'
};

export const REPETITION_CYCLE_FULL_ENUM = {
    ...REPETITION_CYCLE_ENUM,
    CYCLE: 'cycle'
};

export const REPETITION_CYCLE_TRANSLATE_KEYS = {
    [REPETITION_CYCLE_FULL_ENUM.WEEKLY_CYCLE]: 'tracks.weekly_cycle',
    [REPETITION_CYCLE_FULL_ENUM.PRECISE_DAYS]: 'tracks.precise_days',
    [REPETITION_CYCLE_FULL_ENUM.CYCLE_PER_X_DAYS]: 'tracks.cycle_per_x_days',
    [REPETITION_CYCLE_FULL_ENUM.CYCLE]: 'tracks.cycle'
};

export const REPETITION_TYPE_ENUM = {
    WEEKLY: 'weekly',
    YEARLY: 'yearly'
};

export const TRACK_TYPE_ENUM = {
    SEQ_IN_ORDER_ON_TIME: 'in_order_on_time',
    SEQ_IN_TIME_LIM: 'in_order_random_time',
    RAND_IN_TIME: 'random_order_random_time'
};

export const TRACK_TYPE_TRANSLATE_KEYS = {
    [TRACK_TYPE_ENUM.SEQ_IN_ORDER_ON_TIME]: 'tracks.track_types.seq_in_time',
    [TRACK_TYPE_ENUM.SEQ_IN_TIME_LIM]: 'tracks.track_types.seq_in_time_lim',
    [TRACK_TYPE_ENUM.RAND_IN_TIME]: 'tracks.track_types.rand_in_time'
};

export const REPETITION_PATTERN_ENUM = {
    EQUAL_BREAKS: 'equal_breaks',
    RANDOM_BREAKS: 'random_breaks'
};

export const REPETITION_PATTERN_TRANSLATE_KEYS = {
    [REPETITION_PATTERN_ENUM.EQUAL_BREAKS]: 'labels.track_step_equal',
    [REPETITION_PATTERN_ENUM.RANDOM_BREAKS]: 'labels.track_step_random'
};

export const WEEKDAYS_TRANSLATE_KEYS_ARRAY = [
    'labels.weekdays.mo',
    'labels.weekdays.tue',
    'labels.weekdays.wed',
    'labels.weekdays.thu',
    'labels.weekdays.fri',
    'labels.weekdays.sat',
    'labels.weekdays.sun'
];
