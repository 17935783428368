import KmsHttpService from '@/servises/kmsHttpService';
import { getV2Endpoint } from '@/utils/apiV2Endpoints';

export default {
    getEvents: (params) => {
        return KmsHttpService.get(getV2Endpoint('inspection_schedules'), {
            params: params
        });
    },
    getActiveEvent: (id) => {
        return KmsHttpService.get(getV2Endpoint('manage_inspection_schedules', { id: id }));
    },
    createEvent: (data) => {
        return KmsHttpService.post(getV2Endpoint('inspection_schedules'), data);
    },
    updateEvent: (id, data) => {
        return KmsHttpService.patch(getV2Endpoint('manage_inspection_schedules', { id: id }), data);
    },
    deleteEvent: (id) => {
        return KmsHttpService.delete(getV2Endpoint('manage_inspection_schedules', { id: id }));
    }
};
