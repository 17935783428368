const defaultError = () => ({
    visibility: false,
    title: null,
    status: null,
    text: null
});

export default {
    namespaced: true,
    state: () => ({
        error: defaultError()
    }),
    mutations: {
        RESET_ERROR (state) {
            state.error = defaultError();
        },
        SET_ERROR (state, payload) {
            state.error.text = payload.text;
            state.error.status = payload.status;
            state.error.title = payload.title;
            state.error.visibility = true;
        }
    }
};
