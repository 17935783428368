import { getField, updateField } from 'vuex-map-fields';
import { getEndpoint } from '@/utils/endpoints';
import locationsApi from '@/utils/api/locations';
import locationsApiV2 from '@/utils/api-v2/locations';
import Location from '@/models/customers/location';
import { forceFileDownload } from '@/helpers/files';

export default {
    namespaced: true,
    state: () => ({
        locationFormVisibility: false,
        editedLocationId: null,
        locationData: new Location(),
        showTab: null,
        nestedLocations: [],
        isLocationFormFilled: false,
        showLocationDetails: false,
        activeLocation: null,
        hasLimitedAccessToAllowEditing: false
    }),
    getters: {
        getField
    },
    mutations: {
        updateField,
        SET_LOCATION_DATA (state, payload) {
            state.locationData.parseData(payload);
        },
        RESET_LOCATION_DATA (state) {
            state.locationData = new Location();
        },
        SET_IS_LOCATION_FORM_FILLED (state, payload) {
            state.isLocationFormFilled = payload;
        },
        RESET_NESTED_LOCATIONS (state) {
            state.nestedLocations = [];
        },
        REMOVE_NESTED_LOCATION (state, nested) {
            state.nestedLocations.splice(state.nestedLocations.indexOf(nested), 1);
        },
        SET_NESTED_LOCATIONS (state) {
            state.nestedLocations.push(state.locationData);
        },
        SET_ACTIVE_LOCATION (state, payload) {
            let result;
            let hasLimitedAccess = false;

            if (payload === null) {
                result = payload;
            } else {
                let pointIncidentTypes = '';
                let pointPersons = '';
                let incidentTypesForAccess = [];
                let previousIncidentTypesIds = [];
                if (payload.data.attributes.point_incident_kinds) {
                    pointIncidentTypes = payload.data.attributes.point_incident_kinds
                        .map(el => el.incident_kind_name)
                        .join(', ');
                    incidentTypesForAccess = payload.data.attributes.point_incident_kinds
                        .map(el => el.incident_kind_id?.toString());
                    previousIncidentTypesIds = payload.data.attributes.point_incident_kinds
                        .map(el => el.point_incident_kind_id);
                    hasLimitedAccess ||= incidentTypesForAccess.includes(undefined);
                }

                let pointInspectors = '';
                let locationInspectors = [];
                let previousInspectorsIds = [];
                if (payload.data.attributes.point_inspectors) {
                    pointInspectors = payload.data.attributes.point_inspectors
                        .map(el => el.inspector_name)
                        .join(', ');
                    locationInspectors = payload.data.attributes.point_inspectors.filter(e => e.inspector_id !== null)
                        .map(el => el.inspector_id?.toString());
                    previousInspectorsIds = payload.data.attributes.point_inspectors
                        .map(el => el.point_inspector_id);
                }
                if (payload.data.attributes.employees_full_names && payload.data.attributes.employees_full_names.length > 0) {
                    pointPersons = payload.data.attributes.employees_full_names.join(', ');
                }

                let pointTemplates = '';
                let templatesForAccess = [];
                let previousTemplatesIds = [];
                if (payload.data.attributes.point_templates) {
                    pointTemplates = payload.data.attributes.point_templates
                        .map(el => el.template_name)
                        .join(', ');
                    templatesForAccess = payload.data.attributes.point_templates
                        .map(el => el.template_id?.toString());
                    previousTemplatesIds = payload.data.attributes.point_templates
                        .map(el => el.point_template_id);
                    hasLimitedAccess ||= templatesForAccess.includes(undefined);
                }

                result = {
                    id: payload.data.id,
                    ...payload.data.attributes,
                    pointIncidentTypes: pointIncidentTypes,
                    incidentTypesForAccess: incidentTypesForAccess,
                    previousIncidentTypesIds: previousIncidentTypesIds,
                    pointInspectors: pointInspectors,
                    locationInspectors: locationInspectors,
                    previousInspectorsIds: previousInspectorsIds,
                    pointTemplates: pointTemplates,
                    pointPersons: pointPersons,
                    templatesForAccess: templatesForAccess,
                    previousTemplatesIds: previousTemplatesIds
                };
            }

            state.activeLocation = result;
            state.hasLimitedAccessToAllowEditing = hasLimitedAccess;
        }
    },
    actions: {
        getActiveLocation ({ commit }, id) {
            return locationsApi.getActiveLocation(id)
                .then(({ data }) => {
                    commit('SET_ACTIVE_LOCATION', data);
                });
        },
        createLocation ({ state, rootState, commit }) {
            const payload = state.locationData.getCreateData(rootState.manageSite.activeSite.id);

            return locationsApi.createLocation(payload)
                .then(() => {
                    commit('RESET_LOCATION_DATA');
                });
        },
        async updateLocation ({ state, rootState, commit }) {
            const payload = await state.locationData.getUpdateData(rootState.manageSite.activeSite.id, state.editedLocationId);
            return locationsApi.updateLocation(state.editedLocationId, payload)
                .then(({ data }) => {
                    commit('SET_ACTIVE_LOCATION', data);
                    commit('RESET_LOCATION_DATA');
                });
        },
        deleteLocation (context, id) {
            return locationsApiV2.deleteLocation(id);
        },
        cleanActiveLocationInformation ({ commit }) {
            commit('SET_ACTIVE_LOCATION', null);
        },
        getLocationQrCode ({ rootState }, id) {
            const queryParams = new URLSearchParams();
            queryParams.set('locale', rootState.locale.currentLocale);

            const url = `${getEndpoint('locations_qrcode_pdf', { id: id })}?${queryParams}`;

            return forceFileDownload(url, `location_${id}`);
        }
    }
};
