import HttpService from '@/servises/HttpService';
import { getEndpoint } from '@/utils/endpoints';

export default {
    getLocations: (params) => {
        return HttpService.get(getEndpoint('locations'), {
            params: params
        });
    },
    getActiveLocation: (id) => {
        return HttpService.get(getEndpoint('manage_location', { id: id }), {
            params: {
                'extra_fields[points]': 'qr_code_svg'
            }
        });
    },
    createLocation: (payload) => {
        return HttpService.post(getEndpoint('locations'), payload);
    },
    updateLocation: (id, payload) => {
        return HttpService.patch(getEndpoint('manage_location', { id: id }), payload);
    },
    refreshGeolocation: () => {
        return HttpService.get(getEndpoint('refresh_points_geolocation'));
    },
    importLocations: (data) => {
        return HttpService.post(getEndpoint('import_locations'), data);
    }
};
