import inspectionsApi from '@/utils/api/inspections';
import inspectionsApiV2 from '@/utils/api-v2/inspections';
import { forceFileDownload } from '@/helpers/files';
import { CONVERT_FROM_UTC, PARSE_DATE, PARSE_TIME } from '@/helpers/dates';
import { DateTime } from 'luxon';
import { getField, updateField } from 'vuex-map-fields';

export default {
    namespaced: true,
    state: () => ({
        activeInspection: null,
        inspectionQuestions: [],
        inspectionQuestionsTotalAmount: null,
        detailsVisibility: false
    }),
    getters: {
        getField
    },
    mutations: {
        updateField,
        SET_ACTIVE_INSPECTION (state, payload) {
            state.activeInspection = payload;
        },
        SET_INSPECTION_QUESTIONS (state, payload) {
            state.inspectionQuestions = payload;
        },
        SET_INSPECTION_QUESTIONS_TOTAL_AMOUNT (state, payload) {
            state.inspectionQuestionsTotalAmount = payload;
        }
    },
    actions: {
        getActiveInspection ({ commit, dispatch }, id) {
            return inspectionsApi.getActiveInspection(id)
                .then(({ data }) => {
                    const endDateTime = data.data.attributes.inspection_end_datetime
                        ? CONVERT_FROM_UTC(data.data.attributes.inspection_end_datetime) : null;
                    const scheduledInspectionDateLocal = data.data.attributes.scheduled_inspection_date_local ? data.data.attributes.scheduled_inspection_date_local.split('+')[0].split('T').join(' ') : '';
                    const inspectionEndDatetimeLocal = data.data.attributes.inspection_end_datetime_local ? data.data.attributes.inspection_end_datetime_local.split('+')[0].split('T').join(' ') : '';
                    commit('SET_ACTIVE_INSPECTION', {
                        id: data.data.id,
                        ...data.data.attributes,
                        parsed_scheduled_inspection_date_local: scheduledInspectionDateLocal,
                        parsed_local_end_date_time: inspectionEndDatetimeLocal,
                        endDate: endDateTime
                            ? `${PARSE_DATE(endDateTime)} ${PARSE_TIME(endDateTime)}` : null,
                        inspectionDate: PARSE_DATE(data.data.attributes.inspection_date)
                    });

                    dispatch('getInspectionAnswers', { id });
                });
        },
        getActiveInspectionByIncidentId ({ commit, dispatch }, params) {
            return inspectionsApiV2.getInspections(params)
                .then((res) => {
                    const resp = res.data.data[0];
                    if (resp) {
                        const scheduledInspectionDateLocal = resp.attributes.scheduled_inspection_date_local ? resp.attributes.scheduled_inspection_date_local.split('+')[0].split('T').join(' ') : '';
                        const inspectionEndDatetimeLocal = resp.attributes.inspection_end_datetime_local ? resp.attributes.inspection_end_datetime_local.split('+')[0].split('T').join(' ') : '';
                        const _data = resp;
                        const _id = _data.id;
                        const endDateTime = _data.attributes.inspection_end_datetime
                            ? CONVERT_FROM_UTC(_data.attributes.inspection_end_datetime) : null;
                        commit('SET_ACTIVE_INSPECTION', {
                            id: _id,
                            ..._data.attributes,
                            parsed_scheduled_inspection_date_local: scheduledInspectionDateLocal,
                            parsed_local_end_date_time: inspectionEndDatetimeLocal,
                            endDate: endDateTime
                                ? `${PARSE_DATE(endDateTime)} ${PARSE_TIME(endDateTime)}` : null,
                            inspectionDate: PARSE_DATE(_data.attributes.inspection_date)
                        });
                        dispatch('getInspectionAnswers', { _id });
                    }
                });
        },
        getInspectionPDF ({ rootState }, id) {
            const queryParams = new URLSearchParams();
            queryParams.set('locale', rootState.locale.currentLocale);

            const url = `${inspectionsApiV2.getPdfReport(id)}?${queryParams}`;

            return forceFileDownload(url, `inspection_${id}.pdf`, false, true);
        },
        getInspectionAnswers ({ commit }, payload) {
            return inspectionsApi.getInspectionAnswers(payload)
                .then(({ data }) => {
                    const _questions = data.data;

                    if (_questions) {
                        _questions.sort((a, b) => {
                            return a.attributes.sequence - b.attributes.sequence;
                        });

                        commit('SET_INSPECTION_QUESTIONS', _questions.map(el => {
                            // const dateTime = el.attributes.answer_date_time;
                            return {
                                id: el.id,
                                ...el.attributes,
                                answer_date_time: el.attributes.answer_date_time ? DateTime
                                    .fromISO(el.attributes.answer_date_time, { zone: 'utc' })
                                    .toFormat('yyyy-MM-dd TT') : ''
                            };
                        }));

                        commit('SET_INSPECTION_QUESTIONS_TOTAL_AMOUNT', data.meta.stats.total.count);
                    }
                });
        },
        getInspectionAnswersLazyLoading ({ state, commit }, payload) {
            return inspectionsApi.getInspectionAnswers(payload)
                .then(({ data }) => {
                    const _questions = data.data;

                    if (_questions) {
                        _questions.sort((a, b) => {
                            return a.attributes.sequence - b.attributes.sequence;
                        });

                        const result = [...state.inspectionQuestions].concat(_questions.map(el => {
                            return {
                                id: el.id,
                                ...el.attributes,
                                answer_date_time: el.attributes.answer_date_time ? DateTime
                                    .fromISO(el.attributes.answer_date_time, { zone: 'utc' })
                                    .toFormat('yyyy-MM-dd TT') : ''
                            };
                        }));

                        commit('SET_INSPECTION_QUESTIONS', result);

                        commit('SET_INSPECTION_QUESTIONS_TOTAL_AMOUNT', data.meta.stats.total.count);
                    }
                });
        },
        clearInspectionData ({ commit }) {
            commit('SET_ACTIVE_INSPECTION', null);
            commit('SET_INSPECTION_QUESTIONS', []);
            commit('SET_INSPECTION_QUESTIONS_TOTAL_AMOUNT', null);
            commit('manageTemplate/SET_ACTIVE_TEMPLATE', null, { root: true });
        },
        deleteInspection (context, id) {
            return inspectionsApiV2.deleteInspection(id);
        },
        deleteManyInspections (context, data) {
            const payload = {
                data: {
                    type: 'inspections',
                    inspections_ids_to_delete: data
                }
            };

            return inspectionsApi.deleteManyInspections(payload);
        }
    }
};
