export const ABILITY_ACTIONS = {
    READ: 'read',
    MANAGE: 'manage',
    UPDATE: 'update',
    CREATE: 'create',
    ADD: 'add',
    DELETE: 'destroy',
    ARCHIVE: 'archive',
    EXPORT: 'export',
    CHANGE_PASS: 'update_employee_logging_attributes',
    IMPORT_TEMPLATE: 'import_template',
    IMPORT_IMPORT_POINT_XLSX: 'import_point_xlsx',
    IMPORT_EMPLOYEES_XLSX: 'import_employees_xlsx',
    IMPORT_PLACES_XLSX: 'import_places_xlsx',
    IMPORT_CUSTOMERS_XLSX: 'import_customers_xlsx',
    INCORRECT_INSPECTIONS_REPORT: 'incorrect_inspections_report',
    CUSTOMERS_REPORT: 'customers_report',
    GLOBAL_VIOLATIONS_REPORT: 'irregularities_count',
    GLOBAL_VIOLATIONS_PER_CUSTOMER: 'irregularities_per_customer',
    INCIDENTS_TYPES_REPORT: 'incident_kinds',
    SET_ATTRIBUTE_ARCHIVED: 'set_attr_archived',
    UNSET_ATTRIBUTE_ARCHIVED: 'unset_attr_archived',
    GET_REPORT_PDF: 'get_pdf_report',
    REFRESH_LOCATIONS_GEOLOCATION: 'refresh_points_geolocation',
    NUMBER_OF_INCIDENTS: 'number_of_incidents',
    SEND_MAIL_WITH_PDF: 'send_mail_with_pdf',
    VIEW: 'view'
};
export const ABILITY_SUBJECTS = {
    CUSTOMER: 'Conf::Customer',
    SITE: 'Conf::Place',
    LOCATION: 'Conf::Point',
    LOCATION_TEMPLATE: 'Conf::PointTemplate',
    SITE_GROUP: 'Conf::PlaceGroup',
    SITE_SITE_GROUP: 'Conf::PlacePlaceGroup',
    CITY: 'Conf::City',
    COUNTRY: 'Conf::Country',
    EMPLOYEE: 'Conf::Employee',
    EMPLOYEE_GROUP: 'Conf::EmployeeGroup',
    POSITION: 'Conf::Position',
    INSPECTION: 'Event::Inspection',
    TEMPLATE: 'Conf::Template',
    TEMPLATE_QUESTIONS: 'Conf::TemplateQuestion',
    INSPECTION_ANSWERS: 'Event::InspectionAnswer',
    QUESTION_TYPES: 'Event::QuestionType',
    INCIDENT: 'Event::Incident',
    INCIDENT_STATUS: 'Event::IncidentStatus',
    INCIDENT_TYPES: 'Event::IncidentKind',
    INCIDENT_COMMENTS: 'Event::IncidentCommentItem',
    INCIDENT_HISTORY: 'Event::IncidentHistoryItem',
    TERRITORY: 'Conf::Territory',
    TRANSLATIONS: 'Settings::Translation',
    POSITION_PERMISSION: 'Settings::PositionPermission',
    POSITION_ROLE: 'Settings::PositionRole',
    ROLE: 'Settings::Role',
    SYSTEM_SETTINGS: 'Settings::SystemSettings',
    EMPLOYEE_MESSAGE_LOG: 'Log::EmployeeMessagesLog',
    SYSTEM_LOG: 'Settings::SystemLog',
    INSPECTION_SCHEDULE: 'Schedules::InspectionSchedule',
    SCHEDULING_METADATA: 'Schedules::SchedulingMetadata',
    CUSTOM_REPORTS: 'Reports::CustomReport',
    REPORTS: 'reports_controller',
    NFC_REPORTS: 'Reports::NfcReport',
    TRACKS: 'TrackPermissions',
    // superadmin
    SUPER_ADMIN_AGENCY: 'Common::Agency',
    SUPER_ADMIN_SELECTED_AGENCY: 'Common::AgencySetting',
    SUPER_ADMIN_USER: 'Common::User',
    SUPER_ADMIN_USER_PERMISSION: 'Common::UserPermission',
    SUPER_ADMIN_PERMISSION: 'Common::Permission',
    // tabs
    REPORT_TAB: 'Report',
    INCIDENT_TAB: 'Incident',
    INSPECTION_TAB: 'Inspection',
    CUSTOMER_TAB: 'Customer',
    CYCLIC_REPORTS_TAB: 'Cyclick_report',
    EMPLOYEE_TAB: 'Employee',
    CONFIGURATION_TAB: 'Configuration',
    SCHEDULING_TAB: 'Schedule',
    SETTINGS_TAB: 'Setting',
    SUBCONTRACTORS_TYPES: 'Conf::CompanyType',
    SUBCONTRACTORS: 'Conf::Company',
    SUBCONTRACTORS_TAB: 'Company',
    TRACKS_TAB: 'Track'
};
