import { RELATIONSHIPS } from '@/helpers/jsonapiHelpers';

class Position {
    constructor (params) {
        this.id = params?.id !== undefined ? params?.id : null;
        this.name = params?.name || '';
        this.config = params?.config || '';
        this.is_client = params?.is_client || 0;
        this.is_inspector = params?.is_inspector || 0;
        this.is_manager = params?.is_manager || 0;
        this.position_permissions = (params?.position_permissions !== undefined) ? params?.position_permissions : {};
    }

    getCreateData (allPermissions) {
        const relations = { 'permissions': { 'data': [] } };
        const current = Object.entries(this.position_permissions).filter(p => p[1] !== undefined);
        for (const [key, value] of current) {
            const existId = Number.parseInt(allPermissions.find(e => e.text === key).value);
            if (value) {
                relations.permissions.data.push(
                    RELATIONSHIPS.CREATE.ADD_PERMISSION(existId)
                );
            } if (!value && this.id) {
                relations.permissions.data.push(
                    {
                        'type': 'permissions',
                        'id': existId,
                        'method': 'disassociate'
                    }
                );
            }
        }
        return {
            data: {
                type: 'positions',
                ...(this.id) && { id: this.id },
                attributes: {
                    name: this.name,
                    is_client: this.is_client,
                    is_manager: this.is_manager,
                    is_inspector: this.is_inspector,
                    config: this.config
                },
                relationships: relations
            }
        };
    }
}

export default Position;
