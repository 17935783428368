import { getField, updateField } from 'vuex-map-fields';
import reportsApi from '@/utils/api/reports';
import CustomReport from '@/models/reports/customReport';
import { CONVERT_FROM_UTC, PARSE_DATE, PARSE_TIME } from '@/helpers/dates';
import { getEndpoint } from '@/utils/endpoints';
import { forceFileDownload } from '@/helpers/files';

const getId = (string) => string?.substring(string.lastIndexOf('/') + 1);

const formatDates = (result, key) => {
    if (Object.hasOwnProperty.call(result, key)) {
        const date = result[key] ? CONVERT_FROM_UTC(result[key]) : null;
        result[key] = date ? `${PARSE_DATE(date)} ${PARSE_TIME(date)}` : null;
    }
};

export default {
    namespaced: true,
    state: () => ({
        reportFormVisibility: false,
        reportData: new CustomReport(),
        customReports: [],
        totalCustomReportsAmount: null,
        activeCustomReport: null,
        singleCustomReport: null
    }),
    getters: { getField },
    mutations: {
        updateField,
        SET_REPORT_FORM_VISIBILITY (state, payload) {
            state.reportFormVisibility = payload;
        },
        RESET_REPORT_DATA (state) {
            state.reportData = new CustomReport();
        },
        RESET_INCIDENT_FIELDS (state) {
            state.reportData.resetIncidentFields();
        },
        SET_CUSTOM_REPORTS (state, payload) {
            state.customReports = payload;
        },
        SET_TOTAL_CUSTOM_REPORTS_AMOUNT (state, payload) {
            state.totalCustomReportsAmount = payload;
        },
        SET_ACTIVE_CUSTOM_REPORT (state, payload) {
            state.activeCustomReport = payload;
        },
        SET_REPORT_DATA (state, payload) {
            const report = new CustomReport();
            report.parseData(payload);

            state.reportData = report;
        },
        SET_SINGLE_CUSTOM_REPORT (state, payload) {
            let result;
            if (payload !== null) {
                result = { ...payload };

                result.data.forEach(el => {
                    formatDates(el, 'start_date_time');
                    formatDates(el, 'end_date_time');
                });
            } else {
                result = payload;
            }

            state.singleCustomReport = result;
        }
    },
    actions: {
        getCustomReports ({ commit }, additionalParams = null) {
            commit('SET_CUSTOM_REPORTS', []);

            const params = {
                'stats[total]': 'count'
            };

            if (additionalParams) {
                Object.keys(additionalParams).forEach(k => {
                    params[k] = additionalParams[k];
                });
            }

            return reportsApi.getCustomReports(params)
                .then(({ data }) => {
                    commit('SET_CUSTOM_REPORTS', data.data.map((el, index) => {
                        const _seq = (index + 1) + ((params['page[number]'] - 1) * params['page[size]']);

                        return {
                            seq: _seq,
                            id: el.id,
                            ...el.attributes
                        };
                    }));

                    if (data.meta.stats) {
                        commit('SET_TOTAL_CUSTOM_REPORTS_AMOUNT', data.meta.stats.total.count);
                    }
                });
        },
        getActiveCustomReport ({ commit, dispatch, rootState }, id) {
            return reportsApi.getActiveCustomReport(id)
                .then(({ data }) => {
                    const result = {
                        id: data.data.id,
                        name: data.data.attributes.name,
                        incidentFields: data.data.attributes.incident_configuration,
                        dateType: data.data.attributes.date_configuration.dates.type,
                        dateFrom: PARSE_DATE(data.data.attributes.date_configuration.dates.start_date) || null,
                        dateTo: PARSE_DATE(data.data.attributes.date_configuration.dates.end_date) || null,
                        templateQuestions: [],
                        incidentTypeId: getId(data.data.relationships.incident_kind.links.related)
                    };

                    data.data.attributes.answers_configuration.forEach(el => {
                        result.templateQuestions.push(el.question);
                    });

                    commit('SET_ACTIVE_CUSTOM_REPORT', result);
                });
        },
        createCustomReport ({ state }) {
            const data = state.reportData.getCreateData();

            return reportsApi.createCustomReport(data);
        },
        updateCustomReport ({ state }) {
            const id = state.activeCustomReport.id;
            const data = state.reportData.getUpdateData(id);

            return reportsApi.updateCustomReport(id, data);
        },
        deleteCustomReport (context, id) {
            return reportsApi.deleteCustomReport(id);
        },
        getSingleCustomReport ({ commit }, params) {
            return reportsApi.getSingleCustomReport(params.id, params.query)
                .then(({ data }) => {
                    commit('SET_SINGLE_CUSTOM_REPORT', data);
                });
        },
        getCustomReportPdf (context, additionalParams) {
            const params = {};

            Object.keys(additionalParams).forEach(k => {
                if (k !== 'id') {
                    params[k] = additionalParams[k];
                }
            });

            const urlParams = new URLSearchParams();

            Object.entries(params).forEach(el => {
                urlParams.set(el[0], el[1]);
            });

            const url = getEndpoint('custom_report_pdf',
                { id: additionalParams.id }) + `?${urlParams}`;

            return forceFileDownload(url, `custom_report_${additionalParams.id}.pdf`);
        },
        getCustomReportXls (context, additionalParams) {
            const params = {};

            Object.keys(additionalParams).forEach(k => {
                if (k !== 'id') {
                    params[k] = additionalParams[k];
                }
            });

            const urlParams = new URLSearchParams();

            Object.entries(params).forEach(el => {
                urlParams.set(el[0], el[1]);
            });

            const url = getEndpoint('custom_report_xls',
                { id: additionalParams.id }) + `?${urlParams}`;

            return forceFileDownload(url, `custom_report_${additionalParams.id}.xls`);
        }
    }
};
