import KmsHttpService from '@/servises/kmsHttpService';
import { getV2Endpoint } from '@/utils/apiV2Endpoints';

export default {
    getSubContractorsTypes: (params) => {
        return KmsHttpService.get(getV2Endpoint('subcontractors_types'), { params: params });
    },
    getActiveSubContractorsTypes: (id) => {
        return KmsHttpService.get(getV2Endpoint('manage_subcontractors_types', { id: id }));
    },
    createSubContractorsTypes: (payload) => {
        return KmsHttpService.post(getV2Endpoint('subcontractors_types'), payload);
    },
    updateSubContractorsTypes: (id, payload) => {
        return KmsHttpService.patch(getV2Endpoint('manage_subcontractors_types', { id: id }), payload);
    },
    deleteSubContractorsTypes: (id) => {
        return KmsHttpService.delete(getV2Endpoint('manage_subcontractors_types', { id: id }));
    }
};
