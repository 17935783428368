export default {
    path: '/cyclic-reports',
    component: () => import(/* webpackChunkName: "index" */ '@/views/index'),
    redirect: { name: 'tracks' },
    children: [
        {
            path: '/',
            name: 'tracks',
            component: () => import(/* webpackChunkName: "tracks" */ '@/views/cyclicRevision/Tracks.vue')
        },
        {
            path: '/add-track',
            name: 'trackManagement',
            component: () => import(/* webpackChunkName: "tracks-management" */ '@/views/cyclicRevision/TrackManagement.vue')
        },
        {
            path: '/edit-track/:id',
            name: 'editTrack',
            component: () => import(/* webpackChunkName: "tracks-management" */ '@/views/cyclicRevision/TrackManagement.vue')
        }
    ]
};
