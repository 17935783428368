import holidaysCalendarApi from '@/utils/api/holidaysCalendar';
import Holiday from '@/models/settings/holiday';
import { getField, updateField } from 'vuex-map-fields';

export default {
    namespaced: true,
    state: () => ({
        holidays: [],
        totalHolidaysAmount: null,
        holidayFormVisibility: false,
        editedHolidayId: null,
        holidayData: new Holiday()
    }),
    getters: { getField },
    mutations: {
        updateField,
        SET_HOLIDAYS (state, payload) {
            state.holidays = payload;
        },
        SET_TOTAL_HOLIDAYS_AMOUNT (state, payload) {
            state.totalHolidaysAmount = payload;
        },
        SET_HOLIDAY_FORM_VISIBILITY (state, payload) {
            state.holidayFormVisibility = payload;
        },
        SET_EDITED_HOLIDAY_ID (state, payload) {
            state.editedHolidayId = payload;
        },
        RESET_HOLIDAY_DATA (state) {
            state.holidayData = new Holiday();
        },
        SET_HOLIDAY_DATA (state, payload) {
            state.holidayData.parseData(payload);
        }
    },
    actions: {
        getHolidays ({ commit }, additionalParams = null) {
            commit('SET_HOLIDAYS', []);

            const params = {
                'stats[total]': 'count',
                sort: 'name'
            };

            if (additionalParams) {
                Object.keys(additionalParams).forEach(k => {
                    params[k] = additionalParams[k];
                });
            }

            return holidaysCalendarApi.getHolidays(params)
                .then(({ data }) => {
                    commit('SET_HOLIDAYS', data.data.map(el => new Holiday({
                        id: el.id,
                        name: el.attributes.name,
                        date: el.attributes.date
                    })));

                    if (data.meta.stats) {
                        commit('SET_TOTAL_HOLIDAYS_AMOUNT', data.meta.stats.total.count);
                    }
                });
        },
        createHoliday ({ state }) {
            const data = state.holidayData.getCreateData();

            return holidaysCalendarApi.createHoliday(data);
        },
        updateHoliday ({ state }) {
            const data = state.holidayData.getUpdateData();

            return holidaysCalendarApi.updateHoliday(data);
        },
        deleteHoliday (context, id) {
            return holidaysCalendarApi.deleteHoliday(id);
        }
    }
};
