import tracksApi from '@/utils/api-v2/tracks';
import inspectionsApi from '@/utils/api-v2/inspections';
import Track from '@/models/tracks/track';
import { getField, updateField } from 'vuex-map-fields';

export default {
    namespaced: true,
    state: () => ({
        trackData: new Track(),
        inspectionsTemplates: new Map()
    }),
    getters: {
        getField,
        activeTrack: state => state.trackData.id
    },
    mutations: {
        updateField,
        RESET_TRACK_DATA (state) {
            state.trackData = new Track();
        },
        SET_STEP_TEMPLATES (state, payload) {
            state.inspectionsTemplates.set(payload.point_id, payload.templates);
        },
        SET_TRACK_DATA (state, payload) {
            const track = new Track();
            track.parseData(payload);
            state.trackData = track;
        }
    },
    actions: {
        getActiveTrack ({ commit, dispatch }, id) {
            return tracksApi.getTrack(id).then(({ data }) => {
                commit('RESET_TRACK_DATA');
                commit('SET_TRACK_DATA', data);
            });
        },
        createTrack ({ state }) {
            const payload = state.trackData.getDataForCreate();
            return tracksApi.createTrack(payload);
        },
        updateTrack ({ state }) {
            const payload = state.trackData.getDataForCreate();
            return tracksApi.updateTrack(state.trackData.id, payload);
        },
        clearTrack ({ commit }) {
            commit('RESET_TRACK_DATA');
        },
        getStepInspectionTemplates ({ state, commit }, pointId) {
            const pointTemplates = state.inspectionsTemplates.get(pointId);
            if (pointTemplates) return pointTemplates.templates;

            return inspectionsApi.getInspectionTemplatesByPoint({ params: { point_id: pointId } }).then(({ data }) => {
                const mappedData = data.map(el => ({ value: el.id, text: el.name }));
                commit('SET_STEP_TEMPLATES', { point_id: pointId, templates: mappedData });
            });
        }
    }
};
