import getSystemLogTypesApi from '@/utils/api/systemLogs';

export default {
    namespaced: true,
    state: () => ({
        systemLogTypes: []
    }),
    getters: {
        filterableTypes: state => {
            return state.systemLogTypes.map(el => ({
                value: el.id,
                text: el.attributes.short_name
            }));
        }
    },
    mutations: {
        SET_RAW (state, payload) {
            state.systemLogTypes = payload;
        }
    },
    actions: {
        getSystemLogTypes ({ commit }, additionalParams = null) {
            const params = {};
            if (additionalParams) {
                Object.keys(additionalParams).forEach(k => {
                    params[k] = additionalParams[k];
                });
            }
            return getSystemLogTypesApi.getSystemLogTypes(params)
                .then(({ data }) => {
                    commit('SET_RAW', data.data);
                });
        }
    }
};
