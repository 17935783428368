import HttpService from '@/servises/HttpService';
import { getEndpoint } from '@/utils/endpoints';
import KmsHttpService from '@/servises/kmsHttpService';
import { getV2Endpoint } from '@/utils/apiV2Endpoints';

export default {
    getSites: (params) => {
        return HttpService.get(getEndpoint('sites'), {
            params: params
        });
    },
    getActiveSite: (id, agency) => {
        return KmsHttpService.get(getV2Endpoint('manage_site', { id: id }));
    },
    createSite: (payload) => {
        return HttpService.post(getEndpoint('sites'), payload);
    },
    updateSite: (id, payload, params) => {
        return HttpService.patch(getEndpoint('manage_site', { id: id }), payload, params);
    },
    update: (id, payload) => {
        return HttpService.patch(getEndpoint('manage_site', { id: id }), payload);
    },
    importSites: (data) => {
        return HttpService.post(getEndpoint('import_sites'), data);
    },
    importSiteAttachment: (id, data, agency) => {
        return KmsHttpService.postFile(getV2Endpoint('import_locations_attachment', { id: id }), data.data);
    },
    removeSiteAttachment: (id, agency) => {
        return KmsHttpService.delete(getV2Endpoint('remove_locations_attachment', { id: id }));
    }
};
