export default {
    path: '/scheduling',
    component: () => import(/* webpackChunkName: "Index" */ '@/views/index'),
    redirect: { name: 'scheduling' },
    children: [
        {
            path: '',
            name: 'scheduling',
            component: () => import(/* webpackChunkName: "Scheduling" */'@/views/scheduling/Scheduling.vue')
        }
    ]
};
