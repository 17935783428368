import Vue from 'vue';
import VueRouter from 'vue-router';
import home from './routes/home';
import inspections from './routes/inspections';
import management from './routes/management';
import authorization from './routes/authorization';
import store from '@/store';
import superadmin from './routes/superadmin';
import incidents from './routes/incidents';
import reports from './routes/reports';
import editpassword from './routes/editpassword';
import scheduling from './routes/scheduling';
import employees from './routes/employees';
import elements from './routes/elements';
import configurations from './routes/configurations';
import settings from './routes/settings';
import microserviceReports from './routes/microserviceReports';
import cyclicRevisions from './routes/cyclicRevisions';
import subContactors from './routes/subContactors';
import events from './routes/events';

Vue.use(VueRouter);

const indexRoutes = [
    home,
    authorization,
    editpassword,
    inspections,
    management,
    incidents,
    reports,
    scheduling,
    employees,
    elements,
    configurations,
    settings,
    microserviceReports,
    cyclicRevisions,
    events,
    subContactors
];

const superAdminRoutes = [
    superadmin,
    authorization,
    editpassword
];

const host = window.location.host.split('.')[0];
const routes = host === 'superadmin' ? superAdminRoutes : indexRoutes;

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

router.beforeEach((to, from, next) => {
    store.dispatch('authorization/checkAuthorization')
        .then(() => {
            if (to.name !== 'login' && to.name !== 'editpassword' && !store.state.authorization.isLogged) next({ name: 'login' });
            else next();
        });
});
export default router;
