import { getReportsEndpoint, getUrlParamsObj } from '../reportsEndpoints';
import SecondaryHttpService from '@/servises/secondaryHttpService';

export default {
    getEztReports: (params) => {
        const request = {
            params: getUrlParamsObj(params)
        };
        return SecondaryHttpService.get(getReportsEndpoint('ezt'), request);
    },
    getSestLuvWithCommentsReports: (params) => {
        const request = {
            params: getUrlParamsObj(params)
        };
        return SecondaryHttpService.get(getReportsEndpoint('sestLuvComments'), request);
    },
    getSestLuvWeeksReports: (params) => {
        const request = {
            params: getUrlParamsObj(params)
        };
        return SecondaryHttpService.get(getReportsEndpoint('sestLuvWeeks'), request);
    }
};
