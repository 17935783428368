
class subContractors {
    constructor (params) {
        this.id = params?.id !== undefined ? params?.id : null;
        this.name = params?.name || '';
        this.type_id = params?.type?.id || null;
        this.type_name = params?.type?.name || '';
        this.sms_receivers = params?.sms_receivers.map(item => '' + item) || [];
        this.email_receivers = params?.email_receivers.map(item => '' + item) || [];
    }

    getCreateData () {
        return {
            name: this.name,
            type: this.type_id,
            sms_receivers: this.sms_receivers.map(item => parseInt(item, 10)),
            email_receivers: this.email_receivers.map(item => parseInt(item, 10))
        };
    }

    getDataForUpdate () {
        return this.getCreateData();
    }
}

export default subContractors;
