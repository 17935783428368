import { getField, updateField } from 'vuex-map-fields';
import { getEndpoint } from '@/utils/endpoints';
import employeesApi from '@/utils/api/employees';
import osmApi from '@/utils/api-v2/osm';
import Employee from '@/models/employees/employee';
import { forceFileDownload } from '@/helpers/files';

export default {
    namespaced: true,
    state: () => ({
        employeeFormVisibility: false,
        editEmployeeId: null,
        employeeData: new Employee(),
        activeEmployee: null,
        employeeDataForShowAssignedPoints: null,
        employeeDetailsVisibility: false,
        employeeSites: null
    }),
    getters: {
        getField,
        employeeSitesDetails: state => state.employeeSites?.included?.map(el => ({
            ...el.attributes
        })) || [],
        isLoginDataChanged: state => state.employeeData.password?.length &&
            state.employeeData.password !== state.employeeData.passwordConfirmation
    },
    mutations: {
        updateField,
        SET_EMPLOYEE_FORM_VISIBILITY (state, payload) {
            state.employeeFormVisibility = payload;
        },
        SET_EMPLOYEE_DETAILS_VISIBILITY (state, payload) {
            state.employeeDetailsVisibility = payload;
        },
        SET_EDIT_EMPLOYEE_ID (state, payload) {
            state.editEmployeeId = payload;
        },
        RESET_EMPLOYEE_DATA (state) {
            state.employeeData = new Employee();
        },
        SET_ACTIVE_EMPLOYEE (state, payload) {
            state.activeEmployee = payload;
        },
        SET_ACTIVE_EMPLOYEE_SITES (state, payload) {
            state.employeeSites = payload;
        },
        SET_EMPLOYEE_FOR_SHOW_POINTS (state, payload) {
            state.employeeDataForShowAssignedPoints = payload;
        },
        CLEAR_EMPLOYEE_FOR_SHOW_POINTS (state) {
            state.employeeDataForShowAssignedPoints = null;
        },
        SET_EMPLOYEE_DATA (state, payload) {
            const employee = new Employee();
            employee.parseData(payload);

            state.employeeData = employee;
        },
        RESET_LOGIN_DATA (state) {
            state.employeeData.resetLoginData(state.activeEmployee?.login);
        }
    },
    actions: {
        getActiveEmployee ({ commit }, params) {
            const { id, isElement } = params;

            return employeesApi.getActiveEmployee(id)
                .then(({ data }) => {
                    const employeeId = data.data.id;
                    commit('SET_ACTIVE_EMPLOYEE', {
                        id: data.data.id,
                        ...data.data.attributes,
                        relationships: data.data.relationships
                    });
                    commit('SET_EDIT_EMPLOYEE_ID', employeeId);
                    commit('SET_EMPLOYEE_DATA', data.data);
                    if (!isElement) {
                        osmApi.getDeviceByEmployee(employeeId).then(({ data }) => {
                            const deviceObject = data.results[0];
                            const device = {
                                id: deviceObject?.id || '',
                                deviceSerialNumber: deviceObject?.serial_number || '',
                                deviceType: deviceObject?.device_type || ''
                            };
                            commit('osm/SET_DEVICE_DATA', device, { root: true });
                        });
                    }
                });
        },
        getSitesForActiveEmployee ({ commit }, id) {
            return employeesApi.getSitesForActiveEmployee(id)
                .then(({ data }) => {
                    commit('SET_ACTIVE_EMPLOYEE_SITES', data);
                });
        },
        getEmployeeDataForShowAssignedPoints ({ commit }, id) {
            return employeesApi.getSingleEmployee(id)
                .then(({ data }) => {
                    commit('SET_EMPLOYEE_FOR_SHOW_POINTS', {
                        id: data.data.id,
                        ...data.data.attributes,
                        relationships: data.data.relationships
                    });
                });
        },
        clearActiveEmployee ({ commit }) {
            commit('RESET_EMPLOYEE_DATA');
            commit('SET_EDIT_EMPLOYEE_ID', null);
            commit('SET_ACTIVE_EMPLOYEE', null);
            commit('SET_ACTIVE_EMPLOYEE_SITES', null);
            commit('osm/RESET_DEVICE_DATA', null, { root: true });
        },
        createEmployee ({ state, commit }) {
            const data = state.employeeData.getCreateData();

            return employeesApi.createEmployee(data)
                .then((employeeData) => {
                    return employeeData;
                });
        },
        updateEmployee ({ state, commit }, id) {
            const data = state.employeeData.getUpdateData(id);
            return employeesApi.updateEmployee(id, data)
                .then(() => {
                });
        },
        deleteEmployee (context, id) {
            return employeesApi.deleteEmployee(id);
        },
        getEmployeeQrCode (context, id) {
            return forceFileDownload(getEndpoint('get_employee_qrcode_pdf', { id: id }), `employee_${id}.pdf`);
        },
        deleteManyEmployees (context, data) {
            const payload = {
                data: {
                    type: 'employees',
                    employees_ids_to_delete: data
                }
            };

            return employeesApi.deleteManyEmployees(payload);
        },
        changeLoginData ({ state }) {
            const data = {
                id: state.editEmployeeId,
                data: state.employeeData.getUpdateLoginData()
            };

            return employeesApi.changeLoginData(data);
        }
    }
};
