export default {
    path: '/customersAndSites',
    component: () => import(/* webpackChunkName: "Index" */ '@/views/index'),
    redirect: { name: 'customers' },
    children: [
        {
            path: 'customers',
            name: 'customers',
            component: () => import(/* webpackChunkName: "customersAndSites" */ '@/views/customers/Customers.vue')
        },
        {
            path: 'sites',
            name: 'sites',
            component: () => import(/* webpackChunkName: "customersAndSites" */ '@/views/customers/Sites')
        },
        {
            path: 'locations',
            name: 'locations',
            component: () => import(/* webpackChunkName: "customersAndSites" */ '@/views/customers/Locations')
        }
    ]
};
