export default {
    path: '/inspections',
    component: () => import(/* webpackChunkName: "Index" */ '@/views/index'),
    redirect: { name: 'inspectionsSummary' },
    children: [
        {
            path: 'summary',
            name: 'inspectionsSummary',
            component: () => import(/* webpackChunkName: "InspectionsSummary" */ '@/views/inspections/InspectionsSummary')
        }
    ]
};
