import templatesApi from '@/utils/api/templates';
import { CONVERT_FROM_UTC, PARSE_DATE, PARSE_TIME } from '@/helpers/dates';
import { getField, updateField } from 'vuex-map-fields';

const setTemplatesData = (data) => {
    return data.map(el => ({
        id: el.id,
        identification_number: el.attributes.identification_number,
        archive: el.attributes.archive,
        color: el.attributes.color,
        name: el.attributes.name,
        permitted_persons: el.attributes.permitted_persons,
        special_attention: el.attributes.special_attention,
        number_of_random_questions: el.attributes.number_of_random_questions,
        created_at: el.attributes.created_at
            ? `${PARSE_DATE(CONVERT_FROM_UTC(el.attributes.created_at))} ${PARSE_TIME(CONVERT_FROM_UTC(el.attributes.created_at))}`
            : el.attributes.created_at,
        customers_ids: el.attributes.customers_ids,
        places_ids: el.attributes.places_ids,
        points_ids: el.attributes.points_ids
    }));
};

const defaultGroupAssignmentData = () => ({
    action: null,
    employeesGroupsIds: [],
    employeesIds: [],
    customersIds: [],
    sitesIds: [],
    locationsIds: [],
    templatesIds: []
});

export default {
    namespaced: true,
    state: () => ({
        rawTemplates: [],
        secondRawTemplates: [],
        rawTemplateQuestions: [],
        totalTemplatesAmount: null,
        secondtotalTemplatesAmount: null,
        templateQuestionTypes: [],
        groupAssignmentData: defaultGroupAssignmentData()
    }),
    getters: {
        getField,
        filterableTemplates: state => state.rawTemplates.map(el => ({
            value: el.id,
            text: el.name
        })),
        secondFilterableTemplates: state => state.secondRawTemplates.map(el => ({
            value: el.id,
            text: el.name
        })),
        filterableTemplateQuestions: state => state.rawTemplateQuestions.map(el => ({
            value: el.id,
            text: el.question
        }))
    },
    mutations: {
        updateField,
        SET_RAW_TEMPLATES (state, payload) {
            state.rawTemplates = payload;
        },
        SET_SECOND_RAW_TEMPLATES (state, payload) {
            state.secondRawTemplates = payload;
        },
        SET_RAW_TEMPLATE_QUESTIONS (state, payload) {
            state.rawTemplateQuestions = payload;
        },
        SET_TOTAL_TEMPLATES_AMOUNT (state, payload) {
            state.totalTemplatesAmount = payload;
        },
        SET_TEMPLATE_QUESTION_TYPES (state, payload) {
            state.templateQuestionTypes = payload;
        },
        RESET_GROUP_ASSIGNMENT_DATA (state) {
            state.groupAssignmentData = defaultGroupAssignmentData();
        }
    },
    actions: {
        getTemplates ({ commit }, additionalParams = null) {
            const secondTemplate = additionalParams && additionalParams.secondTemplate;
            if (secondTemplate) {
                commit('SET_SECOND_RAW_TEMPLATES', []);
            } else {
                commit('SET_RAW_TEMPLATES', []);
            }
            const params = {
                sort: 'name',
                'stats[total]': 'count'
            };

            if (additionalParams) {
                Object.keys(additionalParams).forEach(k => {
                    params[k] = additionalParams[k];
                });
            }

            return templatesApi.getTemplates(params)
                .then(({ data }) => {
                    if (additionalParams && additionalParams.secondTemplate) {
                        commit('SET_SECOND_RAW_TEMPLATES', setTemplatesData(data.data));
                    } else {
                        commit('SET_RAW_TEMPLATES', setTemplatesData(data.data));
                    }

                    if (data.meta.stats) {
                        commit('SET_TOTAL_TEMPLATES_AMOUNT', data.meta.stats.total.count);
                    }
                });
        },
        getTemplatesLazyLoading ({ commit, state }, additionalParams = null) {
            const secondTemplate = additionalParams && additionalParams.secondTemplate;
            const params = {
                sort: 'name',
                'stats[total]': 'count'
            };

            if (additionalParams) {
                Object.keys(additionalParams).forEach(k => {
                    params[k] = additionalParams[k];
                });
            }

            return templatesApi.getTemplates(params)
                .then(({ data }) => {
                    if (secondTemplate) {
                        const result = [...state.secondRawTemplates].concat(setTemplatesData(data.data));
                        commit('SET_SECOND_RAW_TEMPLATES', result);
                    } else {
                        const result = [...state.rawTemplates].concat(setTemplatesData(data.data));
                        commit('SET_RAW_TEMPLATES', result);
                    }

                    if (data.meta.stats) {
                        commit('SET_TOTAL_TEMPLATES_AMOUNT', data.meta.stats.total.count);
                    }
                });
        },
        getTemplateQuestions ({ commit }, params = null) {
            return templatesApi.getQuestions(params)
                .then(({ data }) => {
                    commit('SET_RAW_TEMPLATE_QUESTIONS', data.data.map(el => ({
                        id: el.id,
                        ...el.attributes
                    })));
                });
        },
        getQuestionTypes ({ commit, rootState }) {
            return templatesApi.getQuestionTypes({
                params: {
                    'locale': rootState.locale.currentLocale || 'en'
                }
            })
                .then(({ data }) => {
                    commit('SET_TEMPLATE_QUESTION_TYPES', data.data.map(el => ({
                        value: el.id,
                        text: el.attributes.name
                    })));
                });
        },
        assignTemplatesToPoints (context, data) {
            return templatesApi.assignTemplatesToPoints({
                data: data
            });
        },
        unassignTemplatesFromPoints (context, data) {
            return templatesApi.unassignTemplatesFromPoints({
                data: data
            });
        }
    }
};
