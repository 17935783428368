import reportsApi from '@/utils/reports-api/reports';

export default {
    namespaced: true,
    state: () => ({
        eztReports: [],
        eztReportsTotalAmount: null,
        eztReportsTotalDuration: null,
        sestLuvReports: [],
        sestLuvWeeksReports: [],
        sestLuvWeeksList: [],
        sestLuvWeeksReportsTotalAmount: null,
        sestLuvReportsComments: [],
        sestLuvReportsCommentsTotalAmount: null
    }),

    mutations: {
        SET_EZT_REPORTS (state, payload) {
            state.eztReports = payload;
        },
        SET_EZT_REPORTS_TOTAL_AMOUNT (state, payload) {
            state.eztReportsTotalAmount = payload;
        },
        SET_EZT_REPORTS_TOTAL_DURATION (state, payload) {
            state.eztReportsTotalDuration = payload;
        },
        SET_SEST_LUV_REPORTS_WITH_COMMENTS (state, payload) {
            state.sestLuvReports = payload;
        },
        SET_SEST_LUV_WEEKS_REPORTS (state, payload) {
            state.sestLuvWeeksReports = payload;
        },
        SET_SEST_LUV_WEEKS_LIST (state, payload) {
            state.sestLuvWeeksList = payload;
        },
        SET_SEST_LUV_WEEKS_REPORTS_TOTAL_AMOUNT (state, payload) {
            state.sestLuvWeeksReportsTotalAmount = payload;
        },
        SET_SEST_LUV_REPORTS_COMMENTS (state, payload) {
            state.sestLuvReportsComments = payload;
        },
        SET_SEST_LUV_REPORTS_COMMENTS_TOTAL_AMOUNT (state, payload) {
            state.sestLuvReportsCommentsTotalAmount = payload;
        }

    },
    actions: {
        getEztReports ({ commit }, params = null) {
            commit('SET_EZT_REPORTS', []);

            return reportsApi.getEztReports(params)
                .then(({ data }) => {
                    commit('SET_EZT_REPORTS', data.data.map((el, index) => ({
                        id: el.num_row,
                        ...el
                    })));
                    commit('SET_EZT_REPORTS_TOTAL_AMOUNT', data.meta.amount);
                    commit('SET_EZT_REPORTS_TOTAL_DURATION', data.total_duration);
                });
        },
        getSestLuvReportWithComments ({ commit }, params = null) {
            commit('SET_SEST_LUV_REPORTS_WITH_COMMENTS', []);

            return reportsApi.getSestLuvWithCommentsReports(params)
                .then(({ data }) => {
                    commit('SET_SEST_LUV_REPORTS_WITH_COMMENTS', data.inspections.map((el) => ({
                        ...el
                    })));
                    commit('SET_SEST_LUV_REPORTS_COMMENTS', data.comments.map((el) => ({
                        ...el
                    })));
                    commit('SET_SEST_LUV_REPORTS_COMMENTS_TOTAL_AMOUNT', data.meta.total_comments);
                });
        },
        getSestLuvWeeksReports ({ commit }, params = null) {
            commit('SET_SEST_LUV_WEEKS_REPORTS', []);

            return reportsApi.getSestLuvWeeksReports(params)
                .then(({ data }) => {
                    commit('SET_SEST_LUV_WEEKS_REPORTS', data.inspections.map((e) => ({
                        ...e
                    })));
                    commit('SET_SEST_LUV_WEEKS_REPORTS_TOTAL_AMOUNT', data.meta.total_inspections);
                    commit('SET_SEST_LUV_WEEKS_LIST', data.weeks);
                });
        }
    }
};
