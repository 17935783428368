import HttpService from '@/servises/HttpService';
import { getEndpoint } from '@/utils/endpoints';

export default {
    getSettings: (params) => {
        return HttpService.get(getEndpoint('system_settings'), {
            params: params
        });
    },
    getSynchSetupEntries: (params) => {
        return HttpService.get(getEndpoint('synch_setup_entries'), {
            params: params
        });
    },
    updateSynchSetupEntries: async (data) => {
        const arr = [];
        if (data.length > 0) {
            data.forEach(el => {
                const data = el;
                delete data.attributes.writable;
                delete data.attributes.name;
                const body = {
                    'data': {
                        ...data
                    }
                };
                arr.push(HttpService.patch(getEndpoint('manage_synch_setup_entries', { id: el.id }), body));
            });
        }
        return await Promise.all(arr);
    },
    updateSettings: (payload) => {
        const data = {
            data: [...payload]
        };
        return HttpService.patch(getEndpoint('update_many_system_settings'), data);
    }
};
