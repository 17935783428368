import store from '@/store';

export function onFulfilled (response) {
    return response;
}

export function onRejected (error) {
    let payload;
    if (error.response?.data) {
        payload = {
            status: error.response.status,
            text: getErrorText(error),
            title: getErrorTitle(error)
        };
        store.commit('error/SET_ERROR', payload);
    }

    return Promise.reject(error);
}

function getErrorTitle (error) {
    let title = '';
    if (Array.isArray(error.response.data.detail)) {
        title = error.response.data.detail.map(e => e.msg);
    } else {
        title = error.response.data.detail.msg;
    }
    return title;
}

function getErrorText (error) {
    let text = '';
    if (Array.isArray(error.response.data.detail)) {
        text = error.response.data.detail.map(e => e.loc);
    } else {
        text = error.response.data.detail;
    }
    return text;
}
