import EmployeesGroup from '@/models/employees/employeesGroup';
import employeesApi from '@/utils/api/employees';
import { getField, updateField } from 'vuex-map-fields';

export default {
    namespaced: true,
    state: () => ({
        groupData: new EmployeesGroup(),
        groupFormVisibility: false,
        editedGroupId: null
    }),
    getters: { getField },
    mutations: {
        updateField,
        SET_GROUP_FORM_VISIBILITY (state, payload) {
            state.groupFormVisibility = payload;
        },
        SET_EDITED_GROUP_ID (state, payload) {
            state.editedGroupId = payload;
        },
        RESET_GROUP_DATA (state) {
            state.groupData = new EmployeesGroup();
        },
        SET_GROUP_DATA (state, params) {
            state.groupData.parseData(params);
        }
    },
    actions: {
        createEmployeesGroup ({ state }) {
            const data = state.groupData.getCreateData();

            return employeesApi.createEmployeesGroup(data);
        },
        updateEmployeesGroup ({ state }) {
            const data = state.groupData.getUpdateData();

            return employeesApi.updateEmployeesGroup(data);
        },
        deleteEmployeesGroup (context, id) {
            return employeesApi.deleteEmployeesGroup(id);
        }
    }
};
