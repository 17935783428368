<template>
    <Modal :dialog="dialog"
           scrollable
           :with-close-button="false"
           max-width="500px"
           @close="close"
           @visibility="handleVisibility($event)"
           hide-overlay>
        <template slot="content">
            <v-card>
                <v-card-title v-if="title" class="text--m pt-2 pb-2">{{ title }}</v-card-title>
                <v-divider v-if="title"></v-divider>
                <v-card-text>
                    <v-alert :value="true"
                             color="error"
                             class="mb-0 pa-2"
                             :icon="ICONS.ALERT"
                             outlined>
                        {{ text }}
                    </v-alert>
                </v-card-text>
            </v-card>
        </template>
    </Modal>
</template>

<script>
import { mapState } from 'vuex';
import Modal from '@/components/templates/Modal';
import { ICONS } from '@/constants/icons';

export default {
    name: 'Error',
    components: { Modal },
    data: () => ({
        dialog: false,
        ICONS
    }),
    computed: {
        ...mapState('error', [
            'error'
        ]),
        title () {
            return this.error.title ? `${this.error.title} (${this.error.status})` : '';
        },
        text () {
            return this.error.text || '';
        }
    },
    watch: {
        'error.visibility': {
            handler (val) {
                this.dialog = val;
            }
        }
    },
    methods: {
        resetError () {
            this.$store.commit('error/RESET_ERROR');
        },
        close () {
            this.resetError();
            this.dialog = false;
        },
        handleVisibility (event) {
            this.dialog = event;
            if (!event) {
                this.close();
            }
        }
    }
};
</script>

<style scoped>

</style>
