import { CONVERT_FROM_UTC, PARSE_DATE, PARSE_DATETIME, PARSE_TIME } from '@/helpers/dates';

export const PARSE_INCIDENTS = (incidents, params) => {
    return incidents.map((el, index) => {
        const dateTime = el.date_of_occurrence ? CONVERT_FROM_UTC(el.date_of_occurrence) : '';
        const startDate = dateTime ? PARSE_DATE(dateTime) : '';
        const startTime = dateTime ? PARSE_TIME(dateTime) : '';

        const endDateTime = el.date_ending ? CONVERT_FROM_UTC(el.date_ending) : '';
        const endDate = endDateTime ? PARSE_DATE(endDateTime) : '';
        const endTime = endDateTime ? PARSE_TIME(endDateTime) : '';

        const _seq = (index + 1) + ((params.params.page - 1) * params.params.page_size);

        const statusId = el.status.id;
        const typeId = el.incident_type.id;
        const localStartDateTime = el.start_date_time_local ? PARSE_DATETIME(new Date(el.start_date_time_local).toISOString()) : '';
        const localEndDateTime = el.end_date_time_local ? PARSE_DATETIME(new Date(el.end_date_time_local).toISOString()) : '';

        return {
            seq: _seq,
            id: el.id,
            ...el,
            incidents_date: `${startDate} ${startTime}`,
            parsed_local_start_date_time: localStartDateTime,
            parsed_local_end_date_time: localEndDateTime,
            end_date: `${endDate} ${endTime}`,
            status_id: statusId,
            type_id: typeId
        };
    });
};
