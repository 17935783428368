export const TYPES = {
    RATING: 1,
    PICTURES: 2,
    TEXT: 3,
    MULTIPLE_CHOICE: 4,
    PERCENT: 5,
    SINGLE_CHOICE: 6,
    YES_NO: 7,
    QR_CODE: 8,
    VALUE: 9,
    SIGNATURE: 10,
    DATE: 11,
    TIME: 12,
    INFORMATION: 13,
    HEADER: 99
};

export const WEB_TYPES_TO_SKIP = [TYPES.PICTURES, TYPES.QR_CODE, TYPES.SIGNATURE];
export const HEADER_ONLY_TYPES = [TYPES.INFORMATION, TYPES.HEADER];

export const QUESTION_COMPONENTS = new Map()
    .set(TYPES.RATING, {
        name: 'Rating',
        attributes: ['value', 'label', 'incorrect']
    })
    .set(TYPES.PICTURES, {
        name: 'PicturesAnswers',
        attributes: ['pictures']
    })
    .set(TYPES.TEXT, {
        name: 'QuestionText',
        attributes: ['value', 'label']
    })
    .set(TYPES.MULTIPLE_CHOICE, {
        name: 'MultipleChoice',
        attributes: ['values', 'options', 'incorrect']
    })
    .set(TYPES.PERCENT, {
        name: 'Percent',
        attributes: ['value', 'label', 'incorrect']
    })
    .set(TYPES.SINGLE_CHOICE, {
        name: 'SingleChoice',
        attributes: ['value', 'label', 'options', 'incorrect']
    })
    .set(TYPES.YES_NO, {
        name: 'YesNo',
        attributes: ['value', 'label', 'incorrect']
    })
    .set(TYPES.VALUE, {
        name: 'QuestionValue',
        attributes: ['value', 'label', 'incorrect']
    })
    .set(TYPES.SIGNATURE, {
        name: 'PicturesAnswers',
        attributes: ['pictures']
    })
    .set(TYPES.DATE, {
        name: 'QuestionDate',
        attributes: ['value', 'label']
    })
    .set(TYPES.TIME, {
        name: 'QuestionTime',
        attributes: ['value', 'label']
    })
    .set(TYPES.INFORMATION, {
        name: 'QuestionInformation',
        attributes: ['label']
    })
    .set(TYPES.HEADER, {
        name: 'QuestionHeader',
        attributes: ['label']
    });
