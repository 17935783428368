import commonApi from '@/utils/api/common';

export default {
    namespaced: true,
    state: {
        configCode: ''
    },
    mutations: {
        SET_CONFIG_CODE (state, payload) {
            state.configCode = payload;
        }
    },
    actions: {
        getConfigCode ({ rootState, commit }) {
            return commonApi.getConfigurationQRCode(rootState.generalConfigurations.host)
                .then(({ data }) => {
                    commit('SET_CONFIG_CODE', `data:image/svg+xml;base64,${data.data.qr_code_svg}`);
                });
        }
    }
};
