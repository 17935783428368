
export const INCIDENT_CONFIGURATION = {
    NUMBER: 'number',
    CUSTOMER: 'customer',
    SITE: 'place',
    LOCATION: 'point',
    START_DATE: 'start_date_time',
    END_DATE: 'end_date_time',
    LATITUDE: 'latitude',
    LONGITUDE: 'longitude',
    TYPE: 'kind',
    STATUS: 'status',
    EMPLOYEE: 'employee',
    RESPONSIBLE_EMPLOYEE: 'responsible_employee'
};
