import { RELATIONSHIPS } from '@/helpers/jsonapiHelpers';

class IncidentType {
    constructor (params) {
        this.id = params?.id !== undefined ? params?.id : null;
        this.name = params?.name || '';
        this.description = params?.description || '';
        this.code = params?.code || '';
        this.color = params?.color || '';
        this.anonymous = params?.anonymous || false;
        this.lead_time = params?.lead_time || null;
        this.notify = params?.notify || 0;
        // generated from relation links
        this.notifications_mail = (params?.notifications_mail !== undefined) ? params?.notifications_mail : [];
        this.notifications_phone = (params?.notifications_phone !== undefined) ? params?.notifications_phone : [];
        this.templateId = (params?.templateId !== undefined) ? params?.templateId : null;
        this.company_typeId = (params?.company_typeId !== undefined) ? params?.company_typeId : null;
        this.incident_statuses = (params?.incident_statuses !== undefined) ? params?.incident_statuses : [];
        // update actions
        this.previousNotifications_mail = params?.previousNotifications_mail || [];
        this.previousNotifications_phone = params?.previousNotifications_phone || [];
        this.previousTemplateId = (params?.templateId !== undefined) ? params?.templateId : null;
        this.previousIncident_statuses = (params?.incident_statuses !== undefined) ? params?.incident_statuses : [];
        this.previousCompanyId = params?.previousCompanyId || null;
    }

    getCreateData () {
        const result = {
            data: {
                type: 'incident_kinds',
                attributes: {
                    name: this.name,
                    color: this.color,
                    code: this.code,
                    description: this.description,
                    anonymous: this.anonymous || false,
                    lead_time: this.lead_time,
                    notify: this.notify
                },
                relationships: {}
            }
        };

        if (this.incident_statuses) {
            const n = this.incident_statuses.map(el => parseInt(el.value)).sort((a, b) => a - b);
            const o = this.previousIncident_statuses.map(el => parseInt(el.value)).sort((a, b) => a - b);
            const defaultIem = this.incident_statuses.filter(el => el.isDefault === 1);
            const newStatuses = this.incident_statuses.filter(el => o.indexOf(parseInt(el.value)) === -1).concat(defaultIem);
            const oldStatuses = this.previousIncident_statuses.filter(el => n.indexOf(parseInt(el.value)) === -1);
            result.data.relationships.incident_statuses = {
                data: RELATIONSHIPS.CREATE.STATUSES(newStatuses).concat(RELATIONSHIPS.DISASSOCIATE.STATUSES(oldStatuses))
            };
        }
        if (this.templateId !== this.previousTemplateId) {
            result.data.relationships.template = {
                data: RELATIONSHIPS.CREATE.TEMPLATE(parseInt(this.templateId))
            };
        }
        if (this.notifications_phone.length === 0) {
            const phonesToDelete = this.previousNotifications_phone.filter(el => this.notifications_phone.indexOf(el) === -1);
            result.data.relationships.employee_phones = {
                data: RELATIONSHIPS.DISASSOCIATE.EMPLOYEE_PHONES(phonesToDelete)
            };
        } else {
            const phonesToDelete = this.previousNotifications_phone.filter(el => this.notifications_phone.indexOf(el) === -1);
            const phonesToCreate = this.notifications_phone.filter(el => this.previousNotifications_phone.indexOf(el) === -1);
            result.data.relationships.employee_phones = {
                data: RELATIONSHIPS.CREATE.EMPLOYEE_PHONES(phonesToCreate).concat(RELATIONSHIPS.DISASSOCIATE.EMPLOYEE_PHONES(phonesToDelete))
            };
        }
        if (this.notifications_mail.length === 0) {
            const toDelete = this.previousNotifications_mail.filter(e => this.notifications_mail.indexOf(e) === -1);
            result.data.relationships.employee_mails = {
                data: RELATIONSHIPS.DISASSOCIATE.EMPLOYEE_MAILS(toDelete)
            };
        } else {
            const toCreate = this.notifications_mail.filter(e => this.previousNotifications_mail.indexOf(e) === -1);
            const toDelete = this.previousNotifications_mail.filter(e => this.notifications_mail.indexOf(e) === -1);
            result.data.relationships.employee_mails = {
                data: RELATIONSHIPS.CREATE.EMPLOYEE_MAILS(toCreate).concat(RELATIONSHIPS.DISASSOCIATE.EMPLOYEE_MAILS(toDelete))
            };
        }
        if (this.company_typeId !== this.previousCompanyId) {
            result.data.relationships.company_type = {
                data: RELATIONSHIPS.CREATE.COMPANY_TYPE(parseInt(this.company_typeId))
            };
        }
        return result;
    }

    getUpdateData (id) {
        const payload = this.getCreateData();
        payload.data.id = id;

        return payload;
    }

    updateAsossiations ({ n, o, disassociate } = {}) {
        const createIds = (data) => data.map(el => parseInt(el.value)).sort((a, b) => a - b);
        const news = createIds(n);
        const old = createIds(o);
        const newList = n.filter(el => old.indexOf(parseInt(el.value)) === -1);
        const oldList = o.filter(el => news.indexOf(parseInt(el.value)) === -1);
        return disassociate ? newList : oldList;
    }
}

export default IncidentType;
