class SitesGroup {
    constructor (params) {
        this.id = params?.id || null;
        this.name = params?.name || '';
        this.code = params?.code || '';
        this.locations = params?.locations || [];
        this.subcontractors = params?.subcontractors || [];
        this.prepareSubcontractorsGroups();
    }

    prepareSubcontractorsGroups () {
        this.subcontractorsGroups = {};
        for (const item of this.subcontractors) {
            this.subcontractorsGroups[item.type.id] = item.id;

            // for multiple values
            // if (!this.subcontractorsGroups[item.type.id]) {
            //     this.subcontractorsGroups[item.type.id] = item.id;
            // }
            // this.subcontractorsGroups[item.type.id].push();
        }
    }

    #getBaseJSONStructure () {
        const subcontractorsIds = [];
        for (const group in this.subcontractorsGroups) {
            if (this.subcontractorsGroups[group]) {
                subcontractorsIds.push(this.subcontractorsGroups[group]);
            }
            // for multiple values
            // subcontractorsIds = subcontractorsIds.concat(this.subcontractorsGroups[group]);
        }

        const data = {
            name: this.name,
            code: this.code,
            locations: this.locations.map(item => parseInt(item, 10)),
            subcontractors: subcontractorsIds
        };
        return data;
    }

    getCreateData () {
        return this.#getBaseJSONStructure();
    }

    getUpdateData () {
        const data = this.#getBaseJSONStructure();
        data.id = this.id;

        return data;
    }

    getFilterableData () {
        return {
            value: this.id,
            text: this.name
        };
    }

    parseData (params) {
        this.id = params.id || null;
        this.name = params.name || '';
        this.code = params.code || '';
        this.locations = params.locations.map(item => '' + item.id) || [];
        this.subcontractors = params.subcontractors || [];
        this.prepareSubcontractorsGroups();
    }
}

export default SitesGroup;
