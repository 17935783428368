import HttpService from '@/servises/HttpService';
import { getEndpoint } from '@/utils/endpoints';

export default {
    getCompanyTypes: (params) => {
        return HttpService.get(getEndpoint('company_types'), {
            params: params
        });
    }
};
