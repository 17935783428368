import { RELATIONSHIPS, INCLUDES } from '@/helpers/jsonapiHelpers';
import { uuid } from 'uuidv4';

class Customer {
    constructor (params) {
        this.name = params?.name || '';
        this.streetAndNumber = params?.streetAndNumber || '';
        this.cityId = params?.cityId || '';
        this.internalIdentifier = (params?.internalIdentifier !== undefined) ? params?.internalIdentifier : '';
        this.externalIdentifier = (params?.externalIdentifier !== undefined) ? params?.externalIdentifier : '';
        this.identification_1 = params?.identification_1 || '';
        this.identification_2 = params?.identification_2 || '';
        this.reportEmail = params?.reportEmail || '';
        this.identification_3 = params?.identification_3 || '';
        this.identification_4 = params?.identification_4 || '';
        this.latitude = (params?.latitude !== undefined) ? params?.latitude : null;
        this.longitude = (params?.longitude !== undefined) ? params?.longitude : null;
        this.zipCode = params?.zipCode || '';
    }

    getCustomerAttributes () {
        const payload = {
            data: {
                type: 'customers',
                attributes: {
                    street_and_number: this.streetAndNumber,
                    name: this.name,
                    latitude: this.latitude,
                    longitude: this.longitude,
                    report_email: this.reportEmail,
                    identification_1: this.identification_1,
                    identification_2: this.identification_2,
                    identification_3: this.identification_3,
                    identification_4: this.identification_4,
                    external_identifier: this.externalIdentifier,
                    internal_identifier: this.internalIdentifier
                },
                relationships: {}
            },
            included: []
        };

        if (this.cityId) {
            payload.data.relationships.city = {
                data: RELATIONSHIPS.CREATE.CITY(this.cityId)
            };
        }

        return payload;
    }

    build () {
        return this.getCustomerAttributes();
    }

    getDataForCreate (pointCreationAvailable) {
        const _payload = this.getCustomerAttributes();

        const siteTempId = uuid();
        const locationTempId = uuid();

        _payload.data.relationships.places = {
            data: [RELATIONSHIPS.CREATE.ADD_SITE(siteTempId)]
        };

        const _attributes = {
            name: _payload.data.attributes.name,
            street_and_number: _payload.data.attributes.street_and_number,
            external_identifier: _payload.data.attributes.external_identifier,
            internal_identifier: _payload.data.attributes.internal_identifier
        };
        const _cityId = _payload.data.relationships.city.data.id;
        const _locationAttributes = {
            name: _payload.data.attributes.name,
            latitude: this.latitude,
            longitude: this.longitude
        };

        const _relationship = {
            city: {
                data: RELATIONSHIPS.CREATE.CITY(_cityId)
            }
        };

        if (pointCreationAvailable) {
            _relationship.points = {
                data: RELATIONSHIPS.CREATE.ADD_LOCATION(locationTempId)
            };

            _payload.included.push(INCLUDES.ADD_LOCATION(locationTempId, _locationAttributes));
        }

        _payload.included.push(INCLUDES.ADD_SITE(siteTempId, _attributes, _relationship));

        return _payload;
    }

    getDataForCreateWithNested (pointCreationAvailable, nestedSites) {
        const _payload = this.getCustomerAttributes();

        _payload.data.relationships.places = {
            data: []
        };

        nestedSites.forEach(el => {
            const siteTempId = uuid();
            const site = el.getSiteAttributes();

            _payload.data.relationships.places.data.push(RELATIONSHIPS.CREATE.ADD_SITE(siteTempId));

            const siteObject = INCLUDES.ADD_SITE(siteTempId, site.attributes, {
                city: {
                    data: RELATIONSHIPS.CREATE.CITY(site.relationships.city_id)
                }
            });

            if (site.relationships.place_groups > 0) {
                siteObject.relationships.place_groups = {
                    data: RELATIONSHIPS.CREATE.SITE_GROUPS(site.relationships.place_groups)
                };
            }

            if (site.relationships.nestedLocations.length > 0 && pointCreationAvailable) {
                const {
                    locationRelationships,
                    locationIncludes
                } = el.parseNestedLocations(site.relationships.nestedLocations, siteTempId);

                siteObject.relationships.points = locationRelationships;
                _payload.included = [..._payload.included, ...locationIncludes];
            }

            _payload.included.push(siteObject);
        });

        return _payload;
    }

    getDataForUpdate (customerId) {
        const _payload = this.getCustomerAttributes();
        _payload.data.id = customerId;

        return _payload;
    }

    getDataForUpdateWithNested (customerId, pointCreationAvailable, nestedSites) {
        const _payload = this.getDataForCreateWithNested(pointCreationAvailable, nestedSites);
        _payload.data.id = customerId;

        return _payload;
    }

    parseData (payload) {
        this.name = payload.name || '';
        this.streetAndNumber = payload.street_and_number || '';
        this.cityId = payload.city_id || '';
        this.internalIdentifier = (payload.internal_identifier !== undefined) ? payload.internal_identifier : '';
        this.externalIdentifier = (payload.external_identifier !== undefined) ? payload.external_identifier : '';
        this.identification_1 = payload.identification_1 || '';
        this.identification_2 = payload.identification_2 || '';
        this.reportEmail = payload.report_email || '';
        this.identification_3 = payload.identification_3 || '';
        this.identification_4 = payload.identification_4 || '';
        this.latitude = (payload.latitude !== undefined) ? payload.latitude : '';
        this.longitude = (payload.longitude !== undefined) ? payload.longitude : '';
        this.zipCode = payload.zip_code || '';
    }
}

export default Customer;
