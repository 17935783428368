
import { getV2Endpoint } from '@/utils/apiV2Endpoints';
import KmsHttpService from '@/servises/kmsHttpService';

export default {
    getTracks: (params) => {
        const endpoint = getV2Endpoint({ 'group': 'tracks', 'endpoint': 'list' }, null);
        return KmsHttpService.get(endpoint, params);
    },
    createTrack: (params) => {
        const endpoint = getV2Endpoint({ 'group': 'tracks', 'endpoint': 'list' }, null);
        return KmsHttpService.post(endpoint, params);
    },
    getTrack: (id, params) => {
        const endpoint = getV2Endpoint({ 'group': 'tracks', 'endpoint': 'manage' }, { id: id });
        return KmsHttpService.get(endpoint, params);
    },
    updateTrack: (id, params) => {
        const endpoint = getV2Endpoint({ 'group': 'tracks', 'endpoint': 'manage' }, { id: id });
        return KmsHttpService.put(endpoint, params);
    },
    deleteTrack: (id) => {
        const endpoint = getV2Endpoint({ 'group': 'tracks', 'endpoint': 'manage' }, { id: id });
        return KmsHttpService.delete(endpoint, null);
    },
    deleteManyTracks: (params) => {
        const endpoint = getV2Endpoint({ 'group': 'tracks', 'endpoint': 'bulk_delete' }, null);
        return KmsHttpService.delete(endpoint, params);
    }
};
