export default {
    path: '/configurations',
    component: () => import(/* webpackChunkName: "Index" */ '@/views/index'),
    redirect: { name: 'areaConfigurations' },
    children: [
        {
            path: 'areas',
            name: 'areaConfigurations',
            component: () => import(/* webpackChunkName: "areaConfigurations" */ '@/views/configurations/AreaConfigurations')
        },
        {
            path: 'templates',
            name: 'templatesConfigurations',
            component: () => import(/* webpackChunkName: "templatesConfigurations" */ '@/views/configurations/TemplatesConfigurations')
        },
        {
            path: 'incidents-statuses',
            name: 'incidentsStatusesConfigurations',
            component: () => import(/* webpackChunkName: "incidentsStatusesConfigurations" */ '@/views/configurations/IncidentsStatusesConfigurations')
        },
        {
            path: 'subcontractors-types',
            name: 'subcontractorsTypes',
            component: () => import(/* webpackChunkName: "subContractorsTypes" */ '@/views/configurations/SubContractorsTypes')
        },
        {
            path: 'positions',
            name: 'positions',
            component: () => import(/* webpackChunkName: "incidentsStatusesConfigurations" */ '@/views/configurations/Positions')
        },
        {
            path: 'employees-groups',
            name: 'employeesGroupsConfigurations',
            component: () => import(/* webpackChunkName: "employeesGroupsConfigurations" */ '@/views/configurations/EmployeesGroupsConfigurations')
        },
        {
            path: 'sites-groups',
            name: 'sitesGroupsConfigurations',
            component: () => import(/* webpackChunkName: "sitesGroupsConfigurations" */ '@/views/configurations/SitesGroupsConfigurations')
        },
        {
            path: 'imports',
            name: 'imports',
            component: () => import(/* webpackChunkName: "imports" */ '@/views/configurations/Imports')
        },
        {
            path: 'incidentsTypes',
            name: 'incidentsTypes',
            component: () => import(/* webpackChunkName: "incidentsTypes" */ '@/views/configurations/IncidentsTypes')
        },
        {
            path: 'group-templates-assignment',
            name: 'groupTemplatesAssignment',
            component: () => import(/* webpackChunkName: "groupTemplatesAssignment" */ '@/views/configurations/GroupTemplatesAssignment')
        }
    ]
};
