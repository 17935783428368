import schedulingApi from '@/utils/api-v2/scheduling';
import PlannedEvent from '@/models/scheduling/plannedEvent';
import { getField, updateField } from 'vuex-map-fields';
import { PARSE_TIME_ONLY_FROM_ISO_DATESTRING } from '@/helpers/dates';

export default {
    namespaced: true,
    state: () => ({
        events: [],
        totalEventsAmount: null,
        manageEventFormVisibility: false,
        eventDetailsVisibility: false,
        editedEventId: null,
        activeEvent: null,
        eventData: new PlannedEvent()
    }),
    getters: { getField },
    mutations: {
        updateField,
        SET_EVENTS (state, payload) {
            state.events = payload;
        },
        SET_TOTAL_AMOUNT (state, payload) {
            state.totalEventsAmount = payload;
        },
        SET_EDIT_EVENT_ID (state, payload) {
            state.editedEventId = payload;
        },
        SET_EVENT_DETAILS_VISIBILITY (state, payload) {
            state.eventDetailsVisibility = payload;
        },
        SET_MANAGE_EVENT_FORM_VISIBILITY (state, payload) {
            state.manageEventFormVisibility = payload;
        },
        RESET_EVENT_DATA (state) {
            state.eventData = new PlannedEvent();
        },
        SET_EVENT_DATA (state, payload) {
            state.eventData.parseData(payload);
        },
        SET_ACTIVE_EVENT (state, payload) {
            state.activeEvent = payload;
        }
    },
    actions: {
        getEvents ({ commit }, additionalParams = null) {
            commit('SET_EVENTS', []);
            commit('SET_TOTAL_AMOUNT', 0);

            return schedulingApi.getEvents(additionalParams)
                .then(({ data }) => {
                    const result = data.results.map(el => {
                        const activeEventData = new PlannedEvent(el);
                        activeEventData.id = el.id;
                        activeEventData.time = PARSE_TIME_ONLY_FROM_ISO_DATESTRING(el.time);
                        return activeEventData;
                    });
                    commit('SET_EVENTS', result);

                    if (data.count) {
                        commit('SET_TOTAL_AMOUNT', data.count);
                    }
                });
        },
        getActiveEvent ({ commit }, id) {
            return schedulingApi.getActiveEvent(id)
                .then(({ data }) => {
                    data.time = PARSE_TIME_ONLY_FROM_ISO_DATESTRING(data.time);
                    const activeEventData = new PlannedEvent(data);

                    commit('SET_EVENT_DATA', activeEventData);
                    commit('SET_ACTIVE_EVENT', activeEventData);
                });
        },
        createEvent ({ state }) {
            const data = state.eventData.getCreateData();

            return schedulingApi.createEvent(data);
        },
        updateEvent ({ state }) {
            const data = state.eventData.getUpdateData();

            return schedulingApi.updateEvent(state.activeEvent.id, data);
        },
        deleteEvent (context, id) {
            return schedulingApi.deleteEvent(id);
        },
        clearActiveEventData ({ commit }) {
            commit('SET_EDIT_EVENT_ID', null);
            commit('SET_ACTIVE_EVENT', null);
            commit('RESET_EVENT_DATA');
        }
    }
};
