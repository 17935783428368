import HttpService from '@/servises/HttpService';
import { getEndpoint } from '@/utils/endpoints';

export default {
    getCustomers: (params) => {
        return HttpService.get(getEndpoint('customers'), {
            params: params
        });
    },
    getActiveCustomer: (id, locale) => {
        return HttpService.get(getEndpoint('manage_customer', { id: id }), {
            params: {
                'extra_fields[customers]': 'identification_with_label,coordinates,employees_with_access,clients_with_access',
                'include': 'city,places',
                locale: locale
            }
        });
    },
    createCustomer: (payload) => {
        return HttpService.post(getEndpoint('customers'), payload);
    },
    updateCustomer: (id, payload) => {
        return HttpService.patch(getEndpoint('manage_customer', { id: id }), payload);
    },
    importCustomers: (data) => {
        return HttpService.post(getEndpoint('import_customers'), data);
    }
};
