import { getField, updateField } from 'vuex-map-fields';
import positionsApi from '@/utils/api/positions';
import Position from '@/models/positions/position';

export default {
    namespaced: true,
    state: () => ({
        positionFormVisibility: false,
        editedPositionId: null,
        permissions: [],
        positionData: new Position()
    }),
    getters: {
        getField
    },
    mutations: {
        updateField,
        SET_POSITION_ID (state, payload) {
            state.editedPositionId = payload;
        },
        SET_PERMISSIONS (state, payload) {
            state.permissions = payload.data.map(e => {
                return {
                    value: e.id,
                    group: e.attributes.group,
                    text: e.attributes.short_name
                };
            });
        },
        SET_POSITION_FORM_VISIBILITY (state, payload) {
            state.positionFormVisibility = payload;
        },
        RESET_POSITION_DATA (state) {
            state.positionData = new Position();
        },
        SET_ACTIVE_POSITION (state, payload) {
            const currentPermissions = {};
            if (payload.included) {
                payload.included.filter(i => i.type === 'permissions').forEach(e => {
                    currentPermissions[`${e.attributes.short_name}`] = true;
                });
            }
            state.positionData = {
                id: payload.data.id,
                ...payload.data.attributes,
                'position_permissions': currentPermissions
            };
        }
    },
    actions: {
        getActivePosition ({ commit }, id) {
            return positionsApi.getActivePosition(id)
                .then(({ data }) => {
                    commit('SET_ACTIVE_POSITION', data);
                });
        },
        getPermissions ({ commit }, params) {
            return positionsApi.getPermissions(params)
                .then(({ data }) => {
                    commit('SET_PERMISSIONS', data);
                });
        },
        createPosition ({ state, commit }) {
            const payload = state.positionData.getCreateData(state.permissions);
            return positionsApi.createPosition(payload)
                .then(() => {
                    commit('RESET_POSITION_DATA');
                });
        },
        updatePosition ({ state, commit }) {
            const payload = new Position(state.positionData).getCreateData(state.permissions);
            return positionsApi.updatePosition(state.editedPositionId, payload)
                .then(() => {
                    commit('RESET_POSITION_DATA');
                });
        },
        deletePosition (context, id) {
            return positionsApi.deletePosition(id);
        }
    }
};
