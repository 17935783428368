import { RELATIONSHIPS } from '@/helpers/jsonapiHelpers';

class EmployeesGroup {
    constructor (params) {
        this.id = params?.id || null;
        this.name = params?.name || '';
        this.employeesIds = params?.employeesIds || [];
    }

    #getBaseJSONStructure () {
        const data = {
            data: {
                type: 'employee_groups',
                attributes: {
                    name: this.name
                }
            }
        };

        if (this.employeesIds.length > 0) {
            data.data.relationships = {
                employees: {
                    data: RELATIONSHIPS.CREATE.EMPLOYEE(this.employeesIds)
                }
            };
        }

        return data;
    }

    getCreateData () {
        return this.#getBaseJSONStructure();
    }

    getUpdateData () {
        const data = this.#getBaseJSONStructure();
        data.data.id = this.id;

        return data;
    }

    parseData (params) {
        this.id = params.id || null;
        this.name = params.name || '';
        this.employeesIds = params.employeesIds || [];
    }

    getFilterableData () {
        return {
            value: this.id,
            text: this.name
        };
    }
}

export default EmployeesGroup;
