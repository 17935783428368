class Holiday {
    constructor (params) {
        this.id = params?.id || null;
        this.name = params?.name || '';
        this.date = params?.date || '';
    }

    #baseJSONStructure () {
        return {
            data: {
                type: 'holiday_calendars',
                attributes: {
                    name: this.name,
                    date: this.date
                }
            }
        };
    }

    getCreateData () {
        return this.#baseJSONStructure();
    }

    getUpdateData () {
        const data = this.#baseJSONStructure();
        data.data.id = this.id;

        return data;
    }

    parseData (params) {
        this.id = params.id || null;
        this.name = params.name || '';
        this.date = params.date || '';
    }
}

export default Holiday;
