export default {
    path: '/event',
    component: () => import('@/views/index'),
    redirect: { name: 'inspectionsSummary' },
    children: [
        {
            path: 'incidents/:id',
            name: 'incidentDetails',
            redirect: { name: 'incidents' },
            component: () => import('@/views/incidents/Incidents')
        },
        {
            path: 'inspections/:id',
            name: 'inspectionDetails',
            redirect: { name: 'inspectionsSummary' },
            component: () => import('@/views/inspections/InspectionsSummary')
        }
    ]
};
