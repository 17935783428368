import { INCLUDES, RELATIONSHIPS } from '@/helpers/jsonapiHelpers';
import { uuid } from 'uuidv4';
import HttpService from '@/servises/HttpService';
import { getEndpoint } from '@/utils/endpoints';

class Location {
    constructor (params) {
        this.latitude = (params?.latitude !== undefined) ? params?.latitude : null;
        this.longitude = (params?.longitude !== undefined) ? params?.longitude : null;
        this.name = params?.name || '';
        this.nfccode = params?.nfccode || '';
        this.beaconIdentifier = params?.beaconIdentifier || '';
        this.code = params?.code || '';
        this.description = params?.description || '';
        this.templatesForAccess = params?.templatesForAccess || [];
        this.incidentTypesForAccess = params?.incidentTypesForAccess || [];
        this.locationInspectors = params?.locationInspectors || [];
        this.previousTemplatesIds = params?.previousTemplatesIds || [];
        this.previousIncidentTypesIds = params?.previousIncidentTypesIds || [];
        this.previousInspectorsIds = params?.previousInspectorsIds || [];
    }

    getLocationAttributes () {
        return {
            name: this.name,
            nfccode: this.nfccode,
            beacon_identifier: this.beaconIdentifier,
            description: this.description,
            latitude: this.latitude,
            longitude: this.longitude,
            code: this.code
        };
    }

    getCreateRelationshipsAndIncludes (siteId, temp = false) {
        const result = {
            relationships: {},
            included: []
        };

        if (!temp) {
            result.relationships.place = {
                data: RELATIONSHIPS.CREATE.SITE(siteId)
            };
        }

        if (this.locationInspectors && this.locationInspectors.length > 0) {
            const tempIds = [];
            this.locationInspectors.forEach(el => {
                tempIds.push({
                    tempId: uuid(),
                    employeeId: el,
                    siteId: siteId
                });
            });

            result.relationships.point_inspectors = {
                data: RELATIONSHIPS.CREATE.LOCATION_INSPECTORS(tempIds)
            };

            tempIds.forEach(data => {
                result.included.push(INCLUDES.ADD_LOCATION_INSPECTOR(data, temp));
            });
        }

        if (this.templatesForAccess && this.templatesForAccess.length > 0) {
            const tempIds = [];
            this.templatesForAccess.forEach(el => {
                tempIds.push({
                    tempId: uuid(),
                    templateId: el
                });
            });

            result.relationships.point_templates = {
                data: RELATIONSHIPS.CREATE.LOCATION_TEMPLATES(tempIds)
            };

            tempIds.forEach(data => {
                result.included.push(INCLUDES.ADD_LOCATION_TEMPLATE(data));
            });
        }

        if (this.incidentTypesForAccess && this.incidentTypesForAccess.length > 0) {
            const tempIds = [];
            this.incidentTypesForAccess.forEach(el => {
                tempIds.push({
                    tempId: uuid(),
                    typeId: el,
                    siteId: siteId
                });
            });

            result.relationships.point_incident_kinds = {
                data: RELATIONSHIPS.CREATE.LOCATION_INCIDENT_TYPES(tempIds)
            };

            tempIds.forEach(data => {
                result.included.push(INCLUDES.ADD_LOCATION_INCIDENT_TYPE(data, temp));
            });
        }

        return result;
    }

    async getUpdateRelationshipsAndIncludes (siteId, locationId) {
        let x = null;
        await HttpService.get(getEndpoint('manage_location', { id: locationId })).then((res) => {
            const comparePointIncidentKinds = res.data.data.attributes.point_incident_kinds; // previousIncidentTypesIds el.point_incident_kind_id
            const comparePointInspectors = res.data.data.attributes.point_inspectors.filter(e => e.inspector_id !== null); // previousInspectorsIds el.point_inspector_id
            const comparePointTemplates = res.data.data.attributes.point_templates; // previousTemplatesIds el.point_template_id
            const incidentKindsToDelete = comparePointIncidentKinds
                .filter(e => this.incidentTypesForAccess.indexOf(e.incident_kind_id.toString()) === -1)
                .map(x => x.point_incident_kind_id.toString());
            const incidentKindsToAdd = this.incidentTypesForAccess
                .filter(el => comparePointIncidentKinds.map(x => x.incident_kind_id.toString()).indexOf(el) === -1);
            const pointIspectorsToDelete = comparePointInspectors
                .filter(e => this.locationInspectors.indexOf(e.inspector_id.toString()) === -1)
                .map(x => x.point_inspector_id.toString());
            const pointInspectorsToAdd = this.locationInspectors
                .filter(el => comparePointInspectors.map(x => x.inspector_id.toString()).indexOf(el) === -1);
            const pointTemplatesToDelete = comparePointTemplates
                .filter(e => this.templatesForAccess.indexOf(e.template_id.toString()) === -1)
                .map(x => x.point_template_id.toString());
            const pointTemplatesToAdd = this.templatesForAccess
                .filter(el => comparePointTemplates.map(x => x.template_id.toString()).indexOf(el) === -1);
            const result = {
                relationships: {
                    place: {
                        data: RELATIONSHIPS.CREATE.SITE(siteId)
                    }
                },
                included: []
            };

            if (this.templatesForAccess.length > 0 || this.previousTemplatesIds.length > 0) {
                result.relationships.point_templates = {
                    data: []
                };

                pointTemplatesToDelete.forEach(el => {
                    result.relationships.point_templates.data.push(RELATIONSHIPS.DESTROY.LOCATION_TEMPLATE(el));
                });

                const tempIds = [];

                pointTemplatesToAdd.forEach(el => {
                    tempIds.push({
                        tempId: uuid(),
                        templateId: el
                    });
                });

                result.relationships.point_templates.data = [
                    ...result.relationships.point_templates.data,
                    ...RELATIONSHIPS.CREATE.LOCATION_TEMPLATES(tempIds)
                ];

                tempIds.forEach(data => {
                    result.included.push(INCLUDES.ADD_LOCATION_TEMPLATE(data));
                });
            }

            if (this.incidentTypesForAccess.length > 0 || this.previousIncidentTypesIds.length > 0) {
                result.relationships.point_incident_kinds = {
                    data: []
                };

                incidentKindsToDelete.forEach(el => {
                    result.relationships.point_incident_kinds.data.push(RELATIONSHIPS.DESTROY.LOCATION_INCIDENT_TYPE(el));
                });

                const tempIds = [];

                incidentKindsToAdd.forEach(el => {
                    tempIds.push({
                        tempId: uuid(),
                        typeId: el,
                        siteId: siteId
                    });
                });

                result.relationships.point_incident_kinds.data = [
                    ...result.relationships.point_incident_kinds.data,
                    ...RELATIONSHIPS.CREATE.LOCATION_INCIDENT_TYPES(tempIds)
                ];

                tempIds.forEach(data => {
                    result.included.push(INCLUDES.ADD_LOCATION_INCIDENT_TYPE(data));
                });
            }

            if (this.locationInspectors.length > 0 || this.previousInspectorsIds.length > 0) {
                result.relationships.point_inspectors = {
                    data: []
                };

                pointIspectorsToDelete.forEach(el => {
                    result.relationships.point_inspectors.data.push(RELATIONSHIPS.DESTROY.LOCATION_INSPECTOR(el));
                });

                const tempIds = [];

                pointInspectorsToAdd.forEach(el => {
                    tempIds.push({
                        tempId: uuid(),
                        employeeId: el,
                        siteId: siteId
                    });
                });

                result.relationships.point_inspectors.data = [
                    ...result.relationships.point_inspectors.data,
                    ...RELATIONSHIPS.CREATE.LOCATION_INSPECTORS(tempIds)
                ];

                tempIds.forEach(data => {
                    result.included.push(INCLUDES.ADD_LOCATION_INSPECTOR(data));
                });
            }
            if (res) x = result;
        });
        return x;
    }

    #getData () {
        return {
            data: {
                type: 'points',
                attributes: this.getLocationAttributes(),
                relationships: {}
            }
        };
    }

    getCreateData (siteId) {
        const location = this.#getData();
        const { relationships, included } = this.getCreateRelationshipsAndIncludes(siteId);
        location.included = included;

        location.data.relationships = relationships;

        return location;
    }

    async getUpdateData (siteId, locationId) {
        const data = await this.getUpdateRelationshipsAndIncludes(siteId, locationId);
        const location = this.#getData();
        location.data.id = locationId;
        const { relationships, included } = data;
        location.included = included;
        location.data.relationships = relationships;
        return location;
    }

    parseData (params) {
        this.latitude = (params?.latitude !== undefined) ? params?.latitude : null;
        this.longitude = (params?.longitude !== undefined) ? params?.longitude : null;
        this.name = params.name || '';
        this.nfccode = params.nfccode || '';
        this.beaconIdentifier = params.beacon_identifier || '';
        this.code = params.code || '';
        this.description = params.description || '';
        this.incidentTypesForAccess = params.incidentTypesForAccess || [];
        this.previousIncidentTypesIds = params.previousIncidentTypesIds || [];
        this.locationInspectors = params.locationInspectors || [];
        this.previousInspectorsIds = params.previousInspectorsIds || [];
        this.templatesForAccess = params.templatesForAccess || [];
        this.previousTemplatesIds = params.previousTemplatesIds || [];
    }
}

export default Location;
