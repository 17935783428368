import osmApi from '@/utils/api-v2/osm';
import { getField, updateField } from 'vuex-map-fields';
import Device from '@/models/devices/device';

export default {
    namespaced: true,
    state: () => ({
        device: new Device()
    }),
    getters: {
        getField,
        deviceTypes: () => [
            { value: 'ag', text: 'Active Guard' },
            { value: 'at', text: 'Active Track' }
        ]
    },
    mutations: {
        updateField,
        RESET_DEVICE_DATA (state) {
            state.device = new Device();
        },
        SET_DEVICE_DATA (state, payload) {
            state.device.parseData(payload);
        }
    },
    actions: {
        createDevice ({ state }, employeeId) {
            const data = {
                serial_number: state.device.deviceSerialNumber,
                employee: employeeId,
                device_type: state.device.deviceType
            };
            return osmApi.createDevice(data);
        },
        updateDevice ({ state, rootState }, deviceId) {
            const data = {
                serial_number: state.device.deviceSerialNumber,
                employee: rootState.manageEmployee.editEmployeeId,
                device_type: state.device.deviceType
            };
            return osmApi.updateDevice(data, deviceId);
        },
        deleteDevice ({ commit }, id) {
            commit('RESET_DEVICE_DATA');
            return osmApi.deleteDevice(id);
        }
    }
};
