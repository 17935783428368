import { getV2Endpoint } from '@/utils/apiV2Endpoints';
import KmsHttpService from '@/servises/kmsHttpService';

export default {
    signIn: (data) => {
        return KmsHttpService.post(getV2Endpoint('sign_in'), data);
    },
    signOut: (data) => {
        return KmsHttpService.post(getV2Endpoint('sign_out'), data);
    },
    refreshToken: (data) => {
        return KmsHttpService.post(getV2Endpoint('refresh_token'), data);
    }
};
