export default {
    namespaced: true,
    state: {
        config: [],
        filters: {},
        filtersSearching: {},
        modalConfig: [],
        modalFilters: {},
        modalFiltersSearching: {}
    },
    mutations: {
        SET_CONFIG (state, payload) {
            state.config = payload;
        },
        SET_MODAL_CONFIG (state, payload) {
            state.modalConfig = payload;
        },
        SET_FILTERS (state, payload) {
            state.filters = payload;
        },
        SET_MODAL_FILTERS (state, payload) {
            state.modalFilters = payload;
        },
        SET_FILTERS_SEARCHING (state, payload) {
            state.filtersSearching = payload;
        },
        SET_MODAL_FILTERS_SEARCHING (state, payload) {
            state.modalFiltersSearching = payload;
        }
    },
    actions: {
        clearFiltersParams ({ commit }) {
            commit('SET_CONFIG', []);
            commit('SET_FILTERS', {});
            commit('SET_FILTERS_SEARCHING', {});
        },
        clearModalFiltersParams ({ commit }) {
            commit('SET_MODAL_CONFIG', []);
            commit('SET_MODAL_FILTERS', {});
            commit('SET_MODAL_FILTERS_SEARCHING', {});
        }
    }
};
