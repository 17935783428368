import templatesApi from '@/utils/api/templates';
import Template from '@/models/templates/template';
import TemplateQuestion from '@/models/templates/templateQuestion';
import { getField, updateField } from 'vuex-map-fields';
import { downloadBase64Document } from '@/helpers/files';

export default {
    namespaced: true,
    state: () => ({
        activeTemplate: null,
        templateData: new Template(),
        templateFormVisibility: false,
        detailsVisibility: false,
        editedTemplateId: null,
        cloneTemplate: false
    }),
    getters: {
        getField,
        getAddedQuestions: state => {
            return state.templateData.questions;
        }

    },
    mutations: {
        updateField,
        SET_ACTIVE_TEMPLATE (state, payload) {
            state.activeTemplate = payload;
        },
        SET_TEMPLATE_FORM_VISIBILITY (state, payload) {
            state.templateFormVisibility = payload;
        },
        SET_CLONE_TEMPLATE (state, payload) {
            state.cloneTemplate = payload;
        },
        SET_DETAILS_VISIBILITY (state, payload) {
            state.detailsVisibility = payload;
        },
        SET_EDITED_TEMPLATE_ID (state, payload) {
            state.editedTemplateId = payload;
        },
        RESET_TEMPLATE_DATA (state) {
            state.templateData = new Template();
        },
        SET_TEMPLATE_DATA (state, payload) {
            state.templateData.parseData(payload);
        },
        CLONE_TEMPLATE_DATA (state, payload) {
            state.templateData.parseData(payload);
        },
        SET_QUESTION (state, payload) {
            if (payload.includeInScanField) {
                for (const q of state.templateData.questions) {
                    q.includeInScanField = false;
                }
            }
            if (payload.random) {
                payload.sequence = -1;
            }
            state.templateData.questions.push(payload);
        },
        EDIT_QUESTION (state, payload) {
            if (payload.includeInScanField) {
                for (const q of state.templateData.questions) {
                    q.includeInScanField = false;
                }
            }
            if (payload.random) {
                payload.sequence = -1;
            }
            const index = state.templateData.questions.findIndex(el => el.id === payload.id);

            state.templateData.questions.splice(index, 1, payload);
        },
        DELETE_QUESTION (state, id) {
            const index = state.templateData.questions.findIndex(el => el.id === id);

            state.templateData.questions.splice(index, 1);
        },
        SET_QUESTIONS_LIST (state, payload) {
            state.templateData.questions = payload;
        },
        REORDER_QUESTIONS (state) {
            state.templateData.reorderQuestions();
        }
    },
    actions: {
        getActiveTemplate ({ commit }, id) {
            return templatesApi.getActiveTemplate(id)
                .then(({ data }) => {
                    const questions = [];

                    if (data.included) {
                        const questionTypes = data.included.filter(el => el.type === 'question_types');
                        const incidentTypes = data.included.filter(el => el.type === 'incident_kinds');
                        const templateQuestionsList = data.included.filter(el => el.type === 'template_questions');

                        templateQuestionsList.forEach(el => {
                            const result = {
                                id: el.id,
                                type: el.attributes.question_type_id.toString(),
                                typeName: questionTypes.find(t => t.id === el.attributes.question_type_id.toString()).attributes.name,
                                question: el.attributes.question,
                                sequence: el.attributes.sequence,
                                parameters: el.attributes.question_parameters ? JSON.parse(el.attributes.question_parameters) : [],
                                condition: el.attributes.minimum_value,
                                smsContent: el.attributes.sms_content,
                                repeated: el.attributes.config,
                                required: el.attributes.required,
                                notApplicable: el.attributes.not_applicable,
                                specialAttention: el.attributes.special_attention,
                                includeInScanField: el.attributes.include_in_scan_field,
                                display_type: el.attributes.display_type
                            };

                            if (Object.hasOwnProperty.call(el.relationships, 'incident_kind')) {
                                result.incident = el.relationships.incident_kind.data?.id;
                                result.incidentName = incidentTypes.find(t => t.id === result.incident)?.attributes.name;
                            }

                            const question = new TemplateQuestion(result);

                            questions.push(question);
                        });
                    }

                    commit('SET_ACTIVE_TEMPLATE', {
                        id: data.data.id,
                        ...data.data.attributes,
                        questions: questions
                    });

                    commit('SET_EDITED_TEMPLATE_ID', data.data.id);
                });
        },
        addQuestion ({ rootState, commit }) {
            commit('SET_QUESTION', rootState.manageTemplateQuestion.questionData);
        },
        editQuestion ({ rootState, commit }) {
            commit('EDIT_QUESTION', rootState.manageTemplateQuestion.questionData);
        },
        deleteQuestion ({ commit }, id) {
            const promise = new Promise((resolve, reject) => {
                commit('DELETE_QUESTION', id);
                resolve();
            });
            return promise
                .then(() => {
                    commit('REORDER_QUESTIONS');
                });
        },
        createTemplate ({ state }, id) {
            const data = state.templateData.getDataForCreate(state.cloneTemplate ? null : id);
            return templatesApi.createTemplate(data);
        },
        updateTemplate ({ state }, id) {
            const data = state.templateData.getDataForUpdate(id);

            return templatesApi.updateTemplate(data);
        },
        deleteTemplate (context, id) {
            return templatesApi.deleteTemplate(id);
        },
        clearActiveTemplateData ({ commit }) {
            commit('SET_EDITED_TEMPLATE_ID', null);
            commit('SET_ACTIVE_TEMPLATE', null);
            commit('RESET_TEMPLATE_DATA');
        },
        exportTemplate ({ state }) {
            return templatesApi.exportTemplate(state.editedTemplateId)
                .then(({ data }) => {
                    downloadBase64Document(data, state.activeTemplate.name, 'application/json');
                });
        },
        importTemplate (context, data) {
            return templatesApi.importTemplate(data);
        },
        manageArchiveTemplate (context, data) {
            return templatesApi.manageArchiveTemplate(data);
        }
    }
};
