class Email {
    constructor (params) {
        this.recipients = params?.recipients || [];
        this.title = params?.title || '';
        this.message = params?.message || '';
        this.agency = params?.agency || '';
        this.kindOfReport = params?.kindOfReport || '';
        this.report = params?.report || {};
    }

    #getBaseJSONStructure () {
        return {
            data: {
                type: 'email',
                recipients: this.recipients,
                attributes: {
                    title: this.title,
                    message: this.message
                }
            }
        };
    }

    getSendMailData () {
        return this.#getBaseJSONStructure();
    }

    getSendReportData () {
        const data = this.#getBaseJSONStructure();

        data.data.agency = this.agency;
        data.data.kind_of_report = this.kindOfReport;
        data.data.report = this.report;

        return data;
    }
}

export default Email;
