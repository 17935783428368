import HttpService from '@/servises/HttpService';
import { getEndpoint } from '@/utils/endpoints';

export default {
    getTemplates: (params) => {
        return HttpService.get(getEndpoint('templates'), {
            params: params
        });
    },
    getQuestions: (params) => {
        return HttpService.get(getEndpoint('template_questions'), params);
    },
    getActiveTemplate: (id) => {
        return HttpService.get(getEndpoint('manage_template', { id: id }), {
            params: {
                include: 'template_questions.question_type,template_questions.incident_kind'
            }
        });
    },
    getSingleTemplate: (id) => {
        return HttpService.get(getEndpoint('manage_template', { id: id }), {
            params: {
                'extra_fields[templates]': 'customers_ids,places_ids,points_ids'
            }
        });
    },
    getQuestionTypes: (params) => {
        return HttpService.get(getEndpoint('question_types'), params);
    },
    createTemplate: (data) => {
        return HttpService.post(getEndpoint('templates'), data);
    },
    updateTemplate: (data) => {
        return HttpService.patch(getEndpoint('manage_template', { id: data.data.id }), data);
    },
    deleteTemplate: (id) => {
        return HttpService.delete(getEndpoint('manage_template', { id: id }));
    },
    createQuestion: (data) => {
        return HttpService.post(getEndpoint('template_questions'), data);
    },
    updateQuestion: (data) => {
        return HttpService.patch(getEndpoint('manage_template_questions', { id: data.data.id }), data);
    },
    deleteQuestion: (id) => {
        return HttpService.delete(getEndpoint('manage_template_questions', { id: id }));
    },
    exportTemplate: (id) => {
        return HttpService.get(getEndpoint('export_template', { id: id }));
    },
    importTemplate: (data) => {
        return HttpService.post(getEndpoint('import_template'), data);
    },
    manageArchiveTemplate: (data) => {
        return HttpService.put(getEndpoint('manage_archive_template', { id: data.id }), data.data);
    },
    assignTemplatesToPoints: (data) => {
        return HttpService.patch(getEndpoint('assign_templates_to_points'), data);
    },
    unassignTemplatesFromPoints: (data) => {
        return HttpService.patch(getEndpoint('unassign_templates_from_points'), data);
    }
};
