export default {
    path: '/',
    name: 'home',
    redirect: { name: 'SuperAdminManageAgency' },
    component: () => import(/* webpackChunkName: "Home" */ '@/views/superadmin/index'),
    children: [
        {
            path: 'agencies',
            name: 'SuperAdminManageAgency',
            component: () => import(/* webpackChunkName: "SuperAdminManageAgency" */ '@/views/superadmin/SuperAdminAgencies')
        },
        {
            path: 'users',
            name: 'superAdminUsers',
            component: () => import(/* webpackChunkName: "SuperAdminUsers" */ '@/views/superadmin/SuperAdminUsers')
        }
    ]
};
