export default {
    path: '/elements',
    component: () => import(/* webpackChunkName: "Index" */ '@/views/index'),
    redirect: { name: 'elements' },
    children: [
        {
            path: '',
            name: 'elements',
            component: () => import(/* webpackChunkName: "Employees" */ '@/views/elements/Elements')
        }
    ]
};
