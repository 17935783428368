import HttpService from '@/servises/HttpService';
import { getEndpoint } from '@/utils/endpoints';

export default {
    signIn: (data) => {
        return HttpService.post(getEndpoint('sign_in'), data);
    },
    remindPassword: (data) => {
        return HttpService.post(getEndpoint('password'), data);
    },
    saveNewPassword: (params) => {
        return HttpService.patch(getEndpoint('password'), params);
    },
    signOut: () => {
        return HttpService.delete(getEndpoint('sign_out'));
    }
};
