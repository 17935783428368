import { CONVERT_FROM_UTC, PARSE_DATE, PARSE_TIME } from './dates';

export const PARSE_INSPECTIONS = (inspections, params) => {
    return inspections.map((el, index) => {
        const _seq = (index + 1) + ((params.params['page[number]'] - 1) * params.params['page[size]']);
        const endDateTime = el.attributes.inspection_end_datetime
            ? CONVERT_FROM_UTC(el.attributes.inspection_end_datetime) : null;
        const scheduledInspectionDateLocal = el.attributes.scheduled_inspection_date_local ? el.attributes.scheduled_inspection_date_local.split('+')[0].split('T').join(' ') : '';
        const inspectionEndDatetimeLocal = el.attributes.inspection_end_datetime_local ? el.attributes.inspection_end_datetime_local.split('+')[0].split('T').join(' ') : '';
        return {
            id: el.id,
            seq: _seq,
            ...el.attributes,
            parsed_scheduled_inspection_date_local: scheduledInspectionDateLocal,
            parsed_local_end_date_time: inspectionEndDatetimeLocal,
            inspection_end_datetime: endDateTime
                ? `${PARSE_DATE(endDateTime)} ${PARSE_TIME(endDateTime)}` : null,
            inspection_date: `${PARSE_DATE(el.attributes.inspection_date)}`
        };
    });
};
