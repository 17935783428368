const prefix = '/v1';

const labels = {
    'password': '/password',
    'config_qr_code': '/agencies/:agencyName/config_qrcode',
    'agency_config': '/agencies/:name',
    'sign_in': '/sign_in',
    'sign_out': '/sign_out',
    'customers': '/conf/customers',
    'manage_customer': '/conf/customers/:id',
    'territories': '/conf/territories',
    'cities': '/conf/cities',
    'manage_city': '/conf/cities/:id',
    'countries': '/conf/countries',
    'sites': '/conf/places',
    'manage_site': '/conf/places/:id',
    'employees': '/conf/employees',
    'manage_employees': '/conf/employees/:id',
    'locations': '/conf/points',
    'manage_location': '/conf/points/:id',
    'refresh_points_geolocation': '/conf/customers/refresh_points_geolocation',
    'incidents': '/event/incidents',
    'manage_incident': '/event/incidents/:id',
    'manage_incident_status': '/event/incident_statuses/:id',
    'incident_statuses': '/event/incident_statuses',
    'incident_kinds': '/event/incident_kinds',
    'manage_incident_kinds': '/event/incident_kinds/:id',
    'inspections': '/event/inspections',
    'manage_inspection': '/event/inspections/:id',
    'update_many_statuses': '/event/incidents/update_many_statuses',
    'destroy_many_event': '/event/:name/destroy_many',
    'destroy_many_conf': '/conf/:name/destroy_many',
    'inspection_answers': '/event/inspection_answers',
    'templates': '/conf/templates',
    'manage_template': '/conf/templates/:id',
    'template_questions': '/conf/template_questions',
    'manage_template_questions': '/conf/template_questions/:id',
    'incident_comments': '/event/incident_comment_items',
    'manage_incident_comments': '/event/incident_comment_items/:id',
    'inspections_infringement': '/reports/incorrect',
    'number_of_incidents': '/reports/number_of_incidents',
    'report_customers': '/reports/customers',
    'report_global_violations': '/reports/irregularities',
    'report_violations_per_question': '/reports/customer_irregularities',
    'report_incident_kinds': '/reports/incident_kinds',
    'custom_reports': '/reports/custom_reports',
    'reports_xls': '/reports/:kind.xlsx',
    'manage_custom_reports': '/reports/custom_reports/:id',
    'single_custom_report': '/reports/custom_report/:id',
    'translations': '/public/languages',
    'agency_translations': '/settings/translations',
    'manage_agency_translations': '/settings/translations/:id',
    'locations_qrcode_pdf': '/conf/points/:id/get_points_qrcode_pdf.pdf',
    'customer_points_qrcode_pdf': '/conf/customers/:id/get_customer_points_qrcode_pdf.pdf',
    'employee_groups': '/conf/employee_groups',
    'manage_employee_groups': '/conf/employee_groups/:id',
    'companies': '/conf/companies',
    'company_types': '/conf/company_types',
    'manage_company_types': '/conf/company_types/:id',
    'positions': '/conf/positions',
    'permissions': '/settings/permissions',
    'manage_synch_setup_entries': '/settings/synch_setup_entries/:id',
    'synch_setup_entries': '/settings/synch_setup_entries',
    'system_settings': '/settings/system_settings',
    'system_logs': '/settings/system_logs',
    'system_log_types': '/settings/system_log_types',
    'manage_system_settings': '/settings/system_settings/:id',
    'update_many_system_settings': '/settings/set_settings',
    'manage_position': '/conf/positions/:id',
    'set_access_to_templates': '/conf/employees/:id/set_access_to_templates',
    'set_access_to_template': '/conf/templates/:id/set_access_to_template',
    'get_employee_qrcode_pdf': '/conf/employees/:id/get_employee_qrcode_pdf.pdf',
    'get_all_employees_qrcode_pdf': '/conf/employees/get_all_employees_qrcode_pdf.pdf',
    'export_xlsx': '/:prefix/:instance/export_xlsx',
    'get_employees_selected_qrcodes': '/conf/employees/get_selected_qrcodes',
    'question_types': '/event/question_types',
    'export_template': '/conf/templates/:id/export_template',
    'import_template': '/conf/templates/import_template',
    'holiday_calendars': '/settings/holiday_calendars',
    'manage_holiday_calendars': '/settings/holiday_calendars/:id',
    'report_pdf': '/reports/:kind.pdf',
    'custom_report_pdf': '/reports/custom_report/:id.pdf',
    'custom_report_xls': '/reports/export_custom_report_xlsx/:id',
    'report_mail_with_pdf': '/reports/send_mail_with_pdf',
    'change_login_data': '/conf/employees/:id/login_data',
    'manage_archive_template': '/conf/templates/:id/archive',
    'download_xlsx_sample': '/conf/:kind/export_sample_xlsx',
    'import_customers': '/conf/customers/import_customers_xlsx',
    'import_sites': '/conf/places/import_places_xlsx',
    'import_locations': '/conf/points/import_points_xlsx',
    'import_employees': '/conf/employees/import_employees_xlsx',
    'assign_templates_to_points': '/conf/points/assign_templates',
    'unassign_templates_from_points': '/conf/points/unassign_templates',
    'assign_kinds_from_points': '/conf/points/assign_kinds',
    'unassign_kinds_from_points': '/conf/points/unassign_kinds',
    'find_coordinates': '/conf/points/find_coordinates',
    'nfc_report': '/reports/nfc_reports',
    'manage_place_employees': '/conf/place_employees/:id',
    'place_employees': '/conf/place_employees',
    'nfc_report_details': '/reports/nfc_reports/:id',
    'manage_assignment_locations_to_employees': '/conf/employees/set_assignment',
    'superadmin_agencies': '/common/agencies',
    'manage_superadmin_agencies': '/common/agencies/:id',
    'superadmin_users': '/common/users',
    'superadmin_permissions': '/common/permissions',
    'manage_superadmin_users': '/common/users/:id'
    // 'periodicPointReport': only apiv2
    // 'periodicEmployeeReport': only apiv2
    // 'periodicPointsNoScannedReport': only apiv2
};

export const getEndpoint = (label, params = null, withPrefix = true) => {
    const _prefix = withPrefix ? prefix : '';
    let url = '';
    if (typeof label === 'object' && label.group && label.endpoint && labels[label.group] && labels[label.group][label.endpoint]) {
        url = `${_prefix}${labels[label.group][label.endpoint]}`;
    } else if (labels[label]) {
        url = `${_prefix}${labels[label]}`;
    }

    if (!params) {
        return url;
    } else {
        if (url.indexOf(':') === -1) {
            return url;
        } else {
            if (typeof (params) === 'object') {
                for (const param in params) {
                    url = url.replace(':' + param, params[param]);
                }
            }
            return url;
        }
    }
};
export const setParams = (defaultParams, additionalParams) => {
    if (additionalParams) {
        Object.keys(additionalParams).forEach(k => {
            defaultParams[k] = additionalParams[k];
        });
    }
};
