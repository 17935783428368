import systemSettingsApi from '@/utils/api/settings';
import systemLogsApi from '@/utils/api/systemLogs';
import { getField, updateField } from 'vuex-map-fields';
import SystemSetting from '@/models/settings/systemSetting';
import { CONVERT_FROM_UTC, PARSE_DATE, PARSE_TIME } from '@/helpers/dates';

export default {
    namespaced: true,
    state: () => ({
        settings: [],
        systemLogs: [],
        systemLogsAmount: null,
        synchSetupEntries: [],
        modal: false,
        total: null,
        model: new SystemSetting()

    }),
    getters: {
        getField,
        isLocationEnabled: state => {
            const locationSettings = state.settings.find(setting => setting.setting_type === 'GPS_SHOW_STATUS');
            if (locationSettings) {
                return locationSettings.value === '1';
            }
            return false;
        }
    },
    mutations: {
        updateField,
        SET_SETTINGS (state, payload) {
            state.settings = payload;
        },
        SET_SYS_LOGS (state, payload) {
            state.systemLogs = payload;
        },
        SET_SYNCH_SETUP (state, payload) {
            state.synchSetupEntries = payload;
        },
        SET_TOTAL_AMOUNT (state, payload) {
            state.total = payload;
        },
        SET_TOTAL_LOGS_AMOUNT (state, payload) {
            state.systemLogsAmount = payload;
        },
        SET_SETTING_FORM_VISIBILITY (state, payload) {
            state.modal = payload;
        }

    },
    actions: {
        getAllSettings ({ commit }) {
            const params = {
                'page[number]': -1,
                'filter[group]': 'Identifier,General_settings,Reports,General_settings_incident,General_settings_inspection'
            };
            return systemSettingsApi.getSettings(params)
                .then(({ data }) => {
                    commit('SET_SETTINGS', data.data.map(el => new SystemSetting({
                        id: el.id,
                        type: el.type,
                        ...el.attributes
                    })));
                    if (data.meta.stats) {
                        commit('SET_TOTAL_AMOUNT', data.meta.stats.total.count);
                    }
                });
        },
        getSettings ({ commit }, additionalParams = null) {
            commit('SET_SETTINGS', []);
            const params = {};
            if (additionalParams) {
                Object.keys(additionalParams).forEach(k => {
                    params[k] = additionalParams[k];
                });
            }

            return systemSettingsApi.getSettings(params)
                .then(({ data }) => {
                    commit('SET_SETTINGS', data.data.map(el => new SystemSetting({
                        id: el.id,
                        type: el.type,
                        ...el.attributes
                    })));
                    if (data.meta.stats) {
                        commit('SET_TOTAL_AMOUNT', data.meta.stats.total.count);
                    }
                });
        },
        getSynchSetupEntries ({ commit }, additionalParams = null) {
            const params = {};
            if (additionalParams) {
                Object.keys(additionalParams).forEach(k => {
                    params[k] = additionalParams[k];
                });
            }

            return systemSettingsApi.getSynchSetupEntries(params)
                .then(({ data }) => {
                    commit('SET_SYNCH_SETUP', data.data);
                });
        },
        getSystemLogs ({ commit }, additionalParams = null) {
            const params = {};
            if (additionalParams) {
                Object.keys(additionalParams).forEach(k => {
                    params[k] = additionalParams[k];
                });
            }

            return systemLogsApi.getSystemLogs(params)
                .then(({ data }) => {
                    commit('SET_SYS_LOGS', data.data.map((el, index) => {
                        const dateTime = el.attributes.occurrence_date ? CONVERT_FROM_UTC(el.attributes.occurrence_date) : '';
                        const startDate = dateTime ? PARSE_DATE(dateTime) : '';
                        const startTime = dateTime ? PARSE_TIME(dateTime) : '';
                        const _seq = index + 1;
                        return {
                            seq: _seq,
                            id: el.id,
                            ...el.attributes,
                            converted_occurrence_date: `${startDate} ${startTime}`
                        };
                    }));
                    if (data.meta.stats) {
                        commit('SET_TOTAL_LOGS_AMOUNT', data.meta.stats.total.count);
                    }
                });
        },
        update ({ state }, ids) {
            const dataToUpdate = state.settings.filter(el => ids.includes(el.id)).map(s => s.createStructure(s));
            if (dataToUpdate.length > 0) {
                return systemSettingsApi.updateSettings(dataToUpdate);
            }
        }
    }
};
