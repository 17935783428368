import KmsHttpService from '@/servises/kmsHttpService';
import { getV2Endpoint } from '@/utils/apiV2Endpoints';

export default {
    getDeviceList: () => {
        return KmsHttpService.get(getV2Endpoint('osm_device_employee'));
    },
    getDevice: (id) => {
        return KmsHttpService.get(getV2Endpoint('osm_device_employee_id', { id: id }));
    },
    getDeviceByEmployee: (id) => {
        return KmsHttpService.get(getV2Endpoint('osm_device_employee'), {
            params: {
                employee: id
            }
        });
    },
    createDevice: (params) => {
        return KmsHttpService.post(getV2Endpoint('osm_device_employee'), params);
    },
    deleteDevice: (id) => {
        return KmsHttpService.delete(getV2Endpoint('osm_device_employee_id', { id: id }));
    },
    updateDevice: (data, id) => {
        return KmsHttpService.put(getV2Endpoint('osm_device_employee_id', { id: id }), data);
    }
};
