export default {
    path: '/reports',
    component: () => import(/* webpackChunkName: "Index" */ '@/views/index'),
    redirect: { name: 'inspectionsInfringementReport' },
    children: [
        {
            path: 'planning-reports',
            name: 'planningReports',
            component: () => import(/* webpackChunkName: "inspectionsInfringementReport" */ '@/views/reports/PlanningReports')
        },
        {
            path: 'inspections-infringement',
            name: 'inspectionsInfringementReport',
            component: () => import(/* webpackChunkName: "inspectionsInfringementReport" */ '@/views/reports/InspectionsInfringementReport')
        },
        {
            path: 'global-violations',
            name: 'globalViolationsReport',
            component: () => import(/* webpackChunkName: "globalViolationsReport" */ '@/views/reports/GlobalViolationsReport')
        },
        {
            path: 'incidents-types',
            name: 'incidentsTypesReport',
            component: () => import(/* webpackChunkName: "incidentsTypesReport" */ '@/views/reports/IncidentsTypesReport')
        },
        {
            path: 'incidents-quantity',
            name: 'incidentsQuantity',
            component: () => import(/* webpackChunkName: "incidentsQuantity" */ '@/views/reports/IncidentsQuantity')
        },
        {
            path: 'custom-reports',
            name: 'customReports',
            component: () => import(/* webpackChunkName: "customReports" */ '@/views/reports/CustomReports')
        },
        {
            path: 'nfc-reports',
            name: 'nfcReports',
            component: () => import(/* webpackChunkName: "nfcReports" */ '@/views/reports/NfcReport')
        }
    ]
};
