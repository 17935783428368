import { CONVERT_TO_UTC } from '@/helpers/dates';
import { RELATIONSHIPS } from '@/helpers/jsonapiHelpers';

class Incident {
    constructor (params) {
        this.customerId = (params?.customerId !== undefined) ? params?.customerId : null;
        this.customerName = params?.customerName || null;
        this.siteName = params?.siteName || null;
        this.siteId = (params?.siteId !== undefined) ? params?.siteId : null;
        this.locationId = (params?.locationId !== undefined) ? params?.locationId : null;
        this.locationName = params?.locationName || null;
        this.type = (params?.type !== undefined) ? params?.type : null;
        this.typeName = params?.typeName || null;
        this.status = (params?.status !== undefined) ? params?.status : null;
        this.personAssigned = (params?.personAssigned !== undefined) ? params?.personAssigned : null;
        this.startDate = params?.startDate || null;
        this.startTime = params?.startTime || null;
        this.description = params?.description || '';
        this.files = params?.files || [];
        this.endDate = params?.endDate || null;
        this.endTime = params?.endTime || null;
    }

    getCreateData () {
        const dateTime = `${this.startDate} ${this.startTime}`;
        const result = {
            data: {
                type: 'incidents',
                attributes: {
                    start_date_time: CONVERT_TO_UTC(dateTime),
                    description: this.description,
                    files: this.files
                },
                relationships: {
                    place: {
                        data: RELATIONSHIPS.CREATE.SITE(this.siteId)
                    },
                    point: {
                        data: RELATIONSHIPS.CREATE.LOCATION(this.locationId)
                    },
                    incident_kind: {
                        data: RELATIONSHIPS.CREATE.INCIDENT_TYPE(this.type)
                    },
                    incident_status: {
                        data: RELATIONSHIPS.CREATE.INCIDENT_STATUS(this.status)
                    }
                }
            }
        };

        if (this.personAssigned) {
            result.data.relationships.responsible_employee = {
                data: RELATIONSHIPS.CREATE.EMPLOYEE(this.personAssigned)
            };
        }

        return result;
    }

    getCreateDataV2 () {
        const dateTime = `${this.startDate} ${this.startTime}`;
        const result = {
            incident_type: this.type,
            location: this.siteId,
            status: this.status,
            date_of_occurrence: CONVERT_TO_UTC(dateTime),
            description: this.description,
            point: this.locationId,
            assigned_employee: this.personAssigned || null,
            photos: this.files
        };

        return result;
    }

    getUpdateData () {
        const payload = this.getCreateDataV2();
        if (this.endDate || this.endTime) {
            const _date = this.endDate || '';
            const _time = this.endTime || '23:59:59';
            payload.date_of_occurrence = CONVERT_TO_UTC(`${_date} ${_time}`);
        }
        return payload;
    }

    parseData (params) {
        this.customerId = (params.customer_id !== undefined) ? params.customer_id : null;
        this.customerName = params.customer_name || null;
        this.siteName = params.place_name || null;
        this.siteId = (params.site_id !== undefined) ? params.site_id : null;
        this.locationId = (params.location_id !== undefined) ? params.location_id : null;
        this.locationName = params.point_name || null;
        this.type = (params.incidentType?.id !== undefined) ? params.incidentType?.id : null;
        this.typeName = params.incidentType?.name || null;
        this.status = (params.incidentStatus?.id !== undefined) ? params.incidentStatus?.id : null;
        this.personAssigned = (params.person_assigned !== undefined) ? params.person_assigned : null;
        this.startDate = params.start_date || null;
        this.startTime = params.start_time || null;
        this.description = params.description || '';
        this.files = params.files || [];
        this.endDate = params.end_date || null;
        this.endTime = params.end_time || null;
    }
}

export default Incident;
