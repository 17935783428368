import HttpService from '@/servises/HttpService';
import { getEndpoint } from '@/utils/endpoints';

export default {
    getActiveInspection: (id) => {
        return HttpService.get(getEndpoint('manage_inspection', { id: id }));
    },
    getInspectionAnswers: (payload) => {
        return HttpService.get(getEndpoint('inspection_answers'), {
            params: {
                'filter[inspection_id]': payload.id,
                'stats[total]': 'count',
                'page[number]': payload.page || 1,
                'page[size]': payload.amount || 20
            }
        });
    },
    deleteInspection: (id) => {
        return HttpService.delete(getEndpoint('manage_inspection', { id: id }));
    },
    deleteManyInspections: (payload) => {
        return HttpService.delete(getEndpoint('destroy_many_event', { name: 'inspections' }), { data: payload });
    }
};
