// import { CONVERT_TO_UTC } from '@/helpers/dates';
// import { datetime, RRule, RRuleSet, rrulestr } from 'rrule';
import { RRule } from 'rrule';
import { EVENT_CYCLES } from '@/constants/eventCycles';
import { PARSE_TIME_ONLY_FROM_ISO_DATESTRING } from '@/helpers/dates';

class PlannedEvent {
    constructor (params) {
        this.id = params?.id !== undefined ? params?.id : null;
        this.name = params?.name || null;
        this.date = params?.date || null;
        this.notificationTime = params?.notification_time || null;
        this.time = params?.time || null;
        this.timeTolerance = params?.time_tolerance || null;
        this.cycle = params?.cycle || null;

        this.clientId = params?.client.id !== undefined ? params?.client.id.toString() : null;
        this.clientName = params?.client.name !== undefined ? params?.client.name : null;

        this.pointId = params?.point.id !== undefined ? params?.point.id.toString() : null;
        this.pointName = params?.point.name !== undefined ? params?.point.name : null;

        this.locationId = params?.location.id !== undefined ? params?.location.id.toString() : null;
        this.locationName = params?.location.name !== undefined ? params?.location.name : null;

        this.templateId = params?.template.id !== undefined ? params?.template.id.toString() : null;
        this.templateName = params?.template.name !== undefined ? params?.template.name : null;

        this.employeeId = params?.employee.id !== undefined ? params?.employee.id.toString() : null;
        this.employeeName = params?.employee.full_name !== undefined ? params?.employee.full_name : null;

        this.incidentTypeId = params?.incident_type.id.toString() || null;
        this.incidentTypeName = params?.incident_type.name || null;

        this.prepareCalendarFields();
    }

    prepareCalendarFields (cycle, date) {
        this.title = this.name;

        let rrule = null;
        let freq = '';
        if (this.cycle === EVENT_CYCLES.YEARLY) {
            freq = RRule.YEARLY;
        } else if (this.cycle === EVENT_CYCLES.MONTHLY) {
            freq = RRule.MONTHLY;
        } else if (this.cycle === EVENT_CYCLES.WEEKLY) {
            freq = RRule.WEEKLY;
        } else if (this.cycle === EVENT_CYCLES.DAILY) {
            freq = RRule.DAILY;
        }
        if (freq) {
            rrule = {
                freq: freq,
                dtstart: this.date
            };
        }
        this.rrule = rrule;
    }

    #getBaseJSONStructure () {
        const data = {
            name: this.name,
            client: this.clientId,
            point: this.pointId,
            location: this.locationId,
            template: this.templateId,
            employee: this.employeeId,
            date: this.date,
            notification_time: parseInt(this.notificationTime, 10),
            time: PARSE_TIME_ONLY_FROM_ISO_DATESTRING(this.time, true),
            time_tolerance: parseInt(this.timeTolerance, 10),
            cycle: this.cycle,
            incident_type: this.incidentTypeId
        };
        return data;
    }

    getCreateData () {
        return this.#getBaseJSONStructure();
    }

    getUpdateData () {
        const data = this.#getBaseJSONStructure();
        data.id = this.id;

        return data;
    }

    parseData (params) {
        this.id = params.id;
        this.name = params.name;
        this.date = params.date;
        this.notificationTime = params.notificationTime;
        this.time = params.time;
        this.timeTolerance = params.timeTolerance;
        this.cycle = params.cycle;

        this.clientId = params.clientId !== undefined ? params.clientId : params.client;
        this.pointId = params.pointId !== undefined ? params.pointId : params.point.toString();
        this.locationId = params.locationId !== undefined ? params.locationId : params.location.toString();
        this.templateId = params.templateId !== undefined ? params.templateId : params.template.toString();
        this.employeeId = params.employeeId !== undefined ? params.employeeId : params.employee.toString();
        this.incidentTypeId = params.incidentTypeId !== undefined ? params.incidentTypeId : params.incident_type.toString();

        // calendar fields:
        this.title = this.name;
        this.rrule = this.prepareCalendarFields();
    }
}

export default PlannedEvent;
