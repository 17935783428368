import subContractorsTypesApi from '@/utils/api-v2/subContractorsTypes';

export default {
    namespaced: true,
    state: () => ({
        rawSubContractorsTypes: [],
        totalSubContractorsTypesAmount: null
    }),
    getters: {
        subContractorsTypes: state => {
            return state.rawSubContractorsTypes.map(el => ({
                value: el.id,
                text: el.name
            }));
        }
    },
    mutations: {
        SET_RAW (state, payload) {
            state.rawSubContractorsTypes = payload;
        },
        SET_TOTAL_AMOUNT (state, payload) {
            state.totalSubContractorsTypesAmount = payload;
        }
    },
    actions: {
        getSubContractorsTypes ({ commit }, additionalParams = null) {
            commit('SET_RAW', []);

            const params = {};

            if (additionalParams) {
                Object.keys(additionalParams).forEach(k => {
                    params[k] = additionalParams[k];
                });
            }

            return subContractorsTypesApi.getSubContractorsTypes(params)
                .then(({ data }) => {
                    commit('SET_RAW', data.results.map((el, index) => ({
                        seq: (index + 1) + ((params.page - 1) * params.page_size),
                        ...el
                    })));

                    if (data.count) {
                        commit('SET_TOTAL_AMOUNT', data.count);
                    }
                });
        },
        getSubContractorsTypesLazyLoading ({ commit, state }, additionalParams = null) {
            const params = {};

            if (additionalParams) {
                Object.keys(additionalParams).forEach(k => {
                    params[k] = additionalParams[k];
                });
            }

            return subContractorsTypesApi.getSubContractorsTypes(params)
                .then(({ data }) => {
                    const result = [...state.rawSubContractorsTypes].concat(data.results.map(el => ({
                        ...el
                    })));

                    commit('SET_RAW', result);

                    if (data.count) {
                        commit('SET_TOTAL_AMOUNT', data.count);
                    }
                });
        }
    }
};
