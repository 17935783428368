export default {
    path: '/m-reports',
    component: () => import(/* webpackChunkName: "Index" */ '@/views/index'),
    redirect: { name: 'eztReports' },
    children: [
        {
            path: 'ezt-reports',
            name: 'eztReports',
            component: () => import(/* webpackChunkName: "inspectionsInfringementReport" */ '@/views/reports/EztReports')
        },
        {
            path: 'sest-luv-report-comments',
            name: 'sestLuvReportsComments',
            component: () => import('@/views/reports/SestLuvReportsComments')
        },
        {
            path: 'sest-luv-report-weeks',
            name: 'sestLuvWeeksReports',
            component: () => import('@/views/reports/SestLuvReportsWeeks')
        }
    ]
};
