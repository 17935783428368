import SitesGroup from '@/models/customers/sitesGroup';
import siteGroupsApi from '@/utils/api/siteGroups';
import { getField, updateField } from 'vuex-map-fields';

export default {
    namespaced: true,
    state: () => ({
        editedGroupId: null,
        groupData: new SitesGroup(),
        groupFormVisibility: false
    }),
    getters: { getField },
    mutations: {
        updateField,
        SET_EDITED_GROUP_ID (state, payload) {
            state.editedGroupId = payload;
        },
        RESET_GROUP_DATA (state) {
            state.groupData = new SitesGroup();
        },
        SET_GROUP_FORM_VISIBILITY (state, payload) {
            state.groupFormVisibility = payload;
        },
        SET_GROUP_DATA (state, payload) {
            state.groupData.parseData(payload);
        }
    },
    actions: {
        createSitesGroup ({ state }) {
            const data = state.groupData.getCreateData();
            return siteGroupsApi.createSitesGroup(data);
        },
        updateSitesGroup ({ state }) {
            const data = state.groupData.getUpdateData();

            return siteGroupsApi.updateSitesGroup(data);
        },
        deleteSitesGroup (context, id) {
            return siteGroupsApi.deleteSitesGroup(id);
        }
    }
};
