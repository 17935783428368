import { getField, updateField } from 'vuex-map-fields';

export default {
    namespaced: true,
    state: () => ({
        isMenuOpened: true,
        activeParentIndex: null,
        parentVisibility: []
    }),
    getters: {
        getField
    },
    mutations: {
        updateField
    }
};
