import reportsApi from '@/utils/api/reports';
import { PARSE_DATE, PARSE_TIME, CONVERT_FROM_UTC } from '@/helpers/dates';

const months = [
    {
        name: 'january',
        val: '01',
        id: 1
    },
    {
        name: 'february',
        val: '02',
        id: 2
    },
    {
        name: 'march',
        val: '03',
        id: 3
    },
    {
        name: 'april',
        val: '04',
        id: 4
    },
    {
        name: 'may',
        val: '05',
        id: 5
    },
    {
        name: 'june',
        val: '06',
        id: 6
    },
    {
        name: 'july',
        val: '07',
        id: 7
    },
    {
        name: 'august',
        val: '08',
        id: 8
    },
    {
        name: 'september',
        val: '09',
        id: 9
    },
    {
        name: 'october',
        val: '10',
        id: 10
    },
    {
        name: 'november',
        val: '11',
        id: 11
    },
    {
        name: 'december',
        val: '12',
        id: 12
    }
];

export default {
    namespaced: true,
    state: () => ({
        inspectionsInfringement: [],
        inspectionsPerEmployee: [],
        globalViolations: [],
        violationsPerQuestion: [],
        incidentsKindsReports: [],
        incidentsKindsReportsMeta: null,
        nfcReports: []
    }),
    getters: {
        getFormattedNFCReports: state => state.nfcReports.map((el, index) => {
            const dateTime = el.created_at ? CONVERT_FROM_UTC(el.created_at) : '';
            const startDate = dateTime ? PARSE_DATE(dateTime) : '';
            const startTime = dateTime ? PARSE_TIME(dateTime) : '';
            const _seq = (index + 1);
            return {
                seq: _seq,
                id: el.id,
                ...el,
                formatted_date: `${startDate} ${startTime}`
            };
        })
    },
    mutations: {
        SET_INSPECTIONS_INFRINGEMENT (state, payload) {
            state.inspectionsInfringement = payload;
        },
        SET_INSPECTIONS_PER_EMPLOYEE (state, payload) {
            state.inspectionsPerEmployee = payload;
        },
        SET_GLOBAL_VIOLATIONS (state, payload) {
            state.globalViolations = payload;
        },
        SET_VIOLATIONS_PER_QUESTION (state, payload) {
            state.violationsPerQuestion = payload;
        },
        SET_INCIDENTS_KINDS_REPORTS (state, payload) {
            state.incidentsKindsReports = payload;
        },
        SET_INCIDENTS_KINDS_REPORTS_META (state, payload) {
            state.incidentsKindsReportsMeta = payload;
        },
        SET_NFC_REPORTS (state, payload) {
            state.nfcReports = payload;
        }
    },
    actions: {
        getInspectionsInfringement ({ commit }, params) {
            commit('SET_INSPECTIONS_INFRINGEMENT', []);
            const year = parseInt(params.year);

            return reportsApi.getInspectionsInfringement(params)
                .then(({ data }) => {
                    const result = [];
                    data.data.forEach(employee => {
                        const mappedEmployee = {
                            employeeId: employee.employee_id,
                            fullName: `${employee.first_name || ''} ${employee.last_name || ''}`,
                            data: []
                        };

                        const yearData = {
                            year: year,
                            count: employee.data.find(el => parseInt(el.year) === year &&
                                !Object.prototype.hasOwnProperty.call(el, 'month') &&
                                !Object.prototype.hasOwnProperty.call(el, 'incorrect')).count,
                            incorrectCount: employee.data.find(el => parseInt(el.year) === year &&
                                !Object.prototype.hasOwnProperty.call(el, 'month') &&
                                Object.prototype.hasOwnProperty.call(el, 'incorrect')).count,
                            monthsData: {}
                        };

                        months.forEach(m => {
                            yearData.monthsData[`month_${m.id}`] = {
                                month: m.id,
                                count: employee.data.find(el => parseInt(el.year) === year &&
                                    el.month === m.val &&
                                    !Object.prototype.hasOwnProperty.call(el, 'incorrect')).count,
                                incorrectCount: employee.data.find(el => parseInt(el.year) === year &&
                                    el.month === m.val &&
                                    Object.prototype.hasOwnProperty.call(el, 'incorrect')).count
                            };
                        });

                        mappedEmployee.data.push(yearData);

                        result.push(mappedEmployee);
                    });

                    commit('SET_INSPECTIONS_INFRINGEMENT', result);
                });
        },
        getInspectionsPerEmployee ({ commit }, params) {
            commit('SET_INSPECTIONS_PER_EMPLOYEE', []);

            return reportsApi.getInspectionsPerEmployee(params)
                .then(({ data }) => {
                    const result = [];

                    data.data.forEach(el => {
                        const parseData = {
                            customer: el.customer,
                            place: el.place,
                            point: el.point,
                            year: el.year,
                            from_month: el.from_month,
                            from_year: el.from_year,
                            total: {
                                count: el.total_inspections,
                                incorrectCount: el.total_incorrect
                            }
                        };

                        months.forEach(m => {
                            parseData[`month_${m.id}`] = {
                                month: m.id,
                                count: el[`${m.name}_total`],
                                incorrectCount: el[`${m.name}_incorrect`]
                            };
                        });

                        result.push(parseData);
                    });

                    commit('SET_INSPECTIONS_PER_EMPLOYEE', result);
                });
        },
        getGlobalViolations ({ commit }, params) {
            commit('SET_GLOBAL_VIOLATIONS', []);

            return reportsApi.getGlobalViolations(params)
                .then(({ data }) => {
                    commit('SET_GLOBAL_VIOLATIONS', data.data);
                });
        },
        getViolationsPerQuestion ({ commit }, params) {
            commit('SET_VIOLATIONS_PER_QUESTION', []);

            return reportsApi.getViolationsPerQuestion(params)
                .then(({ data }) => {
                    commit('SET_VIOLATIONS_PER_QUESTION', data.data);
                });
        },
        getIncidentsKindsReports ({ commit }, params = null) {
            commit('SET_INCIDENTS_KINDS_REPORTS', []);

            return reportsApi.getIncidentKinds(params)
                .then(({ data }) => {
                    commit('SET_INCIDENTS_KINDS_REPORTS', data.data.data);
                    commit('SET_INCIDENTS_KINDS_REPORTS_META', data.data.meta);
                });
        },
        getNFCReport ({ commit }, params = null) {
            commit('SET_NFC_REPORTS', []);

            return reportsApi.getNFCReport(params)
                .then(({ data }) => {
                    commit('SET_NFC_REPORTS', data.data.map(el => ({
                        id: el.id,
                        ...el.attributes
                    })));
                });
        }
    }
};
