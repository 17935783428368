import { getField, updateField } from 'vuex-map-fields';
import subContractorsApi from '@/utils/api-v2/subContractors';
import SubContractors from '@/models/subContractors/subContractors';

export default {
    namespaced: true,
    state: () => ({
        subContractorsFormVisibility: false,
        editedSubContractorsId: null,
        subContractorsData: new SubContractors()
    }),
    getters: {
        getField
    },
    mutations: {
        updateField,
        SET_SUBCONTRACTORS_ID (state, payload) {
            state.editedSubContractorsId = payload;
        },
        SET_SUBCONTRACTORS_FORM_VISIBILITY (state, payload) {
            state.subContractorsFormVisibility = payload;
        },
        RESET_SUBCONTRACTORS_DATA (state) {
            state.subContractorsData = new SubContractors();
        },
        SET_ACTIVE_SUBCONTRACTOR (state, payload) {
            state.subContractorsData = new SubContractors(payload);
        }
    },
    actions: {
        getActiveSubContractors ({ commit }, id) {
            return subContractorsApi.getActiveSubContractors(id)
                .then(({ data }) => {
                    commit('SET_ACTIVE_SUBCONTRACTOR', data);
                });
        },
        createSubContractors ({ state, commit }) {
            const payload = state.subContractorsData.getCreateData(state.subContractorsData);
            return subContractorsApi.createSubContractors(payload)
                .then(() => {
                    commit('RESET_SUBCONTRACTORS_DATA');
                });
        },
        updateSubContractors ({ state, commit }) {
            const payload = state.subContractorsData.getDataForUpdate(state.subContractorsData);
            return subContractorsApi.updateSubContractors(state.editedSubContractorsId, payload)
                .then(() => {
                    commit('RESET_SUBCONTRACTORS_DATA');
                });
        },
        deleteSubContractors (context, id) {
            return subContractorsApi.deleteSubContractors(id);
        }
    }
};
