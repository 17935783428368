import HttpService from '@/servises/HttpService';
import { getEndpoint } from '@/utils/endpoints';

export default {
    getPositions: (params) => {
        return HttpService.get(getEndpoint('positions'), {
            params: params
        });
    },
    getPermissions: (params) => {
        return HttpService.get(getEndpoint('permissions'), {
            params: params
        });
    },
    getActivePosition: (id) => {
        return HttpService.get(getEndpoint('manage_position', { id: id }), {
            params: {
                include: 'permissions'
            }
        });
    },
    createPosition: (payload) => {
        return HttpService.post(getEndpoint('positions'), payload);
    },
    updatePosition: (id, payload) => {
        return HttpService.patch(getEndpoint('manage_position', { id: id }), payload);
    },
    deletePosition: (id) => {
        return HttpService.delete(getEndpoint('manage_position', { id: id }));
    }
};
