import { FREQUENCY_TYPES_ENUM, PERIODIC_REPORTS_TYPES } from '@/constants/reports';
import { PERIODIC_REPORTS_PARAMS } from '@/constants/reports_params';
import { PARSE_DATE, PARSE_TIME_FROM_ISO_DATESTRING } from '@/helpers/dates';

class PlannedReport {
    constructor () {
        this.type = PERIODIC_REPORTS_TYPES[0].type;
        this.startDateReporting = null;
        this.endDateReporting = null;
        this.startTimeReporting = null;
        this.endTimeReporting = null;
        this.pointId = null;
        this.pointName = null;
        this.locationId = null;
        this.locationName = null;
        this.employeeId = null;
        this.employeeName = null;
        this.timeToInvokeReport = null;
        this.emailsForNotifications = [];
        this.frequencyType = null;
        this.isPaused = 0;
    }

    parseReport (reportType, data) {
        this.type = reportType;

        this.id = data.id;
        this.startDateReporting = PARSE_DATE(data.start_date_reporting);
        this.endDateReporting = PARSE_DATE(data.end_date_reporting);
        this.startTimeReporting = data.start_time_reporting;
        this.endTimeReporting = data.end_time_reporting;
        this.frequencyType = data.only_monday ? FREQUENCY_TYPES_ENUM.EVERY_MONDAY : data.monthly ? FREQUENCY_TYPES_ENUM.MONTHLY : FREQUENCY_TYPES_ENUM.DAILY;
        this.isPaused = data.is_paused ? 1 : 0;
        this.timeToInvokeReport = PARSE_TIME_FROM_ISO_DATESTRING(data.time_to_invoke_report);

        const param = PERIODIC_REPORTS_TYPES.find(r => this.type === r.type)?.param;
        if (param === PERIODIC_REPORTS_PARAMS.POINT) {
            this.pointId = data.point.id;
            this.pointName = data.point.name;
        } else if (param === PERIODIC_REPORTS_PARAMS.LOCATION) {
            this.locationId = data.location.id;
            this.locationName = data.location.name;
        } else if (param === PERIODIC_REPORTS_PARAMS.EMPLOYEE) {
            this.employeeId = data.employee.id;
            this.employeeName = data.employee.full_name;
        }

        this.emailsForNotifications = data.emails?.map((item) => {
            return {
                id: item.id,
                report: item.report,
                email: item.email
            };
        }) || [];
    }

    getDataForCreate () {
        return this.getBaseData();
    }

    getDataForUpdate () {
        return this.getBaseData();
    }

    getCreateImmidiateReportData () {
        const data = {
            emails: this.emailsForNotifications.map(item => item.email),
            start_date: this.getDateLocalTime(this.startDateReporting), // this.getDateUTC(this.startDateReporting),
            end_date: this.getDateLocalTime(this.endDateReporting, true) // this.getDateUTC(this.endDateReporting, true)
        };

        if (this.startTimeReporting && this.endTimeReporting) {
            data.daily_time_window_start = this.startTimeReporting;
            data.daily_time_window_end = this.endTimeReporting;
        }

        const param = PERIODIC_REPORTS_TYPES.find(r => this.type === r.type)?.param;
        if (param === PERIODIC_REPORTS_PARAMS.POINT) {
            data.point_ids = [parseInt(this.pointId)];
        } else if (param === PERIODIC_REPORTS_PARAMS.LOCATION) {
            data.location_ids = [parseInt(this.locationId)];
        } else if (param === PERIODIC_REPORTS_PARAMS.EMPLOYEE) {
            data.employee_ids = [parseInt(this.employeeId)];
        }

        return data;
    }

    getBaseData () {
        const data = {
            start_date_reporting: this.getDateUTC(this.startDateReporting),
            end_date_reporting: this.getDateUTC(this.endDateReporting, true),
            only_monday: this.frequencyType === FREQUENCY_TYPES_ENUM.EVERY_MONDAY,
            monthly: this.frequencyType === FREQUENCY_TYPES_ENUM.MONTHLY,
            is_paused: !!this.isPaused,
            time_to_invoke_report: PARSE_TIME_FROM_ISO_DATESTRING(this.timeToInvokeReport, true),
            emails: []
        };

        const param = PERIODIC_REPORTS_TYPES.find(r => this.type === r.type)?.param;
        if (param === PERIODIC_REPORTS_PARAMS.POINT) {
            data.point_id = this.pointId;
        } else if (param === PERIODIC_REPORTS_PARAMS.LOCATION) {
            data.location_id = this.locationId;
        } else if (param === PERIODIC_REPORTS_PARAMS.EMPLOYEE) {
            data.employee_id = this.employeeId;
        }

        data.emails = this.emailsForNotifications;
        return data;
    }

    getDateUTC (date, endOfDay = false) {
        const locTime = endOfDay ? '23:59:59' : '00:00:00';
        const locDate = new Date(`${date} ${PARSE_TIME_FROM_ISO_DATESTRING(locTime)}`);
        return locDate.toISOString();
    }

    getDateLocalTime (date, endOfDay = false) {
        const locTime = endOfDay ? '23:59:59' : '00:00:00';
        return date + 'T' + locTime + 'Z';
    }
}

export default PlannedReport;
