import PlannedReport from '@/models/reports/plannedReport';
import reportsApiV2 from '@/utils/api-v2/reports';
import { PERIODIC_REPORTS_TYPES } from '@/constants/reports';
import { getField, updateField } from 'vuex-map-fields';

export default {
    namespaced: true,
    state: () => ({
        plannedReport: new PlannedReport(),
        activePlannedReport: null,
        editedPlannedReportId: null,
        filterByReportType: PERIODIC_REPORTS_TYPES[0].type
    }),
    getters: {
        getField
    },
    mutations: {
        updateField,
        SET_PLANNED_REPORT (state, data) {
            state.plannedReport = data;
        },
        CLEAR_EDITED_PLANNED_REPORT_ID (state) {
            state.editedPlannedReportId = null;
        },
        CLEAR_PLANNED_REPORT (state) {
            state.activePlannedReport = null;
            state.plannedReport = new PlannedReport();
        }
    },
    actions: {
        getReport ({ commit }, { reportType, id }) {
            return reportsApiV2.executeGroupEndpoint('get', reportType.endpointGroup, { id: id }, { params: null }).then(({ data }) => {
                const plannedReport = new PlannedReport();
                plannedReport.parseReport(reportType.type, data);
                commit('SET_PLANNED_REPORT', plannedReport);
            });
        },

        createReport ({ state }) {
            const data = state.plannedReport.getDataForCreate();

            const reportType = PERIODIC_REPORTS_TYPES.find(r => r.type === state.plannedReport.type);
            if (reportType) {
                return reportsApiV2.executeGroupEndpoint('create', reportType.endpointGroup, null, data);
            }
        },

        sendReport ({ state }) {
            const data = state.plannedReport.getCreateImmidiateReportData();
            const reportType = PERIODIC_REPORTS_TYPES.find(r => r.type === state.plannedReport.type);
            if (reportType) {
                return reportsApiV2.executeGroupEndpoint('send', reportType.endpointGroup, null, data);
            }
        },

        async updateReport ({ state }) {
            const data = await state.plannedReport.getDataForUpdate();
            const reportType = PERIODIC_REPORTS_TYPES.find(r => r.type === state.plannedReport.type);
            if (reportType) {
                return reportsApiV2.executeGroupEndpoint('update', reportType.endpointGroup, { id: state.editedPlannedReportId }, data);
            }
        },

        deleteReport ({ state }, { reportType, id }) {
            return reportsApiV2.executeGroupEndpoint('delete', reportType.endpointGroup, { id: id }, null);
        },

        clearPlannedReport ({ commit }) {
            commit('CLEAR_PLANNED_REPORT');
            commit('CLEAR_EDITED_PLANNED_REPORT_ID');
        }
    }
};
