export const defaultAgency = () => ({
    id: null,
    name: '',
    database_name: '',
    language: '',
    useConfig: 0,
    useKronos: 0,
    useCheckpoint: 0,
    useIncidents: 0,
    isActive: 0,
    useSmsApi: 0,
    isDemo: 0,
    demoDate: null,
    id_user_resp: 1,
    todelete: 0,
    mobileColor: '#000000',
    mobileTextColor: '#FFFFFFFF',
    defLat: '',
    defLon: '',
    logos: {
        print: '',
        watermark: '',
        web: '',
        mobile: ''
    }
});
