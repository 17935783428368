export const RELATIONSHIPS = {
    CREATE: {
        ADD_PERMISSION: (id) => ({
            type: 'permissions',
            'id': id
        }),
        ADD_SITE: (id) => ({
            type: 'places',
            'temp-id': id,
            method: 'create'
        }),
        ADD_LOCATION: (id) => ({
            type: 'points',
            'temp-id': id,
            method: 'create'
        }),
        CITY: (id) => ({
            type: 'city',
            id: id
        }),
        COUNTRY: (id) => ({
            type: 'country',
            id: id
        }),
        CUSTOMER: (id) => ({
            type: 'customer',
            id: id
        }),
        SITE: (ids) => {
            let result;

            if (Array.isArray(ids)) {
                result = [];
                ids.forEach(id => {
                    result.push({
                        type: 'place',
                        id: id
                    });
                });
            } else {
                result = {
                    type: 'place',
                    id: ids
                };
            }

            return result;
        },
        PLACE_EMPLOYEE: (ids) => {
            let result;

            if (Array.isArray(ids)) {
                result = [];
                ids.forEach(id => {
                    result.push({
                        type: 'place_employee',
                        'temp-id': id,
                        method: 'create'
                    });
                });
            } else {
                result = {
                    type: 'place_employee',
                    'temp-id': ids,
                    method: 'create'
                };
            }
            return result;
        },
        TEMP_SITE: (id) => ({
            type: 'place',
            'temp-id': id
        }),
        LOCATION: (id) => ({
            type: 'point',
            id: id
        }),
        DEFAULT_INSPECTOR: (id) => ({
            type: 'default_inspector',
            id: id
        }),
        EMPLOYEE: (ids) => {
            let result;

            if (Array.isArray(ids)) {
                result = [];
                ids.forEach(id => {
                    result.push({
                        type: 'employee',
                        id: id
                    });
                });
            } else {
                result = {
                    type: 'employee',
                    id: ids
                };
            }

            return result;
        },
        SITE_GROUPS: (ids) => {
            let result;

            if (Array.isArray(ids)) {
                result = [];
                ids.forEach(id => {
                    result.push({
                        type: 'place_groups',
                        id: id
                    });
                });
            } else {
                result = {
                    type: 'place_groups',
                    id: ids
                };
            }

            return result;
        },
        INCIDENT_TYPE: (id) => ({
            type: 'incident_kind',
            id: id
        }),
        INCIDENT_STATUS: (id) => ({
            type: 'incident_status',
            id: id
        }),
        INCIDENT: (id) => ({
            type: 'incident',
            id: id
        }),
        TEMPLATE: (id) => ({
            type: 'template',
            id: id
        }),
        POSITION: (id) => ({
            type: 'position',
            id: id
        }),
        COMPANY: (id) => ({
            type: 'company',
            id: id
        }),
        COMPANY_TYPE: (id) => ({
            type: 'company_type',
            id: id
        }),
        EMPLOYEE_PHONES: (emp) => {
            let result;

            if (Array.isArray(emp)) {
                result = [];
                emp.forEach(e => {
                    result.push({
                        type: 'employee_phones',
                        id: parseInt(e.value)
                    });
                });
            }
            return result;
        },
        EMPLOYEE_MAILS: (emp) => {
            let result;

            if (Array.isArray(emp)) {
                result = [];
                emp.forEach(e => {
                    result.push({
                        type: 'employee_mails',
                        id: parseInt(e.value)
                    });
                });
            }
            return result;
        },
        STATUSES: (s) => {
            let result;

            if (Array.isArray(s)) {
                result = [];
                s.forEach(e => {
                    result.push({
                        type: 'incident_statuses',
                        id: e.value
                    });
                });
            }
            return result;
        },
        EMPLOYEE_GROUPS: (ids) => {
            let result;

            if (Array.isArray(ids)) {
                result = [];
                ids.forEach(id => {
                    result.push({
                        type: 'employee_groups',
                        id: id
                    });
                });
            } else {
                result = {
                    type: 'employee_groups',
                    id: ids
                };
            }

            return result;
        },
        LOCATION_INSPECTORS: (ids) => {
            let result;

            if (Array.isArray(ids)) {
                result = [];
                ids.forEach(id => {
                    result.push({
                        type: 'points_inspectors',
                        'temp-id': id.tempId,
                        method: 'create'
                    });
                });
            } else {
                result = {
                    type: 'points_inspectors',
                    'temp-id': ids,
                    method: 'create'
                };
            }

            return result;
        },
        LOCATION_TEMPLATES: (ids) => {
            let result;

            if (Array.isArray(ids)) {
                result = [];
                ids.forEach(id => {
                    result.push({
                        type: 'points_templates',
                        'temp-id': id.tempId,
                        method: 'create'
                    });
                });
            } else {
                result = {
                    type: 'points_templates',
                    'temp-id': ids,
                    method: 'create'
                };
            }

            return result;
        },
        LOCATION_INCIDENT_TYPES: (ids) => {
            let result;

            if (Array.isArray(ids)) {
                result = [];
                ids.forEach(id => {
                    result.push({
                        type: 'points_incident_kinds',
                        'temp-id': id.tempId,
                        method: 'create'
                    });
                });
            } else {
                result = {
                    type: 'points_incident_kinds',
                    'temp-id': ids,
                    method: 'create'
                };
            }

            return result;
        },
        TEMPLATE_QUESTION: (id) => ({
            type: 'question_type',
            id: id
        }),
        CREATE_TEMPLATE_QUESTIONS: (questions) => {
            let result;

            if (Array.isArray(questions)) {
                result = [];
                questions.forEach(el => {
                    result.push({
                        type: 'template_questions',
                        'temp-id': el.id,
                        method: 'create'
                    });
                });
            } else {
                result = {
                    type: 'template_questions',
                    'temp-id': questions,
                    method: 'create'
                };
            }

            return result;
        },
        PERIODIC_EMPLOYEE_EMAIL: (id) => {
            return {
                type: 'periodic_employee_emails',
                'temp-id': id,
                method: 'create'
            };
        },
        PERIODIC_POINT_EMAIL: (id) => {
            return {
                type: 'periodic_point_emails',
                'temp-id': id,
                method: 'create'
            };
        }
    },
    DESTROY: {
        LOCATION_TEMPLATE: (id) => ({
            type: 'point_templates',
            id: id,
            method: 'destroy'
        }),
        LOCATION_INCIDENT_TYPE: (id) => ({
            type: 'point_incident_kinds',
            id: id,
            method: 'destroy'
        }),
        LOCATION_INSPECTOR: (id) => ({
            type: 'point_inspectors',
            id: id,
            method: 'destroy'
        }),
        PERIODIC_EMPLOYEE_EMAIL: (id) => ({
            type: 'periodic_employee_emails',
            id: id,
            method: 'destroy'
        }),
        PERIODIC_POINT_EMAIL: (id) => ({
            type: 'periodic_point_emails',
            id: id,
            method: 'destroy'
        })
    },
    DISASSOCIATE: {
        EMPLOYEE_MAILS: (emp) => {
            let result;

            if (Array.isArray(emp)) {
                result = [];
                emp.forEach(e => {
                    result.push({
                        'method': 'disassociate',
                        type: 'employee_mails',
                        id: parseInt(e.value)
                    });
                });
            }
            return result;
        },
        EMPLOYEE_PHONES: (emp) => {
            let result;

            if (Array.isArray(emp)) {
                result = [];
                emp.forEach(e => {
                    result.push({
                        'method': 'disassociate',
                        type: 'employee_phones',
                        id: parseInt(e.value)
                    });
                });
            }
            return result;
        },
        STATUSES: (s) => {
            let result;

            if (Array.isArray(s)) {
                result = [];
                s.forEach(e => {
                    result.push({
                        'method': 'disassociate',
                        type: 'incident_statuses',
                        id: e.value
                    });
                });
            }
            return result;
        },
        TEMPLATE: (id) => ({
            'method': 'disassociate',
            type: 'template',
            id: parseInt(id)
        }),
        EMPLOYEE: (id) => ({
            'method': 'disassociate',
            type: 'employees',
            id: parseInt(id)
        }),
        LOCATION: (id) => ({
            'method': 'disassociate',
            type: 'point',
            id: parseInt(id)
        }),
        COMPANY: (id) => ({
            'method': 'disassociate',
            type: 'company',
            id: parseInt(id)
        })
    },
    UPDATE: {
        UPDATE_TEMPLATE_QUESTIONS: (questions) => {
            let result;

            if (Array.isArray(questions)) {
                result = [];
                questions.forEach(el => {
                    result.push({
                        type: 'template_questions',
                        id: el.id,
                        method: 'update'
                    });
                });
            } else {
                result = {
                    type: 'template_questions',
                    id: questions,
                    method: 'update'
                };
            }

            return result;
        }
    }
};

export const INCLUDES = {
    ADD_SITE: (id, attributes, relationships = null) => {
        const _result = {
            type: 'places',
            'temp-id': id,
            attributes: attributes
        };

        if (relationships) {
            _result.relationships = relationships;
        }

        return _result;
    },
    ADD_LOCATION: (id, location) => ({
        type: 'points',
        'temp-id': id,
        attributes: location.attributes,
        relationships: location.relationships
    }),
    ADD_PERMISSION: (tempId, existId) => ({
        'type': 'position_permissions',
        'temp-id': tempId,
        'relationships': {
            'permission': {
                'data': {
                    'type': 'permission',
                    'id': existId
                }
            }
        }
    }),
    ADD_PLACE_EMPLOYEE: (tempId, existId) => ({
        'type': 'place_employee',
        'temp-id': tempId,
        'relationships': {
            'employee': {
                'data': {
                    'type': 'employee',
                    'id': existId
                }
            }
        }
    }),
    ADD_LOCATION_INSPECTOR: (data, temp = false) => {
        const result = {
            type: 'points_inspectors',
            'temp-id': data.tempId,
            attributes: {},
            relationships: {
                employee: {
                    data: RELATIONSHIPS.CREATE.EMPLOYEE(data.employeeId)
                }
            }
        };

        if (!temp) {
            result.relationships.place = {
                data: RELATIONSHIPS.CREATE.SITE(data.siteId)
            };
        }

        return result;
    },
    ADD_LOCATION_TEMPLATE: (data) => ({
        type: 'points_templates',
        'temp-id': data.tempId,
        attributes: {},
        relationships: {
            template: {
                data: RELATIONSHIPS.CREATE.TEMPLATE(data.templateId)
            }
        }
    }),
    ADD_LOCATION_INCIDENT_TYPE: (data, temp = false) => {
        const result = {
            type: 'points_incident_kinds',
            'temp-id': data.tempId,
            attributes: {},
            relationships: {
                incident_kind: {
                    data: RELATIONSHIPS.CREATE.INCIDENT_TYPE(data.typeId)
                }
            }
        };

        if (!temp) {
            result.relationships.place = {
                data: RELATIONSHIPS.CREATE.SITE(data.siteId)
            };
        }

        return result;
    },
    ADD_EMPLOYEE_PERIODIC_EMAIL: (id, email) => ({
        type: 'periodic_employee_emails',
        'temp-id': id,
        attributes: {
            email: email
        }
    }),
    ADD_POINT_PERIODIC_EMAIL: (id, email) => ({
        type: 'periodic_point_emails',
        'temp-id': id,
        attributes: {
            email: email
        }
    })
};
