import reportsApi from '@/utils/api/reports';

export default {
    namespaced: true,
    state: () => ({
        rawNumberOfIncidents: []
    }),
    getters: {
        sites: state => state.rawSites.map(el => ({
            value: el.id,
            text: el.name
        })),
        siteGroups: state => state.rawSiteGroups.map(el => el.getFilterableData())
    },
    mutations: {
        SET_RAW (state, payload) {
            state.rawNumberOfIncidents = payload;
        }
    },
    actions: {
        getNumberOfIncidents ({ commit }, additionalParams = null) {
            commit('SET_RAW', []);
            const params = {};
            if (additionalParams) {
                Object.keys(additionalParams).forEach(k => {
                    params[k] = additionalParams[k];
                });
            }
            return reportsApi.getNumberOfIncidents(params)
                .then(({ data }) => {
                    commit('SET_RAW', data.data);
                });
        }
    }
};
