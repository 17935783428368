import HttpService from '@/servises/HttpService';
import { getEndpoint } from '@/utils/endpoints';

export default {
    getEmployees: (params) => {
        return HttpService.get(getEndpoint('employees'), {
            params: params
        });
    },
    setAccessToTemplates: (id, data) => {
        return HttpService.patch(getEndpoint('set_access_to_templates', { id: id }), data);
    },
    employeeSetAccessToTemplate: (id, data) => {
        return HttpService.patch(getEndpoint('set_access_to_template', { id: id }), data);
    },
    getActiveEmployee: (id) => {
        return HttpService.get(getEndpoint('manage_employees', { id: id }), {
            params: {
                include: 'position,city,company,employee_groups,point',
                'extra_fields[employees]': 'qr_code_svg,supervisor_full_name'
            }
        });
    },
    getSitesForActiveEmployee: (id) => {
        return HttpService.get(getEndpoint('place_employees'), {
            params: {
                'filter[employee_id]': id,
                'include': 'place'
            }
        });
    },
    getSingleEmployee: (id) => {
        return HttpService.get(getEndpoint('manage_employees', { id: id }), {
            params: {
                'extra_fields[employees]': 'inspector_relations_ids'
            }
        });
    },
    createEmployee: (data) => {
        return HttpService.post(getEndpoint('employees'), data);
    },
    updateEmployee: (id, data) => {
        return HttpService.patch(getEndpoint('manage_employees', { id: id }), data);
    },
    deleteEmployee: (id) => {
        return HttpService.delete(getEndpoint('manage_employees', { id: id }));
    },
    deleteManyEmployees: (payload) => {
        return HttpService.delete(getEndpoint('destroy_many_conf', { name: 'employees' }), { data: payload });
    },
    getEmployeesGroups: (params) => {
        return HttpService.get(getEndpoint('employee_groups'), {
            params: params
        });
    },
    createEmployeesGroup: (data) => {
        return HttpService.post(getEndpoint('employee_groups'), data);
    },
    updateEmployeesGroup: (data) => {
        return HttpService.patch(getEndpoint('manage_employee_groups', { id: data.data.id }), data);
    },
    deleteEmployeesGroup: (id) => {
        return HttpService.delete(getEndpoint('manage_employee_groups', { id: id }));
    },
    changeLoginData: (data) => {
        return HttpService.patch(getEndpoint('change_login_data', { id: data.id }), data.data);
    },
    importEmployees: (data) => {
        return HttpService.post(getEndpoint('import_employees'), data);
    },
    manageAssignmentToLocations: (data) => {
        return HttpService.patch(getEndpoint('manage_assignment_locations_to_employees'), data);
    }
};
