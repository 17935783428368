import HttpService from '@/servises/HttpService';
import { getEndpoint } from '@/utils/endpoints';

export default {
    getCities: (params) => {
        return HttpService.get(getEndpoint('cities'), {
            params: params
        });
    },
    createCity: (data) => {
        return HttpService.post(getEndpoint('cities'), data);
    },
    updateCity: (id, data) => {
        return HttpService.patch(getEndpoint('manage_city', { id: id }), data);
    },
    deleteCity: (id) => {
        return HttpService.delete(getEndpoint('manage_city', { id: id }));
    }
};
