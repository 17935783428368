import HttpService from '@/servises/HttpService';
import { getEndpoint } from '@/utils/endpoints';

export default {
    getAgencyConfiguration: (host) => {
        return HttpService.get(getEndpoint('agency_config', { name: host }));
    },
    getLocaleMessages: (locale, agency) => {
        return HttpService.get(getEndpoint('translations', null, false), {
            params: {
                locale: locale,
                agency: agency
            },
            transformRequest: (data, headers) => {
                delete headers.common.Authorization;
                return data;
            }
        });
    },
    getConfigurationQRCode: (agency) => {
        return HttpService.get(getEndpoint('config_qr_code', { agencyName: agency }));
    },
    reportMail: (data) => {
        return HttpService.post(getEndpoint('report_mail_with_pdf'), data);
    },
    getCoordinates: (data) => {
        return HttpService.post(getEndpoint('find_coordinates'), data);
    }
};
