import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import { en, pl, es, uk, ru, sl } from 'vuetify/lib/locale';
import { ICONS } from '@/constants/icons';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#2D2D2C',
                secondary: '#6E807E',
                tertiary: '#E8EBEB',
                red: '#7E4F4F',
                green: '#959A7C',
                blue: '#676E9F',
                medium_red: '#cbb8b8',
                yellow: '#7a6d1b',
                text: '#3E4449',
                medium_text: '#636363',
                light_grey_2: '#9e9e9e'
            }
        }
    },
    lang: {
        locales: { pl, en, es, uk, ru, sl },
        current: 'pl'
    },
    icons: {
        values: {
            dropdown: ICONS.SINGLE_DOWN
        }
    }
});
