import { getField, updateField } from 'vuex-map-fields';
import subContractorsTypesApi from '@/utils/api-v2/subContractorsTypes';
import SubContractorsType from '@/models/subContractorsTypes/subContractorsType';

export default {
    namespaced: true,
    state: () => ({
        subContractorsTypesFormVisibility: false,
        editedSubContractorsTypesId: null,
        subContractorsTypesData: new SubContractorsType()
    }),
    getters: {
        getField
    },
    mutations: {
        updateField,
        SET_SUBCONTRACTORS_TYPE_ID (state, payload) {
            state.editedSubContractorsTypesId = payload;
        },
        SET_SUBCONTRACTORS_TYPE_FORM_VISIBILITY (state, payload) {
            state.subContractorsTypesFormVisibility = payload;
        },
        RESET_SUBCONTRACTORS_TYPE_DATA (state) {
            state.subContractorsTypesData = new SubContractorsType();
        },
        SET_ACTIVE_SUBCONTRACTOR_TYPE (state, payload) {
            state.subContractorsTypesData = {
                ...payload
            };
        }
    },
    actions: {
        getActiveSubContractorsType ({ commit }, id) {
            return subContractorsTypesApi.getActiveSubContractorsTypes(id)
                .then(({ data }) => {
                    commit('SET_ACTIVE_SUBCONTRACTOR_TYPE', data);
                });
        },
        createSubContractorsType ({ state, commit }) {
            const payload = state.subContractorsTypesData.getCreateData(state.subContractorsTypesData);
            return subContractorsTypesApi.createSubContractorsTypes(payload)
                .then(() => {
                    commit('RESET_SUBCONTRACTORS_TYPE_DATA');
                });
        },
        updateSubContractorsType ({ state, commit }) {
            const payload = new SubContractorsType(state.subContractorsTypesData).getCreateData(state.permissions);
            return subContractorsTypesApi.updateSubContractorsTypes(state.editedSubContractorsTypesId, payload)
                .then(() => {
                    commit('RESET_SUBCONTRACTORS_TYPE_DATA');
                });
        },
        deleteSubContractorsType (context, id) {
            return subContractorsTypesApi.deleteSubContractorsTypes(id);
        }
    }
};
